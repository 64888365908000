import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { createAppTheme } from "./theme";
import "./config/i18n";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

// Import components
import MainLayout from "./components/layout/MainLayout";
import LoadingScreen from "./components/layout/LoadingScreen";
import AboutPage from "./pages/AboutPage";

// Import pages
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import Services from "./pages/Services";
import QuotationForm from "./pages/QuotationForm";
import ReferencePage from "./pages/ReferencePage";

// Service detail pages placeholder
const ServicePage = ({ title }) => <div>{title}</div>;

// İç sayfalar için Router
function AppRoutes() {
  const location = useLocation();
  const { i18n } = useTranslation();
  const siteTitle = i18n.language === "tr" ? "Ölçü Eğitim" : "Olcu Education";

  // Her sayfa değiştiğinde sayfanın en tepesine kaydır
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Title'ı dil değişimine göre ayarla
  useEffect(() => {
    document.title = siteTitle;
  }, [siteTitle, i18n.language]);

  return (
    <MainLayout>
      <Helmet>
        <title>{siteTitle}</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/hakkimizda" element={<AboutPage />} />
        <Route path="/hizmetler" element={<Services />} />
        <Route path="/iletisim" element={<ContactPage />} />
        <Route path="/referanslar" element={<ReferencePage />} />
        <Route path="/about" element={<AboutPage />} />

        {/* Teklif Formu Sayfası - Yeni eklenen route */}
        <Route path="/teklif-formu" element={<QuotationForm />} />

        {/* Hizmetler Alt Sayfaları */}
        <Route
          path="/hizmetler/otomasyon-sistemleri"
          element={<ServicePage title="Okul Otomasyon Sistemleri" />}
        />
        <Route
          path="/hizmetler/web-yazilim"
          element={<ServicePage title="Okul Web Yazılım" />}
        />
        <Route
          path="/hizmetler/resmiyet-kontrol"
          element={
            <ServicePage title="Okul Resmiyet Kontrol ve Personel Eğitimi" />
          }
        />
        <Route
          path="/hizmetler/robotik"
          element={<ServicePage title="Robotik Malzemeler" />}
        />
        <Route
          path="/hizmetler/yemek-hizmetleri"
          element={<ServicePage title="Yemek Hizmetleri" />}
        />
        <Route
          path="/hizmetler/servis-hizmetleri"
          element={<ServicePage title="Servis Hizmetleri" />}
        />
        <Route
          path="/hizmetler/kirtasiye"
          element={<ServicePage title="Kırtasiye Ürünleri" />}
        />
        <Route
          path="/hizmetler/temizlik-gida"
          element={<ServicePage title="Temizlik ve Gıda Ürünleri" />}
        />
        <Route
          path="/hizmetler/ogretmen-egitimleri"
          element={<ServicePage title="Öğretmen Eğitimleri ve Seminerleri" />}
        />
        <Route
          path="/hizmetler/kayit-danismanligi"
          element={<ServicePage title="Kayıt Danışmanlığı" />}
        />

        {/* 404 - Bulunamadı Sayfası */}
        <Route path="*" element={<div>Sayfa Bulunamadı</div>} />
      </Routes>
    </MainLayout>
  );
}

function App() {
  const [showLoading, setShowLoading] = useState(false);
  const [themeMode] = useState("light");
  const theme = createAppTheme(themeMode);
  const { i18n } = useTranslation();
  const defaultTitle =
    i18n.language === "tr" ? "Ölçü Eğitim" : "Olcu Education";

  // Initial title set
  useEffect(() => {
    document.title = defaultTitle;

    // Ensure favicon is set
    const linkElement = document.querySelector("link[rel='icon']");
    if (linkElement) {
      linkElement.href = "/favicon.ico";
    }
  }, [defaultTitle, i18n.language]);

  useEffect(() => {
    // Bu oturumda siteye daha önce giriş yapılıp yapılmadığını kontrol et
    const hasVisitedInThisSession = sessionStorage.getItem(
      "hasVisitedInThisSession"
    );

    if (!hasVisitedInThisSession) {
      // Bu oturumdaki ilk ziyaret - yükleme ekranını göster
      setShowLoading(true);

      const loadingTimer = setTimeout(() => {
        setShowLoading(false);
        // Ziyaret edildiğini sessionStorage'a kaydet
        sessionStorage.setItem("hasVisitedInThisSession", "true");
      }, 4300); // 5 saniye animasyon + 0.3 saniye geçiş süresi

      return () => clearTimeout(loadingTimer);
    } else {
      // Bu oturumda daha önce ziyaret edilmişse, yükleme ekranını gösterme
      setShowLoading(false);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {showLoading ? (
        <LoadingScreen onLoadingComplete={() => setShowLoading(false)} />
      ) : (
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      )}
    </ThemeProvider>
  );
}

export default App;
