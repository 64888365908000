// src/locales/en/form.js
export const formTranslations = {
  institution: "Institution Name",
  city: "City",
  district: "District",
  select: "Select",
  schoolType: "School Type",
  studentCount: "Number of Students",
  subjectAreas: "Subject Areas of Interest",
  publicationSchedule: "Publication Schedule",
  systemType: "Automation Type",
  currentSystem: "Current System in Use",
  userCount: "User Count (Teachers/Admin)",
  parentAccess: "Parent Access Required?",
  mobileAccess: "Mobile App Need",
  websiteType: "Website Type",
  hasExistingWebsite: "Do You Have an Existing Website?",
  existingWebsite: "Existing Website Address",
  contentManagement: "Who Will Manage the Content?",
  designPreferences: "Design Preferences/References",
  institutionType: "Institution Type",
  licenseStatus: "License/Permit Status",
  staffTrainingNeeds: "Staff Training Needs",
  urgency: "Urgency Level",
  ageGroups: "Target Age Groups",
  programmingTypes: "Requested Coding Types",
  teacherTraining: "Teacher Training Needed",
  currentInfrastructure: "Current Infrastructure/Hardware",
  roboticsSets: "Robotics Sets of Interest",
  labSetup: "Laboratory Setup Required",
  budget: "Approximate Budget Range",
  mealTypes: "Required Meal Types",
  staffCount: "Staff Count",
  dietaryRestrictions: "Special Dietary Requirements",
  kitchenAvailability: "Kitchen Facilities",
  serviceType: "Service Type",
  routeCount: "Estimated Number of Service Routes",
  vehicleTypes: "Required Vehicle Types",
  trackingSystem: "Tracking System Required",
  hostessRequired: "Hostess Required",
  serviceSchedule: "Service Hours",
  productCategories: "Required Product Categories",
  purchaseFrequency: "Purchase Frequency",
  customPrinting: "Custom Printing Required",
  estimatedBudget: "Estimated Budget",
  ecoCertRequired: "Eco-Certified Products Required",
  storageSpace: "Storage Space",
  trainingAreas: "Training Areas",
  teacherCount: "Number of Teachers for Training",
  preferredDates: "Preferred Date Range",
  trainingLocation: "Training Location",
  trainingDuration: "Preferred Training Duration",
  currentStudentCount: "Current Student Count",
  targetStudentCount: "Target Student Count",
  consultancyAreas: "Consultancy Areas",
  marketingBudget: "Marketing Budget",
  previousCampaigns: "Previous Registration Campaigns",

  // Translations for form options
  options: {
    schoolType: {
      Anaokulu: "Kindergarten",
      İlkokul: "Primary School",
      Ortaokul: "Middle School",
      Lise: "High School",
      Diğer: "Other",
    },
    publicationSchedule: {
      "Acil (1 ay içinde)": "Urgent (within 1 month)",
      "1-3 ay içinde": "Within 1-3 months",
      "3-6 ay içinde": "Within 3-6 months",
      "Gelecek eğitim yılı için": "For the next academic year",
    },
    systemType: {
      "Öğrenci Takip": "Student Tracking",
      "Yoklama Sistemi": "Attendance System",
      "Not Sistemi": "Grading System",
      "Veli İletişim": "Parent Communication",
      "Envanter Yönetimi": "Inventory Management",
      Diğer: "Other",
    },
    parentAccess: {
      Evet: "Yes",
      Hayır: "No",
    },
    mobileAccess: {
      Evet: "Yes",
      Hayır: "No",
    },
    websiteType: {
      "Kurumsal Web Sitesi": "Corporate Website",
      "E-Öğrenme Platformu": "E-Learning Platform",
      "Okul-Veli Portal": "School-Parent Portal",
      Diğer: "Other",
    },
    hasExistingWebsite: {
      Evet: "Yes",
      Hayır: "No",
    },
    contentManagement: {
      "Kurum Personeli": "Institution Staff",
      "Dış Kaynak (Sizin tarafınızdan)": "Outsourced (By your team)",
      "Karma Model": "Hybrid Model",
    },
    institutionType: {
      "Özel Okul": "Private School",
      "Özel Kurs": "Private Course",
      "Etüt Merkezi": "Study Center",
      "Rehabilitasyon Merkezi": "Rehabilitation Center",
      Diğer: "Other",
      Anaokulu: "Kindergarten",
      İlkokul: "Primary School",
      Ortaokul: "Middle School",
      Lise: "High School",
      "Karma Okul": "Mixed School",
    },
    licenseStatus: {
      "Yeni Başvuru": "New Application",
      Yenileme: "Renewal",
      "Tadilat/Değişiklik": "Modification/Change",
      "Denetim Hazırlığı": "Inspection Preparation",
    },
    staffTrainingNeeds: {
      "Evrak Yönetimi": "Document Management",
      "Resmi Yazışmalar": "Official Correspondence",
      "Mevzuat Bilgilendirme": "Regulatory Information",
      "Denetim Hazırlık": "Inspection Preparation",
      Diğer: "Other",
    },
    urgency: {
      "Acil (1 hafta içinde)": "Urgent (within 1 week)",
      "1-2 hafta içinde": "Within 1-2 weeks",
      "1 ay içinde": "Within 1 month",
      "Belirli bir tarihte": "On a specific date",
    },
    ageGroups: {
      "5-8 yaş": "5-8 years",
      "9-12 yaş": "9-12 years",
      "13-15 yaş": "13-15 years",
      "16-18 yaş": "16-18 years",
    },
    programmingTypes: {
      "Blok Kodlama": "Block Coding",
      "Web Geliştirme": "Web Development",
      "Mobil Uygulama": "Mobile App Development",
      "Oyun Geliştirme": "Game Development",
      "Robotik Kodlama": "Robotic Coding",
      Diğer: "Other",
    },
    teacherTraining: {
      Evet: "Yes",
      Hayır: "No",
    },
    roboticsSets: {
      "Lego Education": "Lego Education",
      Arduino: "Arduino",
      "Makey Makey": "Makey Makey",
      mBot: "mBot",
      "Raspberry Pi": "Raspberry Pi",
      "VEX Robotics": "VEX Robotics",
      Diğer: "Other",
    },
    labSetup: {
      Evet: "Yes",
      Hayır: "No",
      "Mevcut Laboratuvar Geliştirme": "Existing Laboratory Enhancement",
    },
    budget: {
      "10.000 TL altı": "Under 10,000 TL",
      "10.000 - 25.000 TL": "10,000 - 25,000 TL",
      "25.000 - 50.000 TL": "25,000 - 50,000 TL",
      "50.000 TL üzeri": "Over 50,000 TL",
      "5.000 TL altı": "Under 5,000 TL",
      "5.000 - 15.000 TL": "5,000 - 15,000 TL",
      "15.000 - 50.000 TL": "15,000 - 50,000 TL",
      "50.000 - 100.000 TL": "50,000 - 100,000 TL",
      "100.000 TL üzeri": "Over 100,000 TL",
    },
    mealTypes: {
      "Sabah Kahvaltısı": "Breakfast",
      "Öğle Yemeği": "Lunch",
      "İkindi Kahvaltısı": "Afternoon Snack",
      "Akşam Yemeği": "Dinner",
    },
    kitchenAvailability: {
      "Var (Tam donanımlı)": "Available (Fully equipped)",
      "Var (Kısmi donanımlı)": "Available (Partially equipped)",
      Yok: "Not available",
    },
    serviceType: {
      "Yerinde Pişirme": "On-site Cooking",
      "Taşıma Yemek": "Catering",
      "Karma Model": "Hybrid Model",
    },
    vehicleTypes: {
      "Minibüs (14+1)": "Minibus (14+1)",
      "Midibüs (25+1)": "Midibus (25+1)",
      "Otobüs (40+1 üzeri)": "Bus (over 40+1)",
    },
    trackingSystem: {
      Evet: "Yes",
      Hayır: "No",
    },
    hostessRequired: {
      Evet: "Yes",
      Hayır: "No",
    },
    productCategories: {
      "Okul Defterleri": "School Notebooks",
      Kitaplar: "Books",
      "Resim Malzemeleri": "Art Supplies",
      "El İşi Malzemeleri": "Craft Materials",
      "Ofis Kırtasiye": "Office Stationery",
      Diğer: "Other",
      "Temizlik Malzemeleri": "Cleaning Supplies",
      "Hijyen Ürünleri": "Hygiene Products",
      "Gıda Ürünleri": "Food Products",
      "Kantin Ürünleri": "Canteen Products",
    },
    purchaseFrequency: {
      "Yıllık Tek Seferde": "Once a Year",
      Dönemlik: "Per Semester",
      Aylık: "Monthly",
      "İhtiyaç Oldukça": "As Needed",
      Haftalık: "Weekly",
    },
    customPrinting: {
      Evet: "Yes",
      Hayır: "No",
    },
    ecoCertRequired: {
      Evet: "Yes",
      Hayır: "No",
    },
    storageSpace: {
      Küçük: "Small",
      Orta: "Medium",
      Büyük: "Large",
      "Çok Büyük": "Very Large",
    },
    trainingAreas: {
      "Yeni Eğitim Metodolojileri": "New Educational Methodologies",
      "Teknoloji Entegrasyonu": "Technology Integration",
      "Sınıf Yönetimi": "Classroom Management",
      "Ölçme ve Değerlendirme": "Measurement and Evaluation",
      Diğer: "Other",
    },
    trainingLocation: {
      Okulumuzda: "At Our School",
      "Sizin Belirlediğiniz Yerde": "At Your Location",
      Online: "Online",
    },
    trainingDuration: {
      "Yarım Gün": "Half Day",
      "Tam Gün": "Full Day",
      "Hafta Sonu": "Weekend",
      "Hafta İçi Akşam": "Weekday Evening",
      "Uzun Dönemli Program": "Long-term Program",
    },
    consultancyAreas: {
      "Veli İletişim Stratejileri": "Parent Communication Strategies",
      "Sosyal Medya Yönetimi": "Social Media Management",
      "Etkinlik Organizasyonu": "Event Organization",
      "Kayıt Dönemi Planlaması": "Registration Period Planning",
      "Marka Değeri Artırma": "Brand Value Enhancement",
      Diğer: "Other",
    },
    subjectAreas: {
      Türkçe: "Turkish",
      Matematik: "Mathematics",
      "Fen Bilimleri": "Science",
      "Sosyal Bilgiler": "Social Studies",
      İngilizce: "English",
      Diğer: "Other",
    },
  },
};
