import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Container,
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
  Divider,
  Breadcrumbs,
  Link as MuiLink,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import SendIcon from "@mui/icons-material/Send";
import { formFieldsConfig, serviceDetails } from "../config/formConfig";

const QuotationForm = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [serviceId, setServiceId] = useState("");
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [redirectMsg, setRedirectMsg] = useState({
    open: false,
    message: "",
  });

  // Get service ID from URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const serviceParam = searchParams.get("hizmet");

    if (serviceParam && serviceDetails[serviceParam]) {
      setServiceId(serviceParam);
    }
  }, [location]);

  const handleCloseRedirectMsg = () => {
    setRedirectMsg({ open: false, message: "" });
  };

  // Get form fields for selected service
  const getFormFields = () => {
    if (!serviceId) return [];

    // Common fields + service specific fields
    return [
      ...formFieldsConfig.commonFields,
      ...(formFieldsConfig.serviceFields[serviceId] || []),
    ];
  };

  // Show/hide field based on condition
  const shouldShowField = (field) => {
    // If not conditional, always show
    if (!field.conditional) return true;

    // Check condition for conditional fields
    const { conditional } = field;
    return formData[conditional.field] === conditional.value;
  };

  // Update form data
  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;

    if (type === "checkbox") {
      // Update array for checkbox groups
      const currentValues = formData[name] || [];
      const newValues = checked
        ? [...currentValues, value]
        : currentValues.filter((val) => val !== value);

      setFormData((prev) => ({
        ...prev,
        [name]: newValues,
      }));
    } else {
      // Normal update for other input types
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Submit form
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    // Prepare form data
    const submitData = {
      ...formData,
      serviceId,
      serviceName: t(`services.${getServiceKey(serviceId)}`), // Translate service name
      submissionDate: new Date().toISOString(),
    };

    // E-posta içeriği oluştur
    const emailSubject = `Teklif Talebi: ${t(
      `services.${getServiceKey(serviceId)}`
    )}`;
    let emailBody = `Hizmet: ${t(`services.${getServiceKey(serviceId)}`)}\n\n`;

    // Add form data to email body
    Object.entries(formData).forEach(([key, value]) => {
      const fieldLabel = t(getFieldLabel(key));
      emailBody += `${fieldLabel}: ${value}\n`;
    });

    // Yeni satır karakterlerini HTML için uygun formata dönüştür
    const formattedBody = emailBody.replace(/\n/g, "%0D%0A");

    // Show redirect message
    setTimeout(() => {
      setLoading(false);

      // Show redirecting message - İki yönlü dil kontrolü
      // İki yönlü güvenilir dil tespiti: Hem İngilizce hem Türkçe içerik kontrolü
      let currentLang = "tr"; // Varsayılan olarak Türkçe

      try {
        // Form içindeki başlık ve buton metnini al
        const submitButton =
          document
            .querySelector('button[type="submit"]')
            ?.textContent?.trim() || "";
        const formTitle =
          document.querySelector("h1, h4")?.textContent?.trim() || "";
        console.log("Button text:", submitButton, "Form title:", formTitle); // Hata ayıklama

        // Net olarak İngilizce algılama (kesin İngilizce belirteçleri)
        const isEnglishMarkers =
          submitButton.includes("Send Quotation Request") ||
          formTitle.includes("Quotation Form");

        // Net olarak Türkçe algılama (kesin Türkçe belirteçleri)
        const isTurkishMarkers =
          submitButton.includes("Teklif Talebini Gönder") ||
          formTitle.includes("Teklif Formu");

        console.log(
          "English markers:",
          isEnglishMarkers,
          "Turkish markers:",
          isTurkishMarkers
        );

        if (isEnglishMarkers && !isTurkishMarkers) {
          currentLang = "en";
        } else if (isTurkishMarkers && !isEnglishMarkers) {
          currentLang = "tr";
        } else {
          // Belirsiz durumda i18n'e bakılır
          currentLang = i18n.language?.startsWith("en") ? "en" : "tr";
        }
      } catch (e) {
        console.error("Language detection error:", e);
        // Hata durumunda varsayılan olarak i18n'e bak
        currentLang = i18n.language?.startsWith("en") ? "en" : "tr";
      }

      console.log("Final detected language:", currentLang);

      // Tespit edilen dile göre mesajı seç
      const redirectMessage =
        currentLang === "en"
          ? "You are being redirected to your email application. Please complete the process to send your message."
          : "E-posta uygulamanıza yönlendiriliyorsunuz. Lütfen mesajınızı göndermek için işlemi tamamlayın.";

      setRedirectMsg({
        open: true,
        message: redirectMessage,
      });

      // Open email client in new window
      window.open(
        `mailto:info@olcuegitim.com?subject=${encodeURIComponent(
          emailSubject
        )}&body=${formattedBody}`,
        "_blank"
      );
    }, 1000);
  };

  // Helper function to map service IDs to translation keys
  const getServiceKey = (id) => {
    const keyMap = {
      "okul-yayinlari": "schoolPublications",
      "otomasyon-sistemleri": "schoolAutomation",
      "web-yazilim": "schoolWebSoftware",
      "resmiyet-kontrol": "officialControl",
      "kodlama-egitimi": "codingEducation",
      robotik: "robotics",
      "yemek-hizmetleri": "foodServices",
      "servis-hizmetleri": "transportServices",
      kirtasiye: "stationery",
      "temizlik-gida": "cleaningFood",
      "ogretmen-egitimleri": "teacherTrainings",
      "kayit-danismanligi": "registrationConsultancy",
    };

    return keyMap[id] || id;
  };

  // Map field IDs to translation keys
  const getFieldLabel = (fieldId) => {
    // Özel alanlar için eşleşme
    const fieldMap = {
      fullName: "contact.fullName",
      email: "contact.email",
      phone: "contact.phone",
      message: "contact.message",
      // Diğer özel eşleşmeler burada eklenebilir
    };

    // Özel eşleşme varsa onu kullan, yoksa form. önekini kullan
    return fieldMap[fieldId] || `form.${fieldId}`;
  };

  // Helper function to translate options
  const translateOption = (fieldId, option) => {
    const specificKey = `form.options.${fieldId}.${option}`;
    const translated = t(specificKey);

    // Çevirinin mevcut olup olmadığını kontrol et
    if (translated !== specificKey) {
      return translated;
    }

    // Eğer çeviri bulunamadıysa, seçeneği olduğu gibi döndür
    return option;
  };

  // Get selected service details
  const selectedService = serviceDetails[serviceId] || {};
  const formFields = getFormFields();

  // Render form field
  const renderFormField = (field) => {
    if (!shouldShowField(field)) return null;

    const { id, type, options, required } = field;
    const label = t(getFieldLabel(id)); // Translate field label
    const fieldValue = formData[id] || "";

    switch (type) {
      case "text":
      case "email":
      case "tel":
      case "number":
        return (
          <TextField
            fullWidth
            id={id}
            name={id}
            label={label}
            type={type}
            value={fieldValue}
            onChange={handleChange}
            required={required}
            margin="normal"
            variant="outlined"
          />
        );

      case "textarea":
        return (
          <TextField
            fullWidth
            id={id}
            name={id}
            label={label}
            value={fieldValue}
            onChange={handleChange}
            required={required}
            margin="normal"
            variant="outlined"
            multiline
            rows={4}
          />
        );

      case "select":
        return (
          <TextField
            select
            fullWidth
            id={id}
            name={id}
            label={label}
            value={fieldValue}
            onChange={handleChange}
            required={required}
            margin="normal"
            variant="outlined"
          >
            <MenuItem value="">{t("form.select")}</MenuItem>
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {translateOption(id, option)}
              </MenuItem>
            ))}
          </TextField>
        );

      case "checkbox":
        return (
          <FormControl
            fullWidth
            margin="normal"
            required={required}
            component="fieldset"
            variant="outlined"
          >
            <Typography variant="body1" gutterBottom>
              {label}
            </Typography>
            <Grid container spacing={1}>
              {options.map((option) => (
                <Grid item xs={12} sm={6} md={4} key={option}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData[id]?.includes(option) || false}
                        onChange={handleChange}
                        name={id}
                        value={option}
                      />
                    }
                    label={translateOption(id, option)}
                  />
                </Grid>
              ))}
            </Grid>
          </FormControl>
        );

      case "radio":
        return (
          <FormControl
            fullWidth
            margin="normal"
            required={required}
            component="fieldset"
          >
            <Typography variant="body1" gutterBottom>
              {label}
            </Typography>
            <RadioGroup
              name={id}
              value={fieldValue}
              onChange={handleChange}
              row
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={translateOption(id, option)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );

      default:
        return null;
    }
  };

  // Thank you message (kept for compatibility)
  if (submitted) {
    return (
      <Container maxWidth="md" sx={{ py: 8 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <Box textAlign="center" py={4}>
            <Typography variant="h4" gutterBottom color="primary">
              {t("common.thanks")}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {t("common.quotationReceived")}
            </Typography>
            <Typography variant="body1" paragraph>
              {t("common.willContact")}
            </Typography>
            <Button
              component={Link}
              to="/hizmetler"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              {t("common.returnToServices")}
            </Button>
          </Box>
        </Paper>
      </Container>
    );
  }

  return (
    <>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        {/* Breadcrumbs */}
        <Breadcrumbs sx={{ mb: 4 }}>
          <MuiLink
            component={Link}
            to="/"
            color="inherit"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <HomeIcon sx={{ mr: 0.5, fontSize: 20 }} />
            {t("common.home")}
          </MuiLink>
          <MuiLink component={Link} to="/hizmetler" color="inherit">
            {t("common.services")}
          </MuiLink>
          {serviceId && (
            <Typography color="textPrimary">
              {t(`services.${getServiceKey(serviceId)}`)}
            </Typography>
          )}
        </Breadcrumbs>

        <Paper elevation={3} sx={{ p: { xs: 2, md: 4 }, borderRadius: 2 }}>
          {/* Form Title */}
          <Box mb={4}>
            <Typography variant="h4" component="h1" gutterBottom>
              {t("quotation.title")}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {t("quotation.description")}
            </Typography>
            <Divider sx={{ mt: 2 }} />
          </Box>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          {/* Form */}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Show error if no service selected */}
              {!serviceId ? (
                <Grid item xs={12}>
                  <Alert severity="warning">
                    {t("quotation.invalidService")}
                  </Alert>
                </Grid>
              ) : (
                <>
                  {/* Form Fields */}
                  {formFields.map((field) => (
                    <Grid
                      item
                      xs={12}
                      md={field.type === "textarea" ? 12 : 6}
                      key={field.id}
                    >
                      {renderFormField(field)}
                    </Grid>
                  ))}

                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <Box
                      sx={{ mt: 2, display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={loading}
                        startIcon={
                          loading ? (
                            <CircularProgress size={20} />
                          ) : (
                            <SendIcon />
                          )
                        }
                        sx={{
                          px: 5,
                          py: 1.5,
                          borderRadius: 2,
                          boxShadow: 3,
                          fontWeight: "bold",
                        }}
                      >
                        {loading
                          ? t("quotation.sending")
                          : t("quotation.sendRequest")}
                      </Button>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </Paper>

        {/* Privacy Policy Note */}
        <Box mt={3} p={3} bgcolor="background.paper" borderRadius={2}>
          <Typography variant="caption" color="textSecondary">
            {t("quotation.privacyNote").split("Gizlilik Politikamızı")[0]}
            <MuiLink component={Link} to="/gizlilik-politikasi">
              {t("common.privacyPolicy")}
            </MuiLink>{" "}
            {t("quotation.privacyNote").split("Gizlilik Politikamızı")[1] || ""}
          </Typography>
        </Box>
      </Container>

      {/* 3 saniye sonra otomatik kapanan yönlendirme mesajı */}
      <Snackbar
        open={redirectMsg.open}
        autoHideDuration={3000} // 3 saniye (3000 milisaniye) sonra otomatik kapanacak
        onClose={handleCloseRedirectMsg}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        TransitionProps={{ appear: true }}
      >
        <Alert
          onClose={handleCloseRedirectMsg}
          severity="info"
          variant="filled"
          sx={{
            transition: "opacity 0.5s ease-out",
          }}
        >
          {redirectMsg.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default QuotationForm;
