import React from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  Button,
  Grid,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Çeviri hookunu ekliyoruz

const ReferencePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation(); // Çeviri fonksiyonunu alıyoruz

  return (
    <Container maxWidth="lg" sx={{ py: { xs: 4, md: 8 } }}>
      <Box sx={{ textAlign: "center", mb: { xs: 4, md: 6 } }}>
        <Typography
          variant="h3"
          component="h1"
          fontWeight="bold"
          gutterBottom
          sx={{
            fontSize: { xs: "2rem", md: "3rem" },
            background: "linear-gradient(45deg, #0ea2e2 30%, #5d767c 90%)",
            backgroundClip: "text",
            textFillColor: "transparent",
            mb: 2,
          }}
        >
          {t("references.title")}{" "}
          {/* Çeviri için t fonksiyonunu kullanıyoruz */}
        </Typography>

        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{
            maxWidth: "700px",
            mx: "auto",
            fontSize: { xs: "1rem", md: "1.1rem" },
          }}
        >
          {t("references.subtitle")}
        </Typography>
      </Box>

      <Paper
        elevation={0}
        sx={{
          p: { xs: 3, md: 5 },
          mb: 5,
          borderRadius: 4,
          background:
            "linear-gradient(120deg, rgba(240,249,255,1) 0%, rgba(249,249,255,1) 100%)",
          boxShadow: "0 10px 40px -10px rgba(0,64,128,0.1)",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "300px",
            height: "300px",
            background:
              "radial-gradient(circle, rgba(33,150,243,0.1) 0%, rgba(33,203,243,0.05) 70%, rgba(255,255,255,0) 100%)",
            top: "-120px",
            right: "-120px",
            borderRadius: "50%",
            zIndex: 0,
          }}
        />

        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                background: "linear-gradient(45deg, #0ea2e2 30%, #21CBF3 90%)",
                borderRadius: "50%",
                p: 3,
                color: "white",
                boxShadow: "0 5px 20px rgba(33,150,243,0.4)",
                mb: { xs: 2, md: 0 },
              }}
            >
              <LockOutlinedIcon sx={{ fontSize: 40 }} />
            </Box>
          </Grid>

          <Grid item xs={12} md={8} sx={{ zIndex: 1, position: "relative" }}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              fontWeight="medium"
              sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
            >
              {t("references.privacyTitle")}
            </Typography>

            <Typography
              variant="body1"
              paragraph
              sx={{ fontSize: { xs: "0.95rem", md: "1rem" }, lineHeight: 1.7 }}
            >
              {t("references.privacyDescription1")}
            </Typography>

            <Typography
              variant="body1"
              sx={{ fontSize: { xs: "0.95rem", md: "1rem" }, lineHeight: 1.7 }}
            >
              {t("references.privacyDescription2")}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Box sx={{ mb: 6 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                height: "100%",
                borderRadius: 3,
                bgcolor: "rgba(236, 246, 253, 0.5)",
                border: "1px solid rgba(33, 150, 243, 0.1)",
                transition: "all 0.3s ease",
                "&:hover": {
                  boxShadow: "0 5px 15px rgba(0,0,0,0.05)",
                  transform: "translateY(-5px)",
                },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <ShieldOutlinedIcon sx={{ color: "primary.main", mr: 1.5 }} />
                <Typography variant="h6" fontWeight="medium">
                  {t("references.dataSecurityTitle")}
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ lineHeight: 1.7 }}>
                {t("references.dataSecurityDescription")}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                height: "100%",
                borderRadius: 3,
                bgcolor: "rgba(236, 246, 253, 0.5)",
                border: "1px solid rgba(33, 150, 243, 0.1)",
                transition: "all 0.3s ease",
                "&:hover": {
                  boxShadow: "0 5px 15px rgba(0,0,0,0.05)",
                  transform: "translateY(-5px)",
                },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <VerifiedUserOutlinedIcon
                  sx={{ color: "primary.main", mr: 1.5 }}
                />
                <Typography variant="h6" fontWeight="medium">
                  {t("references.trustedPartnerTitle")}
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ lineHeight: 1.7 }}>
                {t("references.trustedPartnerDescription")}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Paper
        elevation={0}
        sx={{
          p: { xs: 3, md: 5 },
          borderRadius: 4,
          textAlign: "center",
          background: "linear-gradient(45deg, #0ea2e2 30%, #5d767c 90%)",
          boxShadow: "0 10px 40px -10px rgba(33,150,243,0.5)",
          color: "white",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "400px",
            height: "400px",
            background:
              "radial-gradient(circle, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0.05) 70%, rgba(255,255,255,0) 100%)",
            top: "-200px",
            left: "-200px",
            borderRadius: "50%",
            zIndex: 0,
          }}
        />

        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            maxWidth: "800px",
            mx: "auto",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: "bold",
              mb: 3,
              fontSize: { xs: "1.5rem", md: "2.2rem" },
            }}
          >
            {t("references.contactTitle")}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              mb: 4,
              opacity: 0.9,
              fontSize: { xs: "0.95rem", md: "1.1rem" },
              lineHeight: 1.7,
            }}
          >
            {t("references.contactDescription")}
          </Typography>

          <Button
            variant="contained"
            size="large"
            component={Link}
            to="/iletisim"
            sx={{
              py: 1.5,
              px: 4,
              borderRadius: 3,
              bgcolor: "white",
              color: "primary.main",
              fontWeight: "bold",
              "&:hover": {
                bgcolor: "rgba(255,255,255,0.9)",
              },
              boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
              fontSize: { xs: "0.9rem", md: "1rem" },
            }}
          >
            {t("references.contactButtonText")}
          </Button>
        </Box>
      </Paper>

      <Box sx={{ textAlign: "center", mt: 6, opacity: 0.7 }}>
        <Typography variant="body2" color="text.secondary">
          © {new Date().getFullYear()} {t("common.copyright")}.{" "}
          {t("references.dataSafety")}
        </Typography>
      </Box>
    </Container>
  );
};

export default ReferencePage;
