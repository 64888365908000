import React, { useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { 
  Container, 
  Grid, 
  Typography, 
  Box, 
  Card, 
  CardContent, 
  Button, 
  Avatar, 
  Paper,
  useTheme,
  useMediaQuery,
  Divider,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


// Styled components
const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(/assets/images/backgrounds/about-bg.jpg)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed',
  color: theme.palette.common.white,
  padding: theme.spacing(15, 0),
  textAlign: 'center',
  marginBottom: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(10, 0),
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacing(5),
  fontWeight: 700,
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: -12,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 70,
    height: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 2,
  }
}));

const ValueCard = styled(Card)(({ theme }) => ({
  height: '100%',
  textAlign: 'center',
  transition: 'all 0.4s ease',
  borderRadius: 16,
  overflow: 'hidden',
  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    transform: 'translateY(-12px)',
    boxShadow: '0 20px 40px rgba(0, 0, 0, 0.12)',
  }
}));

const FloatingCard = styled(Card)(({ theme }) => ({
  height: '100%',
  borderRadius: 16,
  boxShadow: '0 15px 35px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.4s ease',
  transform: 'translateY(30px)',
  '&:hover': {
    transform: 'translateY(20px) scale(1.02)',
    boxShadow: '0 25px 45px rgba(0, 0, 0, 0.15)',
  }
}));

const FeatureList = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& > svg': {
      color: theme.palette.success.main,
      marginRight: theme.spacing(1),
    }
  }
}));

const CtaSection = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
  borderRadius: 24,
  padding: theme.spacing(8, 3),
  color: theme.palette.common.white,
  textAlign: 'center',
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  boxShadow: '0 15px 50px rgba(0, 0, 0, 0.1)',
  position: 'relative',
  overflow: 'hidden',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: 'radial-gradient(circle at 20% 30%, rgba(255, 255, 255, 0.2) 0%, transparent 40%)',
  }
}));

const AboutPage = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const testimonialRef = useRef(null);

  // Scroll to top when page loads
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollTestimonials = (direction) => {
    if (testimonialRef.current) {
      const scrollAmount = 380; // Approximate width of a testimonial card
      testimonialRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  // Testimonial data - This would ideally be translated as well
  const testimonials = [
    {
      text: t("about.testimonials.testimonial1"),
      name: "Ahmet Y.",
      title: t("about.testimonials.role1"),
      rating: 5,
      color: theme.palette.primary.main,
      lightColor: theme.palette.primary.light,
    },
    {
      text: t("about.testimonials.testimonial2"),
      name: "Zeynep K.",
      title: t("about.testimonials.role2"),
      rating: 4,
      color: theme.palette.success.main,
      lightColor: theme.palette.success.light,
    },
    {
      text: t("about.testimonials.testimonial3"),
      name: "Murat D.",
      title: t("about.testimonials.role3"),
      rating: 5,
      color: theme.palette.info.main,
      lightColor: theme.palette.info.light,
    },
    {
      text: t("about.testimonials.testimonial4"),
      name: "Selim B.",
      title: t("about.testimonials.role4"),
      rating: 4.5,
      color: theme.palette.warning.main,
      lightColor: theme.palette.warning.light,
    },
    {
      text: t("about.testimonials.testimonial5"),
      name: "Deniz A.",
      title: t("about.testimonials.role5"),
      rating: 4,
      color: theme.palette.secondary.main,
      lightColor: theme.palette.secondary.light,
    },
    {
      text: t("about.testimonials.testimonial6"),
      name: "Emre T.",
      title: t("about.testimonials.role6"),
      rating: 5,
      color: theme.palette.error.main,
      lightColor: theme.palette.error.light,
    },
    {
      text: t("about.testimonials.testimonial7"),
      name: "Hakan S.",
      title: t("about.testimonials.role7"),
      rating: 4,
      color: theme.palette.primary.dark,
      lightColor: theme.palette.primary.main,
    },
    {
      text: t("about.testimonials.testimonial8"),
      name: "Nur L.",
      title: t("about.testimonials.role8"),
      rating: 4.5,
      color: theme.palette.success.dark,
      lightColor: theme.palette.success.main,
    },
    {
      text: t("about.testimonials.testimonial9"),
      name: "Canan Y.",
      title: t("about.testimonials.role9"),
      rating: 4,
      color: theme.palette.info.dark,
      lightColor: theme.palette.info.main,
    },
    {
      text: t("about.testimonials.testimonial10"),
      name: "Burak M.",
      title: t("about.testimonials.role10"),
      rating: 5,
      color: theme.palette.warning.dark,
      lightColor: theme.palette.warning.main,
    },
  ];

  const renderRating = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    for (let i = 0; i < 5; i++) {
      if (i < fullStars) {
        stars.push(
          <StarIcon key={i} sx={{ color: theme.palette.warning.main }} />
        );
      } else if (i === fullStars && hasHalfStar) {
        stars.push(
          <StarHalfIcon key={i} sx={{ color: theme.palette.warning.main }} />
        );
      } else {
        stars.push(
          <StarBorderIcon key={i} sx={{ color: theme.palette.warning.main }} />
        );
      }
    }

    return stars;
  };

  return (
    <Box>
      {/* Hero Section */}
      <HeroSection>
        <Container>
          <Box sx={{ opacity: 0, animation: "fadeIn 0.8s forwards" }}>
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{
                fontWeight: 800,
                textShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                letterSpacing: 1,
                mb: 3,
              }}
            >
              {t("about.heroTitle")}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                maxWidth: 750,
                mx: "auto",
                opacity: 0.9,
                letterSpacing: 0.5,
              }}
            >
              {t("about.heroSubtitle")}
            </Typography>
          </Box>
        </Container>
      </HeroSection>

      {/* Main Content */}
      <Container>
        {/* Intro Section */}
        <Box sx={{ mb: 10, maxWidth: 1000, mx: "auto" }}>
          <Typography
            variant="h4"
            component="h2"
            align="center"
            sx={{
              fontWeight: 700,
              mb: 3,
              position: "relative",
              letterSpacing: 0.5,
            }}
          >
            {t("about.whoWeAre")}
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                bottom: -12,
                width: 80,
                height: 4,
                bgcolor: "primary.main",
                borderRadius: 2,
              }}
            />
          </Typography>
          <Box sx={{ mt: 5 }}>
            <Typography
              variant="body1"
              paragraph
              align="center"
              sx={{
                fontSize: "1.2rem",
                color: "text.secondary",
                lineHeight: 1.8,
              }}
            >
              {t("about.introFirstParagraph")}
            </Typography>
            <Typography
              variant="body1"
              paragraph
              align="center"
              sx={{
                fontSize: "1.2rem",
                color: "text.secondary",
                lineHeight: 1.8,
              }}
            >
              {t("about.introSecondParagraph")}
            </Typography>
          </Box>
        </Box>

        {/* Education Services & Supply Section */}
        <Box sx={{ mb: 10 }}>
          <Typography
            variant="h4"
            component="h2"
            align="center"
            sx={{
              fontWeight: 700,
              mb: 5,
              position: "relative",
            }}
          >
            {t("about.servicesTitle")}
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                bottom: -12,
                width: 100,
                height: 4,
                bgcolor: "primary.main",
                borderRadius: 2,
              }}
            />
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <FloatingCard>
                <CardContent sx={{ p: 4 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    <Avatar
                      sx={{
                        width: 60,
                        height: 60,
                        bgcolor: theme.palette.primary.light,
                        color: theme.palette.primary.main,
                        mr: 2,
                        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.08)",
                      }}
                    >
                      <PeopleAltOutlinedIcon fontSize="large" />
                    </Avatar>
                    <Typography
                      variant="h5"
                      component="h3"
                      sx={{ fontWeight: 700 }}
                    >
                      {t("about.educationConsultingTitle")}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{ mb: 3, color: "text.secondary", lineHeight: 1.8 }}
                  >
                    {t("about.educationConsultingDesc")}
                  </Typography>
                  <Divider sx={{ my: 3 }} />
                  <FeatureList>
                    <Box>
                      <CheckCircleOutlineIcon />
                      <Typography variant="body1">
                        {t("about.educationFeature1")}
                      </Typography>
                    </Box>
                    <Box>
                      <CheckCircleOutlineIcon />
                      <Typography variant="body1">
                        {t("about.educationFeature2")}
                      </Typography>
                    </Box>
                    <Box>
                      <CheckCircleOutlineIcon />
                      <Typography variant="body1">
                        {t("about.educationFeature3")}
                      </Typography>
                    </Box>
                    <Box>
                      <CheckCircleOutlineIcon />
                      <Typography variant="body1">
                        {t("about.educationFeature4")}
                      </Typography>
                    </Box>
                  </FeatureList>
                </CardContent>
              </FloatingCard>
            </Grid>

            <Grid item xs={12} md={6}>
              <FloatingCard>
                <CardContent sx={{ p: 4 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                    <Avatar
                      sx={{
                        width: 60,
                        height: 60,
                        bgcolor: theme.palette.success.light,
                        color: theme.palette.success.main,
                        mr: 2,
                        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.08)",
                      }}
                    >
                      <LocalShippingOutlinedIcon fontSize="large" />
                    </Avatar>
                    <Typography
                      variant="h5"
                      component="h3"
                      sx={{ fontWeight: 700 }}
                    >
                      {t("about.supplyServicesTitle")}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{ mb: 3, color: "text.secondary", lineHeight: 1.8 }}
                  >
                    {t("about.supplyServicesDesc")}
                  </Typography>
                  <Divider sx={{ my: 3 }} />
                  <FeatureList>
                    <Box>
                      <CheckCircleOutlineIcon />
                      <Typography variant="body1">
                        {t("about.supplyFeature1")}
                      </Typography>
                    </Box>
                    <Box>
                      <CheckCircleOutlineIcon />
                      <Typography variant="body1">
                        {t("about.supplyFeature2")}
                      </Typography>
                    </Box>
                    <Box>
                      <CheckCircleOutlineIcon />
                      <Typography variant="body1">
                        {t("about.supplyFeature3")}
                      </Typography>
                    </Box>
                    <Box>
                      <CheckCircleOutlineIcon />
                      <Typography variant="body1">
                        {t("about.supplyFeature4")}
                      </Typography>
                    </Box>
                  </FeatureList>
                </CardContent>
              </FloatingCard>
            </Grid>
          </Grid>
        </Box>

        {/* Vision & Mission */}
        <Box sx={{ mb: 10 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Card
                elevation={0}
                sx={{
                  height: "100%",
                  borderRadius: 4,
                  background: `linear-gradient(135deg, ${theme.palette.primary.light}30, ${theme.palette.primary.main}15)`,
                  border: `1px solid ${theme.palette.primary.main}30`,
                  backdropFilter: "blur(10px)",
                  p: 1,
                  overflow: "hidden",
                  position: "relative",
                  transition: "all 0.4s ease",
                  "&:hover": {
                    transform: "translateY(-10px)",
                    boxShadow: "0 20px 40px rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                <Box
                  className="vision-blob"
                  sx={{
                    position: "absolute",
                    width: "60%",
                    height: "60%",
                    background: `radial-gradient(circle, ${theme.palette.primary.main}30, ${theme.palette.primary.light}10)`,
                    borderRadius: "50%",
                    top: "70%",
                    right: "70%",
                    transform: "translate(0%, 0%)",
                    transition: "transform 0.6s ease",
                    zIndex: 0,
                  }}
                />
                <CardContent sx={{ p: 4, position: "relative", zIndex: 1 }}>
                  <Typography
                    variant="h5"
                    component="h3"
                    color="primary"
                    gutterBottom
                    sx={{ fontWeight: 700, mb: 3 }}
                  >
                    {t("about.visionTitle")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "text.primary",
                      lineHeight: 1.8,
                      fontSize: "1.05rem",
                    }}
                  >
                    {t("about.visionDesc")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                elevation={0}
                sx={{
                  height: "100%",
                  borderRadius: 4,
                  background: `linear-gradient(135deg, ${theme.palette.success.light}30, ${theme.palette.success.main}15)`,
                  border: `1px solid ${theme.palette.success.main}30`,
                  backdropFilter: "blur(10px)",
                  p: 1,
                  overflow: "hidden",
                  position: "relative",
                  transition: "all 0.4s ease",
                  "&:hover": {
                    transform: "translateY(-10px)",
                    boxShadow: "0 20px 40px rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                <Box
                  className="mission-blob"
                  sx={{
                    position: "absolute",
                    width: "60%",
                    height: "60%",
                    background: `radial-gradient(circle, ${theme.palette.success.main}30, ${theme.palette.success.light}10)`,
                    borderRadius: "50%",
                    top: "70%",
                    left: "70%",
                    transform: "translate(0%, 0%)",
                    transition: "transform 0.6s ease",
                    zIndex: 0,
                  }}
                />
                <CardContent sx={{ p: 4, position: "relative", zIndex: 1 }}>
                  <Typography
                    variant="h5"
                    component="h3"
                    color="success.main"
                    gutterBottom
                    sx={{ fontWeight: 700, mb: 3 }}
                  >
                    {t("about.missionTitle")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "text.primary",
                      lineHeight: 1.8,
                      fontSize: "1.05rem",
                    }}
                  >
                    {t("about.missionDesc")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* Values Section */}
        <Box sx={{ mb: 10 }}>
          <SectionTitle variant="h4" component="h2" align="center">
            {t("about.valuesTitle")}
          </SectionTitle>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <ValueCard elevation={4}>
                <CardContent sx={{ p: 4 }}>
                  <Box
                    sx={{
                      mb: 3,
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        width: 90,
                        height: 90,
                        borderRadius: "50%",
                        background: `linear-gradient(135deg, ${theme.palette.primary.main}30, ${theme.palette.primary.light}30)`,
                        transform: "translate(-5px, -5px)",
                      }}
                    />
                    <Avatar
                      sx={{
                        width: 80,
                        height: 80,
                        bgcolor: theme.palette.primary.main,
                        boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
                        position: "relative",
                        zIndex: 1,
                      }}
                    >
                      <HandshakeOutlinedIcon sx={{ fontSize: 40 }} />
                    </Avatar>
                  </Box>
                  <Typography
                    variant="h6"
                    component="h3"
                    align="center"
                    gutterBottom
                    sx={{ fontWeight: 700, mb: 2 }}
                  >
                    {t("about.value1Title")}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{ color: "text.secondary", lineHeight: 1.7 }}
                  >
                    {t("about.value1Desc")}
                  </Typography>
                </CardContent>
              </ValueCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <ValueCard elevation={4}>
                <CardContent sx={{ p: 4 }}>
                  <Box
                    sx={{
                      mb: 3,
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        width: 90,
                        height: 90,
                        borderRadius: "50%",
                        background: `linear-gradient(135deg, ${theme.palette.warning.main}30, ${theme.palette.warning.light}30)`,
                        transform: "translate(-5px, -5px)",
                      }}
                    />
                    <Avatar
                      sx={{
                        width: 80,
                        height: 80,
                        bgcolor: theme.palette.warning.main,
                        boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
                        position: "relative",
                        zIndex: 1,
                      }}
                    >
                      <LightbulbOutlinedIcon sx={{ fontSize: 40 }} />
                    </Avatar>
                  </Box>
                  <Typography
                    variant="h6"
                    component="h3"
                    align="center"
                    gutterBottom
                    sx={{ fontWeight: 700, mb: 2 }}
                  >
                    {t("about.value2Title")}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{ color: "text.secondary", lineHeight: 1.7 }}
                  >
                    {t("about.value2Desc")}
                  </Typography>
                </CardContent>
              </ValueCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <ValueCard elevation={4}>
                <CardContent sx={{ p: 4 }}>
                  <Box
                    sx={{
                      mb: 3,
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        width: 90,
                        height: 90,
                        borderRadius: "50%",
                        background: `linear-gradient(135deg, ${theme.palette.info.main}30, ${theme.palette.info.light}30)`,
                        transform: "translate(-5px, -5px)",
                      }}
                    />
                    <Avatar
                      sx={{
                        width: 80,
                        height: 80,
                        bgcolor: theme.palette.info.main,
                        boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
                        position: "relative",
                        zIndex: 1,
                      }}
                    >
                      <PeopleAltOutlinedIcon sx={{ fontSize: 40 }} />
                    </Avatar>
                  </Box>
                  <Typography
                    variant="h6"
                    component="h3"
                    align="center"
                    gutterBottom
                    sx={{ fontWeight: 700, mb: 2 }}
                  >
                    {t("about.value3Title")}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{ color: "text.secondary", lineHeight: 1.7 }}
                  >
                    {t("about.value3Desc")}
                  </Typography>
                </CardContent>
              </ValueCard>
            </Grid>
          </Grid>
        </Box>

        {/* Statistics Section */}
        <Box sx={{ mb: 10 }}>
          <Paper
            elevation={0}
            sx={{
              bgcolor: "background.default",
              borderRadius: 8,
              py: 8,
              px: 4,
              background: `linear-gradient(135deg, ${theme.palette.primary.dark}05, ${theme.palette.primary.main}15)`,
              boxShadow: "0 20px 60px rgba(0, 0, 0, 0.07)",
              position: "relative",
              overflow: "hidden",
              "&:before": {
                content: '""',
                position: "absolute",
                width: "300px",
                height: "300px",
                borderRadius: "50%",
                background: `radial-gradient(circle, ${theme.palette.primary.light}30, transparent 70%)`,
                top: "-150px",
                left: "-100px",
              },
              "&:after": {
                content: '""',
                position: "absolute",
                width: "300px",
                height: "300px",
                borderRadius: "50%",
                background: `radial-gradient(circle, ${theme.palette.primary.light}30, transparent 70%)`,
                bottom: "-150px",
                right: "-100px",
              },
            }}
          >
            <SectionTitle
              variant="h4"
              component="h2"
              align="center"
              sx={{ mb: 6 }}
            >
              {t("about.statisticsTitle")}
            </SectionTitle>

            <Box sx={{ position: "relative", zIndex: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 4 }}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={6} md={3}>
                  <Paper
                    elevation={4}
                    sx={{
                      py: 4,
                      px: 2,
                      textAlign: "center",
                      borderRadius: 4,
                      overflow: "hidden",
                      position: "relative",
                      height: "100%",
                      transition: "all 0.3s ease",
                      background: `linear-gradient(135deg, white, ${theme.palette.primary.light}10)`,
                      "&:hover": {
                        transform: "translateY(-10px) scale(1.05)",
                        boxShadow: "0 20px 40px rgba(0, 0, 0, 0.15)",
                      },
                      "&:before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "5px",
                        background: `linear-gradient(90deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
                      },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        fontWeight: 800,
                        color: theme.palette.primary.main,
                        mb: 1,
                        textShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      10+
                    </Typography>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      sx={{ fontWeight: 600 }}
                    >
                      {t("about.stat1")}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={6} md={3}>
                  <Paper
                    elevation={4}
                    sx={{
                      py: 4,
                      px: 2,
                      textAlign: "center",
                      borderRadius: 4,
                      overflow: "hidden",
                      position: "relative",
                      height: "100%",
                      transition: "all 0.3s ease",
                      background: `linear-gradient(135deg, white, ${theme.palette.success.light}10)`,
                      "&:hover": {
                        transform: "translateY(-10px) scale(1.05)",
                        boxShadow: "0 20px 40px rgba(0, 0, 0, 0.15)",
                      },
                      "&:before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "5px",
                        background: `linear-gradient(90deg, ${theme.palette.success.light}, ${theme.palette.success.main})`,
                      },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        fontWeight: 800,
                        color: theme.palette.success.main,
                        mb: 1,
                        textShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        background: `linear-gradient(135deg, ${theme.palette.success.main}, ${theme.palette.success.dark})`,
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      500+
                    </Typography>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      sx={{ fontWeight: 600 }}
                    >
                      {t("about.stat2")}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={6} md={3}>
                  <Paper
                    elevation={4}
                    sx={{
                      py: 4,
                      px: 2,
                      textAlign: "center",
                      borderRadius: 4,
                      overflow: "hidden",
                      position: "relative",
                      height: "100%",
                      transition: "all 0.3s ease",
                      background: `linear-gradient(135deg, white, ${theme.palette.info.light}10)`,
                      "&:hover": {
                        transform: "translateY(-10px) scale(1.05)",
                        boxShadow: "0 20px 40px rgba(0, 0, 0, 0.15)",
                      },
                      "&:before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "5px",
                        background: `linear-gradient(90deg, ${theme.palette.info.light}, ${theme.palette.info.main})`,
                      },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        fontWeight: 800,
                        color: theme.palette.info.main,
                        mb: 1,
                        textShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        background: `linear-gradient(135deg, ${theme.palette.info.main}, ${theme.palette.info.dark})`,
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      100+
                    </Typography>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      sx={{ fontWeight: 600 }}
                    >
                      {t("about.stat3")}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={6} md={3}>
                  <Paper
                    elevation={4}
                    sx={{
                      py: 4,
                      px: 2,
                      textAlign: "center",
                      borderRadius: 4,
                      overflow: "hidden",
                      position: "relative",
                      height: "100%",
                      transition: "all 0.3s ease",
                      background: `linear-gradient(135deg, white, ${theme.palette.warning.light}10)`,
                      "&:hover": {
                        transform: "translateY(-10px) scale(1.05)",
                        boxShadow: "0 20px 40px rgba(0, 0, 0, 0.15)",
                      },
                      "&:before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "5px",
                        background: `linear-gradient(90deg, ${theme.palette.warning.light}, ${theme.palette.warning.main})`,
                      },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        fontWeight: 800,
                        color: theme.palette.warning.main,
                        mb: 1,
                        textShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        background: `linear-gradient(135deg, ${theme.palette.warning.main}, ${theme.palette.warning.dark})`,
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      10+
                    </Typography>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      sx={{ fontWeight: 600 }}
                    >
                      {t("about.stat4")}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        {/* Testimonials */}
        <Box sx={{ mb: 10 }}>
          <SectionTitle variant="h4" component="h2" align="center">
            {t("about.testimonialsTitle")}
          </SectionTitle>

          <Box sx={{ position: "relative", mt: 6 }}>
            {/* Navigation Arrows */}
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: { xs: -10, md: -20 },
                transform: "translateY(-50%)",
                zIndex: 5,
                display: { xs: "none", sm: "block" },
              }}
            >
              <IconButton
                aria-label={t("about.previous")}
                onClick={() => scrollTestimonials("left")}
                sx={{
                  bgcolor: "background.paper",
                  boxShadow: "0 3px 10px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    bgcolor: theme.palette.primary.light,
                  },
                }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
            </Box>

            <Box
              sx={{
                position: "absolute",
                top: "50%",
                right: { xs: -10, md: -20 },
                transform: "translateY(-50%)",
                zIndex: 5,
                display: { xs: "none", sm: "block" },
              }}
            >
              <IconButton
                aria-label={t("about.next")}
                onClick={() => scrollTestimonials("right")}
                sx={{
                  bgcolor: "background.paper",
                  boxShadow: "0 3px 10px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    bgcolor: theme.palette.primary.light,
                  },
                }}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </Box>

            {/* Horizontal Scrolling Testimonials */}
            <Box
              ref={testimonialRef}
              sx={{
                display: "flex",
                overflowX: "auto",
                pb: 3,
                gap: 3,
                scrollBehavior: "smooth",
                scrollSnapType: "x mandatory",
                "&::-webkit-scrollbar": {
                  height: 8,
                },
                "&::-webkit-scrollbar-track": {
                  bgcolor: "rgba(0, 0, 0, 0.05)",
                  borderRadius: 4,
                },
                "&::-webkit-scrollbar-thumb": {
                  bgcolor: theme.palette.primary.light,
                  borderRadius: 4,
                  "&:hover": {
                    bgcolor: theme.palette.primary.main,
                  },
                },
                mx: { xs: -2, sm: 0 },
                px: { xs: 2, sm: 0 },
              }}
            >
              {testimonials.map((testimonial, index) => (
                <Card
                  key={index}
                  sx={{
                    minWidth: { xs: "90%", sm: "380px", md: 350 },
                    maxWidth: { xs: "90%", sm: "380px", md: 350 },
                    scrollSnapAlign: "start",
                    borderRadius: 4,
                    overflow: "visible",
                    position: "relative",
                    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.08)",
                    transition: "all 0.4s ease",
                    "&:hover": {
                      transform: "translateY(-10px)",
                      boxShadow: "0 20px 40px rgba(0, 0, 0, 0.12)",
                    },
                    "&:before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      height: 5,
                      backgroundColor: testimonial.color,
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                    },
                  }}
                >
                  <CardContent sx={{ p: 4, pb: 3 }}>
                    <FormatQuoteIcon
                      sx={{
                        position: "absolute",
                        top: -28,
                        left: 30,
                        fontSize: 70,
                        color: testimonial.lightColor,
                        transform: "rotate(180deg)",
                        zIndex: 1,
                      }}
                    />
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{
                        mb: 3,
                        position: "relative",
                        zIndex: 1,
                        lineHeight: 1.8,
                      }}
                    >
                      {testimonial.text}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          alt={testimonial.name}
                          sx={{
                            width: 54,
                            height: 54,
                            mr: 2,
                            border: `2px solid ${theme.palette.background.paper}`,
                            boxShadow: "0 3px 10px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          {testimonial.name.charAt(0)}
                        </Avatar>
                        <Box>
                          <Typography variant="subtitle1" fontWeight="bold">
                            {testimonial.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {testimonial.title}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {renderRating(testimonial.rating)}
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>

            {/* Scroll Indicators */}
            <Box
              sx={{ display: "flex", justifyContent: "center", mt: 3, gap: 1 }}
            >
              {[...Array(5)].map((_, i) => (
                <Box
                  key={i}
                  onClick={() => {
                    if (testimonialRef.current) {
                      testimonialRef.current.scrollLeft =
                        i * (window.innerWidth > 900 ? 1050 : 750);
                    }
                  }}
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: "50%",
                    bgcolor:
                      i === 0
                        ? theme.palette.primary.main
                        : theme.palette.grey[300],
                    transition: "all 0.3s ease",
                    cursor: "pointer",
                    "&:hover": {
                      transform: "scale(1.2)",
                      bgcolor: theme.palette.primary.light,
                    },
                  }}
                />
              ))}
            </Box>
          </Box>
        </Box>

        {/* Call to Action */}
        <Box>
          <CtaSection>
            <Box
              sx={{
                position: "relative",
                zIndex: 2,
              }}
            >
              <Typography
                variant="h4"
                component="h3"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  textShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                {t("about.ctaTitle")}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: 4,
                  maxWidth: 800,
                  mx: "auto",
                  opacity: 0.9,
                }}
              >
                {t("about.ctaDesc")}
              </Typography>
              <Button
                component={RouterLink}
                to="/iletisim"
                variant="contained"
                size="large"
                endIcon={<ArrowForwardIcon />}
                sx={{
                  px: 5,
                  py: 1.5,
                  backgroundColor: "white",
                  color: "primary.main",
                  fontWeight: 700,
                  borderRadius: 3,
                  boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    transform: "translateY(-5px)",
                    boxShadow: "0 15px 30px rgba(0, 0, 0, 0.15)",
                  },
                  transition: "all 0.3s ease",
                }}
              >
                {t("about.ctaButton")}
              </Button>
            </Box>
          </CtaSection>
        </Box>
      </Container>
    </Box>
  );
};

// Add CSS Animations for basic fade-in effect
const fadeInAnimation = `
@keyframes fadeIn {
from { opacity: 0; transform: translateY(20px); }
to { opacity: 1; transform: translateY(0); }
}
`;

// Insert the style in the head
if (typeof document !== "undefined") {
  const style = document.createElement("style");
  style.innerHTML = fadeInAnimation;
  document.head.appendChild(style);
}

export default AboutPage;   