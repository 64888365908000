import React from "react";
import { Box } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Partner logoları
import efsanedersler from "../../assets/images/icons/efsanedersler.svg";
import logo from "../../assets/images/icons/logo.png";
import egitimcantam from "../../assets/images/icons/Çantam.png";
import sihirlitahta from "../../assets/images/icons/sihirlitahta.png";
import multiway from "../../assets/images/icons/multiway.png";
import osesislogoisimli from "../../assets/images/icons/osesislogoisimli.png";

// Partner bilgileri (logo ve URL)
const partners = [
  {
    logo: efsanedersler,
    url: "https://www.efsanedersler.com",
  },
  {
    logo: logo,
    url: "https://www.olcuegitim.com",
  },
  {
    logo: egitimcantam,
    url: "https://www.egitimcantam.com",
  },
  {
    logo: sihirlitahta,
    url: "https://www.sihirlitahta.com",
  },
  {
    logo: multiway,
    url: "https://www.multiwaycleanmarket.com",
  },
  {
    logo: osesislogoisimli,
    url: "https://www.osesis.com",
  },
];

const Partners = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Slider {...settings}>
        {partners.map((partner, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              px: 2,
              opacity: 0.9,
              transition: "opacity 0.3s",
              height: "100px", // Tüm logolar için sabit yükseklik
              backgroundColor: "white", // Arka plan rengi ekleyin
              "&:hover": {
                opacity: 1,
              },
            }}
          >
            <a
              href={partner.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "block",
                textDecoration: "none",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={partner.logo}
                alt={`Partner ${index + 1}`}
                style={{
                  width: "150px", // Sabit genişlik
                  height: "80px", // Sabit yükseklik
                  objectFit: "contain", // Oranları koru
                  display: "block", // Boşlukları engelle
                  margin: "auto", // Ortaya hizala
                  transition: "transform 0.3s",
                }}
              />
            </a>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Partners;
