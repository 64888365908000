// src/config/formConfig.js
// Düzeltilmiş versiyonu - QuotationForm bileşeni kaldırıldı ve sadece konfigürasyon bilgileri bırakıldı

// 1. Form Konfigürasyonu
// Her hizmet için özel alanları tanımlayan bir yapı

export const formFieldsConfig = {
  // Tüm hizmetlerde ortak olan alanlar
  commonFields: [
    { id: "fullName", label: "Ad Soyad", type: "text", required: true },
    { id: "email", label: "E-posta", type: "email", required: true },
    { id: "phone", label: "Telefon", type: "tel", required: true },
    { id: "institution", label: "Kurum Adı", type: "text", required: true },
    { id: "city", label: "İl", type: "text", required: true },
    { id: "district", label: "İlçe", type: "text", required: true },
    { id: "message", label: "Ek Bilgiler", type: "textarea", required: false },
  ],

  // Her hizmet için özel alanlar
  serviceFields: {
    "okul-yayinlari": [
      {
        id: "schoolType",
        label: "Okul Türü",
        type: "select",
        options: ["Anaokulu", "İlkokul", "Ortaokul", "Lise", "Diğer"],
        required: true,
      },
      {
        id: "studentCount",
        label: "Öğrenci Sayısı",
        type: "number",
        required: true,
      },
      {
        id: "subjectAreas",
        label: "İlgilenilen Ders Alanları",
        type: "checkbox",
        options: [
          "Türkçe",
          "Matematik",
          "Fen Bilimleri",
          "Sosyal Bilgiler",
          "İngilizce",
          "Diğer",
        ],
        required: true,
      },
      {
        id: "publicationSchedule",
        label: "Yayın Takvimi",
        type: "select",
        options: [
          "Acil (1 ay içinde)",
          "1-3 ay içinde",
          "3-6 ay içinde",
          "Gelecek eğitim yılı için",
        ],
        required: true,
      },
    ],

    "otomasyon-sistemleri": [
      {
        id: "systemType",
        label: "Otomasyon Türü",
        type: "checkbox",
        options: [
          "Öğrenci Takip",
          "Yoklama Sistemi",
          "Not Sistemi",
          "Veli İletişim",
          "Envanter Yönetimi",
          "Diğer",
        ],
        required: true,
      },
      {
        id: "currentSystem",
        label: "Mevcut Kullandığınız Sistem",
        type: "text",
        required: false,
      },
      {
        id: "userCount",
        label: "Kullanıcı Sayısı (Öğretmen/İdari)",
        type: "number",
        required: true,
      },
      {
        id: "studentCount",
        label: "Öğrenci Sayısı",
        type: "number",
        required: true,
      },
      {
        id: "parentAccess",
        label: "Veli Erişimi Gerekli mi?",
        type: "radio",
        options: ["Evet", "Hayır"],
        required: true,
      },
      {
        id: "mobileAccess",
        label: "Mobil Uygulama İhtiyacı",
        type: "radio",
        options: ["Evet", "Hayır"],
        required: true,
      },
    ],

    "web-yazilim": [
      {
        id: "websiteType",
        label: "Web Sitesi Türü",
        type: "select",
        options: [
          "Kurumsal Web Sitesi",
          "E-Öğrenme Platformu",
          "Okul-Veli Portal",
          "Diğer",
        ],
        required: true,
      },
      {
        id: "hasExistingWebsite",
        label: "Mevcut Web Siteniz Var mı?",
        type: "radio",
        options: ["Evet", "Hayır"],
        required: true,
      },
      {
        id: "existingWebsite",
        label: "Mevcut Web Site Adresi",
        type: "text",
        required: false,
        conditional: { field: "hasExistingWebsite", value: "Evet" },
      },
      {
        id: "contentManagement",
        label: "İçerik Yönetimi Kimin Tarafından Yapılacak?",
        type: "select",
        options: [
          "Kurum Personeli",
          "Dış Kaynak (Sizin tarafınızdan)",
          "Karma Model",
        ],
        required: true,
      },
      {
        id: "designPreferences",
        label: "Tasarım Tercihleri/Referanslar",
        type: "textarea",
        required: false,
      },
    ],

    "resmiyet-kontrol": [
      {
        id: "institutionType",
        label: "Kurum Türü",
        type: "select",
        options: [
          "Özel Okul",
          "Özel Kurs",
          "Etüt Merkezi",
          "Rehabilitasyon Merkezi",
          "Diğer",
        ],
        required: true,
      },
      {
        id: "licenseStatus",
        label: "Lisans/Ruhsat Durumu",
        type: "select",
        options: [
          "Yeni Başvuru",
          "Yenileme",
          "Tadilat/Değişiklik",
          "Denetim Hazırlığı",
        ],
        required: true,
      },
      {
        id: "staffTrainingNeeds",
        label: "Personel Eğitim İhtiyaçları",
        type: "checkbox",
        options: [
          "Evrak Yönetimi",
          "Resmi Yazışmalar",
          "Mevzuat Bilgilendirme",
          "Denetim Hazırlık",
          "Diğer",
        ],
        required: true,
      },
      {
        id: "urgency",
        label: "Aciliyet Durumu",
        type: "select",
        options: [
          "Acil (1 hafta içinde)",
          "1-2 hafta içinde",
          "1 ay içinde",
          "Belirli bir tarihte",
        ],
        required: true,
      },
    ],

    "kodlama-egitimi": [
      {
        id: "ageGroups",
        label: "Hedef Yaş Grupları",
        type: "checkbox",
        options: ["5-8 yaş", "9-12 yaş", "13-15 yaş", "16-18 yaş"],
        required: true,
      },
      {
        id: "programmingTypes",
        label: "İstenen Kodlama Türleri",
        type: "checkbox",
        options: [
          "Blok Kodlama",
          "Web Geliştirme",
          "Mobil Uygulama",
          "Oyun Geliştirme",
          "Robotik Kodlama",
          "Diğer",
        ],
        required: true,
      },
      {
        id: "teacherTraining",
        label: "Öğretmen Eğitimi İhtiyacı",
        type: "radio",
        options: ["Evet", "Hayır"],
        required: true,
      },
      {
        id: "currentInfrastructure",
        label: "Mevcut Altyapı/Donanım",
        type: "textarea",
        required: false,
      },
      {
        id: "studentCount",
        label: "Öğrenci Sayısı",
        type: "number",
        required: true,
      },
    ],

    robotik: [
      {
        id: "roboticsSets",
        label: "İlgilenilen Robotik Setler",
        type: "checkbox",
        options: [
          "Lego Education",
          "Arduino",
          "Makey Makey",
          "mBot",
          "Raspberry Pi",
          "VEX Robotics",
          "Diğer",
        ],
        required: true,
      },
      {
        id: "ageGroups",
        label: "Hedef Yaş Grupları",
        type: "checkbox",
        options: ["5-8 yaş", "9-12 yaş", "13-15 yaş", "16-18 yaş"],
        required: true,
      },
      {
        id: "labSetup",
        label: "Laboratuvar Kurulumu İhtiyacı",
        type: "radio",
        options: ["Evet", "Hayır", "Mevcut Laboratuvar Geliştirme"],
        required: true,
      },
      {
        id: "teacherTraining",
        label: "Öğretmen Eğitimi İhtiyacı",
        type: "radio",
        options: ["Evet", "Hayır"],
        required: true,
      },
      {
        id: "budget",
        label: "Yaklaşık Bütçe Aralığı",
        type: "select",
        options: [
          "10.000 TL altı",
          "10.000 - 25.000 TL",
          "25.000 - 50.000 TL",
          "50.000 TL üzeri",
        ],
        required: false,
      },
    ],

    "yemek-hizmetleri": [
      {
        id: "mealTypes",
        label: "İhtiyaç Duyulan Öğünler",
        type: "checkbox",
        options: [
          "Sabah Kahvaltısı",
          "Öğle Yemeği",
          "İkindi Kahvaltısı",
          "Akşam Yemeği",
        ],
        required: true,
      },
      {
        id: "studentCount",
        label: "Öğrenci Sayısı",
        type: "number",
        required: true,
      },
      {
        id: "staffCount",
        label: "Personel Sayısı",
        type: "number",
        required: true,
      },
      {
        id: "dietaryRestrictions",
        label: "Özel Diyet İhtiyaçları",
        type: "textarea",
        required: false,
      },
      {
        id: "kitchenAvailability",
        label: "Mutfak İmkanı",
        type: "radio",
        options: ["Var (Tam donanımlı)", "Var (Kısmi donanımlı)", "Yok"],
        required: true,
      },
      {
        id: "serviceType",
        label: "Servis Şekli",
        type: "select",
        options: ["Yerinde Pişirme", "Taşıma Yemek", "Karma Model"],
        required: true,
      },
    ],

    "servis-hizmetleri": [
      {
        id: "routeCount",
        label: "Tahmini Servis Güzergah Sayısı",
        type: "number",
        required: true,
      },
      {
        id: "studentCount",
        label: "Taşınacak Öğrenci Sayısı",
        type: "number",
        required: true,
      },
      {
        id: "vehicleTypes",
        label: "İhtiyaç Duyulan Araç Tipleri",
        type: "checkbox",
        options: ["Minibüs (14+1)", "Midibüs (25+1)", "Otobüs (40+1 üzeri)"],
        required: true,
      },
      {
        id: "trackingSystem",
        label: "Takip Sistemi İhtiyacı",
        type: "radio",
        options: ["Evet", "Hayır"],
        required: true,
      },
      {
        id: "hostessRequired",
        label: "Hostes İhtiyacı",
        type: "radio",
        options: ["Evet", "Hayır"],
        required: true,
      },
      {
        id: "serviceSchedule",
        label: "Servis Saatleri",
        type: "textarea",
        required: true,
      },
    ],

    kirtasiye: [
      {
        id: "productCategories",
        label: "İhtiyaç Duyulan Ürün Kategorileri",
        type: "checkbox",
        options: [
          "Okul Defterleri",
          "Kitaplar",
          "Resim Malzemeleri",
          "El İşi Malzemeleri",
          "Ofis Kırtasiye",
          "Diğer",
        ],
        required: true,
      },
      {
        id: "studentCount",
        label: "Öğrenci Sayısı",
        type: "number",
        required: true,
      },
      {
        id: "purchaseFrequency",
        label: "Satın Alma Sıklığı",
        type: "select",
        options: ["Yıllık Tek Seferde", "Dönemlik", "Aylık", "İhtiyaç Oldukça"],
        required: true,
      },
      {
        id: "customPrinting",
        label: "Özel Baskı İhtiyacı",
        type: "radio",
        options: ["Evet", "Hayır"],
        required: true,
      },
      {
        id: "estimatedBudget",
        label: "Tahmini Bütçe",
        type: "select",
        options: [
          "10.000 TL altı",
          "10.000 - 25.000 TL",
          "25.000 - 50.000 TL",
          "50.000 - 100.000 TL",
          "100.000 TL üzeri",
        ],
        required: false,
      },
    ],

    "temizlik-gida": [
      {
        id: "productCategories",
        label: "İhtiyaç Duyulan Ürün Kategorileri",
        type: "checkbox",
        options: [
          "Temizlik Malzemeleri",
          "Hijyen Ürünleri",
          "Gıda Ürünleri",
          "Kantin Ürünleri",
          "Diğer",
        ],
        required: true,
      },
      {
        id: "purchaseFrequency",
        label: "Satın Alma Sıklığı",
        type: "select",
        options: ["Haftalık", "Aylık", "Dönemlik", "Yıllık"],
        required: true,
      },
      {
        id: "studentCount",
        label: "Öğrenci Sayısı",
        type: "number",
        required: true,
      },
      {
        id: "ecoCertRequired",
        label: "Eko-Sertifikalı Ürün İhtiyacı",
        type: "radio",
        options: ["Evet", "Hayır"],
        required: false,
      },
      {
        id: "storageSpace",
        label: "Depolama Alanı",
        type: "select",
        options: ["Küçük", "Orta", "Büyük", "Çok Büyük"],
        required: true,
      },
    ],

    "ogretmen-egitimleri": [
      {
        id: "trainingAreas",
        label: "Eğitim Alanları",
        type: "checkbox",
        options: [
          "Yeni Eğitim Metodolojileri",
          "Teknoloji Entegrasyonu",
          "Sınıf Yönetimi",
          "Ölçme ve Değerlendirme",
          "Diğer",
        ],
        required: true,
      },
      {
        id: "teacherCount",
        label: "Eğitim Alacak Öğretmen Sayısı",
        type: "number",
        required: true,
      },
      {
        id: "preferredDates",
        label: "Tercih Edilen Tarih Aralığı",
        type: "text",
        required: false,
      },
      {
        id: "trainingLocation",
        label: "Eğitim Lokasyonu",
        type: "radio",
        options: ["Okulumuzda", "Sizin Belirlediğiniz Yerde", "Online"],
        required: true,
      },
      {
        id: "trainingDuration",
        label: "Eğitim Süresi Tercihi",
        type: "select",
        options: [
          "Yarım Gün",
          "Tam Gün",
          "Hafta Sonu",
          "Hafta İçi Akşam",
          "Uzun Dönemli Program",
        ],
        required: true,
      },
    ],

    "kayit-danismanligi": [
      {
        id: "institutionType",
        label: "Kurum Türü",
        type: "select",
        options: [
          "Anaokulu",
          "İlkokul",
          "Ortaokul",
          "Lise",
          "Karma Okul",
          "Diğer",
        ],
        required: true,
      },
      {
        id: "currentStudentCount",
        label: "Mevcut Öğrenci Sayısı",
        type: "number",
        required: true,
      },
      {
        id: "targetStudentCount",
        label: "Hedef Öğrenci Sayısı",
        type: "number",
        required: true,
      },
      {
        id: "consultancyAreas",
        label: "Danışmanlık Alanları",
        type: "checkbox",
        options: [
          "Veli İletişim Stratejileri",
          "Sosyal Medya Yönetimi",
          "Etkinlik Organizasyonu",
          "Kayıt Dönemi Planlaması",
          "Marka Değeri Artırma",
          "Diğer",
        ],
        required: true,
      },
      {
        id: "marketingBudget",
        label: "Pazarlama Bütçesi",
        type: "select",
        options: [
          "5.000 TL altı",
          "5.000 - 15.000 TL",
          "15.000 - 50.000 TL",
          "50.000 TL üzeri",
        ],
        required: false,
      },
      {
        id: "previousCampaigns",
        label: "Önceki Kayıt Kampanyaları",
        type: "textarea",
        required: false,
      },
    ],
  },
};

// 2. Hizmet Bilgilerini Tutan Yapı
export const serviceDetails = {
  "okul-yayinlari": {
    id: "okul-yayinlari",
    title: "Okul Yayınları",
    description: "Okulunuza özel yayınlar ile eğitim kalitesini artırın.",
    icon: "BookIcon",
    formTitle: "Okul Yayınları Teklif Formu",
    formDescription:
      "Okulunuza özel yayınlar için aşağıdaki formu doldurarak ücretsiz teklif alabilirsiniz.",
  },
  "otomasyon-sistemleri": {
    id: "otomasyon-sistemleri",
    title: "Okul Otomasyon Sistemleri",
    description:
      "Modern otomasyon sistemleri ile okulunuzun verimliliğini artırın.",
    icon: "ComputerIcon",
    formTitle: "Okul Otomasyon Sistemleri Teklif Formu",
    formDescription:
      "Okulunuza özel otomasyon çözümleri için aşağıdaki formu doldurarak ücretsiz teklif alabilirsiniz.",
  },
  "web-yazilim": {
    id: "web-yazilim",
    title: "Okul Web Yazılım",
    description:
      "Kurumunuzun dijital yüzü olan web sitesini profesyonel ekibimizle tasarlayın.",
    icon: "WebIcon",
    formTitle: "Okul Web Yazılım Teklif Formu",
    formDescription:
      "Kurumunuz için özel web yazılım çözümleri için aşağıdaki formu doldurarak ücretsiz teklif alabilirsiniz.",
  },
  "resmiyet-kontrol": {
    id: "resmiyet-kontrol",
    title: "Okul Resmiyet Kontrol ve Personel Eğitimi",
    description:
      "Eğitim kurumunuzun resmi süreçlerinin doğru yönetimi için danışmanlık ve personel eğitimi hizmetleri.",
    icon: "AdminPanelSettingsIcon",
    formTitle: "Resmiyet Kontrol Teklif Formu",
    formDescription:
      "Kurumunuzun resmi süreçlerinin yönetimi için danışmanlık hizmetleri hakkında bilgi alın.",
  },
  "kodlama-egitimi": {
    id: "kodlama-egitimi",
    title: "Kodlama ve Yazılım Eğitimi",
    description:
      "Geleceğin teknolojilerini öğrencilerinize bugünden kazandırın.",
    icon: "CodeIcon",
    formTitle: "Kodlama Eğitimi Teklif Formu",
    formDescription:
      "Öğrencileriniz için kodlama eğitimi hizmetlerimiz hakkında bilgi almak için formu doldurun.",
  },
  robotik: {
    id: "robotik",
    title: "Robotik Malzemeler",
    description:
      "En son teknoloji robotik malzemeler ile STEM eğitiminizi güçlendirin.",
    icon: "PrecisionManufacturingIcon",
    formTitle: "Robotik Malzemeler Teklif Formu",
    formDescription:
      "STEM eğitiminiz için robotik malzeme ihtiyaçlarınızı belirtin ve ücretsiz teklif alın.",
  },
  "yemek-hizmetleri": {
    id: "yemek-hizmetleri",
    title: "Yemek Hizmetleri",
    description:
      "Öğrencilerinize sağlıklı, dengeli ve lezzetli yemek hizmetleri.",
    icon: "FastfoodIcon",
    formTitle: "Yemek Hizmetleri Teklif Formu",
    formDescription:
      "Kurumunuz için yemek hizmetleri hakkında detaylı bilgi ve teklif almak için formu doldurun.",
  },
  "servis-hizmetleri": {
    id: "servis-hizmetleri",
    title: "Servis Hizmetleri",
    description: "Güvenli ve konforlu okul servis çözümleri.",
    icon: "AirportShuttleIcon",
    formTitle: "Servis Hizmetleri Teklif Formu",
    formDescription:
      "Öğrencileriniz için güvenli ve konforlu ulaşım çözümleri hakkında bilgi alın.",
  },
  kirtasiye: {
    id: "kirtasiye",
    title: "Kırtasiye Ürünleri",
    description:
      "Okulunuzun kırtasiye ihtiyaçlarını en uygun fiyatlarla karşılıyoruz.",
    icon: "BorderColorIcon",
    formTitle: "Kırtasiye Ürünleri Teklif Formu",
    formDescription:
      "Kurumunuzun kırtasiye ihtiyaçları için toplu alım avantajlarından yararlanın.",
  },
  "temizlik-gida": {
    id: "temizlik-gida",
    title: "Temizlik ve Gıda Ürünleri",
    description:
      "Eğitim kurumları için kaliteli temizlik ve gıda ürünleri tedariği.",
    icon: "CleaningServicesIcon",
    formTitle: "Temizlik ve Gıda Ürünleri Teklif Formu",
    formDescription:
      "Kurumunuzun temizlik ve gıda ürünleri ihtiyaçları için ücretsiz teklif alın.",
  },
  "ogretmen-egitimleri": {
    id: "ogretmen-egitimleri",
    title: "Öğretmen Eğitimleri ve Seminerleri",
    description:
      "Profesyonel eğitimlerle öğretmenlerinizin gelişimini destekleyin.",
    icon: "SchoolIcon",
    formTitle: "Öğretmen Eğitimleri Teklif Formu",
    formDescription:
      "Öğretmenleriniz için profesyonel gelişim eğitimleri hakkında bilgi almak için formu doldurun.",
  },
  "kayit-danismanligi": {
    id: "kayit-danismanligi",
    title: "Kayıt Danışmanlığı",
    description:
      "Öğrenci kayıt süreçlerinizi profesyonel danışmanlık ile optimize edin.",
    icon: "AssignmentIndIcon",
    formTitle: "Kayıt Danışmanlığı Teklif Formu",
    formDescription:
      "Kurumunuzun doluluk oranını artıracak kayıt danışmanlığı hizmetlerimiz hakkında bilgi alın.",
  },
};
