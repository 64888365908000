import { formTranslations } from "./form";

export const translations = {
  common: {
    companyName: "ÖLÇÜ EĞİTİM VE TEDARİK HİZMETLERİ",
    loading: "Yükleniyor...",
    home: "Ana Sayfa",
    about: "Hakkımızda",
    services: "Hizmetlerimiz",
    contact: "İletişim",
    references: "Referanslarımız",
    language: "Dil",
    search: "Ara",
    more: "Daha Fazla",
    readMore: "Devamını Oku",
    ourServices: "Hizmetlerimiz",
    phoneNumber: "Telefon Numarası",
    email: "E-posta",
    address: "Adres",
    contactUs: "Bize Ulaşın",
    copyright: "Tüm hakları saklıdır",
    allServices: "Tüm Hizmetlerimiz",
    technicalSupport: "Teknik Destek",
    quickLinks: "Hızlı Bağlantılar",
    send: "Gönder",
    contactForm: "İletişim Formu",
    name: "Adınız Soyadınız",
    phone: "Telefon",
    message: "Mesajınız",
    workingHours: "Çalışma Saatleri",
    quotationRequest: "Teklif Al",
    explore: "Keşfedin",
    quotationForm: "Teklif Formu",
    thanks: "Teşekkürler!",
    quotationReceived: "Teklif talebiniz başarıyla alındı.",
    willContact: "Ekibimiz en kısa sürede sizinle iletişime geçecektir.",
    returnToServices: "Hizmetlere Dön",
    privacyPolicy: "Gizlilik Politikası",
    categories: "Kategoriler",
  },
  header: {
    callUs: "Bizi Arayın",
    sendEmail: "E-posta Gönderin",
    ourLocation: "Konumumuz",
  },
  services: {
    schoolPublications: "Okul Yayınları",
    schoolAutomation: "Okul Otomasyon Sistemleri",
    schoolWebSoftware: "Okul Web Yazılım",
    officialControl: "Okul Resmiyet Kontrol ve Personel Eğitimi",
    codingEducation: "Kodlama ve Yazılım Eğitimi",
    robotics: "Robotik Malzemeler",
    foodServices: "Yemek Hizmetleri",
    transportServices: "Servis Hizmetleri",
    stationery: "Kırtasiye Ürünleri",
    cleaningFood: "Temizlik ve Gıda Ürünleri",
    teacherTrainings: "Öğretmen Eğitimleri ve Seminerleri",
    registrationConsultancy: "Kayıt Danışmanlığı",
    servicesDescription:
      "Eğitim kurumları için tüm ihtiyaçlarınıza cevap veren profesyonel çözümler",
    education: "Eğitim",
    technology: "Teknoloji",
    consultancy: "Danışmanlık",
    supply: "Tedarik",
    support: "Destek",
    allCategories: "Tüm Kategoriler",
    noServicesInCategory: "Bu kategoride henüz hizmet bulunmuyor",
    selectAnotherCategory:
      "Başka bir kategori seçebilir veya tüm hizmetlerimizi görüntüleyebilirsiniz.",
    showAllServices: "Tüm Hizmetleri Göster",
    contact: "İletişime Geçin",
    contactDescription:
      "Hizmetlerimiz hakkında detaylı bilgi için bize ulaşın.",
  },
  home: {
    welcomeTitle: "Eğitim Çözümleri ve Danışmanlık Hizmetleri",
    welcomeTitle2: "Eğitimde Dijital Dönüşüm",
    welcomeSubtitle: "Kurumsal ve profesyonel çözümlerle yanınızdayız",
    ourReference: "Referanslarımız",
    testimonials: "Müşteri Yorumları",
    whyChooseUs: "Neden Bizi Tercih Etmelisiniz?",
    whyChooseUsSubtitle:
      "Eğitim kurumunuzu bir adım öne taşıyacak profesyonel çözüm ortağınız",
    educationExpertise: "Eğitim Uzmanlığı",
    educationExpertiseDesc:
      "10+ yıllık sektör deneyimi ile eğitim kurumlarının tüm ihtiyaçlarına yönelik uzman çözümler sunuyoruz.",
    digitalTransformation: "Dijital Dönüşüm",
    digitalTransformationDesc:
      "Modern teknolojileri kullanarak eğitim kurumlarının dijital dönüşümünü sağlıyor, verimliliklerini artırıyoruz.",
    supportService: "Teknik Destek",
    supportServiceDesc:
      "Teknik destek ekibimiz her zaman yanınızda. 09:00-18:00 arası çözüm odaklı destek hizmeti sunuyoruz.",
    customizedSolutions: "Özelleştirilmiş Çözümler",
    customizedSolutionsDesc:
      "Her kurumun kendine özgü ihtiyaçlarını analiz ediyor, size özel çözümler ve danışmanlık hizmetleri sunuyoruz.",
    servingInstitutions:
      "Türkiye'nin dört bir yanında 500+ eğitim kurumuna hizmet veriyoruz.",
    quickContact: "Hızlı İletişim",
    workingDays: "Haftanın 7 günü",
    ourPartners: "Markalarımız",
    digitalSolutions: "Dijital Çözümlerimiz",
    codingRoboticTitle: "Kodlama ve Robotik Eğitimler",
    modernSkills: "Öğrencilerinize 21. yüzyıl becerileri kazandırın",
    codingEducation: "Kodlama Eğitimi",
    roboticMaterials: "Robotik Malzemeler",
    testimonialDescription: "Müşterilerimizin bizimle ilgili görüşleri",
    professionalSolutions:
      "Eğitim kurumunuzun tüm ihtiyaçlarını karşılayacak profesyonel çözümler sunuyoruz",
    exploreAllServices: "Tüm Hizmetlerimizi Keşfedin",
    previous: "Önceki",
    next: "Sonraki",
    getQuote: "Teklif Al",
    viewServices: "Dijital Çözümlerimiz",
    schoolPublications: "Okul Yayınları",
    schoolPublicationsDesc:
      "Okulunuza özel yayınlar ile eğitim kalitesini artırın. Yayınlarımız tüm yaş gruplarına uygun olarak hazırlanmakta ve MEB müfredatına göre düzenlenmektedir.",
    schoolAutomation: "Okul Otomasyon Sistemleri",
    schoolAutomationDesc:
      "Modern otomasyon sistemleri ile okulunuzun verimliliğini artırın. Öğrenci takip, yoklama, not sistemi ve daha fazlasını tek bir platformda yönetin.",
    codingEducationDesc:
      "Geleceğin teknolojilerini öğrencilerinize bugünden kazandırın. Yaş gruplarına özel müfredatlar ve eğitim malzemeleri ile kodlama eğitimi çözümleri.",
    teacherTrainings: "Öğretmen Eğitimleri ve Seminerleri",
    teacherTrainingsDesc:
      "Profesyonel eğitimlerle öğretmenlerinizin gelişimini destekleyin. Alanında uzman eğitmenler eşliğinde, kurumunuza özel seminerler.",
    roboticsDesc:
      "En son teknoloji robotik malzemeler ile STEM eğitiminizi güçlendirin. Laboratuvar kurulumundan, eğitmen desteğine kadar tüm çözümler.",
    registrationConsultancy: "Kayıt Danışmanlığı",
    registrationConsultancyDesc:
      "Öğrenci kayıt süreçlerinizi profesyonel danışmanlık ile optimize edin. Doluluk oranınızı artıracak stratejik çözümler.",
  },
  contact: {
    getInTouch: "Bizimle İletişime Geçin",
    fullName: "Ad Soyad",
    email: "E-posta",
    phone: "Telefon",
    message: "Mesajınız",
    send: "Gönder",
    successMessage:
      "Mesajınız başarıyla gönderildi. En kısa sürede size dönüş yapacağız.",
    redirectMessage:
      "E-posta uygulamanıza yönlendiriliyorsunuz. Lütfen mesajınızı göndermek için işlemi tamamlayın.",
    errorMessage:
      "Mesajınız gönderilirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.",
    workingHours: "Çalışma Saatleri",
    address: "Adresimiz",
    contactUs: "Bize Ulaşın",
    formDescription:
      "Her türlü soru ve önerileriniz için formu doldurarak bize mesaj gönderebilirsiniz. En kısa sürede sizinle iletişime geçeceğiz.",
  },
  quotation: {
    title: "Teklif Formu",
    description: "Lütfen aşağıdaki formu doldurarak teklif talebinde bulunun.",
    sending: "Gönderiliyor...",
    sendRequest: "Teklif Talebini Gönder",
    privacyNote:
      "* Bu formu doldurarak, KVKK kapsamında kişisel verilerinizin işlenmesine ve sizinle iletişime geçilmesine onay vermiş olursunuz. Detaylı bilgi için Gizlilik Politikamızı inceleyebilirsiniz.",
    invalidService:
      "Geçerli bir hizmet seçilmedi. Lütfen hizmetler sayfasından bir hizmet seçin.",
    success: "Teşekkürler!",
    successMessage: "Teklif talebiniz başarıyla alındı.",
    contactSoon: "Ekibimiz en kısa sürede sizinle iletişime geçecektir.",
  },
  references: {
    title: "Referanslarımız",
    subtitle:
      "Güven ve gizlilik ilkelerimiz doğrultusunda çalışan profesyonel ekibimiz",
    privacyTitle: "Müşteri Gizliliği ve KVKK Uyumluluğu",
    privacyDescription1:
      "Şirketimiz, müşterilerimizle olan iş birliklerini ve başarı hikayelerini paylaşmayı değerli bulmaktadır. Ancak kişisel verilerin korunması ve müşteri bilgilerinin gizliliği konusundaki hassasiyetimiz doğrultusunda, 6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK) kapsamındaki yükümlülüklerimiz ve ticari sır niteliğindeki bilgilerin korunması ilkesi gereğince, müşteri referanslarımızı kamuya açık şekilde paylaşmamaktayız.",
    privacyDescription2:
      "Müşterilerimizle kurduğumuz güven ilişkisi ve veri sorumlusu olarak üstlendiğimiz sorumluluklar çerçevesinde, iş ortaklarımızın bilgilerini korumak önceliğimizdir.",
    dataSecurityTitle: "Veri Güvenliği",
    dataSecurityDescription:
      "Bilgi güvenliği politikalarımız doğrultusunda, tüm müşteri verilerimiz uluslararası standartlarda korunmaktadır. Kurumsal kimlik bilgileri dahil olmak üzere tüm veriler, müşteri izni olmadan paylaşılmamaktadır.",
    trustedPartnerTitle: "Güvenilir İş Ortağı",
    trustedPartnerDescription:
      "Sektörde yılların verdiği deneyim ve güvenle hizmet veriyoruz. Müşterilerimizle kurduğumuz uzun soluklu iş birlikleri ve yüksek müşteri memnuniyeti oranımız başarımızın en önemli göstergesidir.",
    contactTitle: "Referanslarımız ve Özel Görüşme İçin",
    contactDescription:
      "Potansiyel müşterilerimiz için özel görüşmeler düzenliyor, benzer sektördeki başarı hikayelerimizi ve referanslarımızı gizlilik çerçevesinde paylaşıyoruz. İletişim formumuz üzerinden bizimle iletişime geçerek hem referanslarımız hakkında bilgi alabilir hem de özel görüşme talebinde bulunabilirsiniz.",
    contactButtonText: "İletişim Formu",
    dataSafety: "Verileriniz güvende",
  },
  about: {
    heroTitle: "Hakkımızda",
    heroSubtitle:
      "2015'dan Beri Eğitim Danışmanlığı ve Tedarik Hizmetlerinde Güvenilir Çözüm Ortağınız",
    whoWeAre: "Biz Kimiz?",
    introFirstParagraph:
      "Ölçü Eğitim, 2015 yılından beri eğitim danışmanlığı ve tedarik hizmetleri alanında hizmet veren, gücünü müşteri memnuniyetinden alan, insan odaklı bir kurumdur. Bir yandan bireysel ve kurumsal danışmanlık hizmetleri sunarken, diğer yandan eğitim kurumlarının ihtiyaç duyduğu ürünlerin tedarikinde güvenilir çözümler sunuyoruz.",
    introSecondParagraph:
      "Eğitim sektöründeki deneyimimiz ve uzman kadromuzla, her ölçekteki eğitim kurumuna özel çözümler geliştiriyor, tedarik zincirinizi güçlendiriyor ve kurumların büyüme ve gelişme süreçlerine katkıda bulunuyoruz. İlkelerimizden ödün vermeden, yenilikçi yaklaşımlarla eğitim sektörüne değer katmaya devam ediyoruz.",
    servicesTitle: "Danışmanlık ve Tedarik Hizmetlerimiz",
    educationConsultingTitle: "Eğitim Danışmanlığı",
    educationConsultingDesc:
      "Kurumunuzun akademik başarısını arttırmak ve eğitim kalitesini yükseltmek için uzman ekibimizle kapsamlı danışmanlık hizmetleri sunuyoruz.",
    educationFeature1: "Kurumsal Eğitim Danışmanlığı",
    educationFeature2: "Eğitim Programı Geliştirme",
    educationFeature3: "Öğretmen ve Yönetici Eğitimleri",
    educationFeature4: "Akademik Başarı Koçluğu",
    supplyServicesTitle: "Tedarik Hizmetleri",
    supplyServicesDesc:
      "Eğitim kurumlarınızın ihtiyaç duyduğu tüm materyallerin ve ekipmanların tedariğini güvenilir ve uygun maliyetlerle sağlıyoruz.",
    supplyFeature1: "Eğitim Materyalleri Tedariği",
    supplyFeature2: "Teknolojik Ekipman Çözümleri",
    supplyFeature3: "Kırtasiye ve Ofis Malzemeleri",
    supplyFeature4: "Okul Mobilyaları",
    visionTitle: "Vizyonumuz",
    visionDesc:
      "Eğitim danışmanlığı ve tedarik alanında lider konuma gelerek, yenilikçi ve sürdürülebilir çözümlerle Türkiye'deki eğitim kurumlarının kalitesini yükseltmek ve uluslararası standartlara ulaşmalarına katkıda bulunmak.",
    missionTitle: "Misyonumuz",
    missionDesc:
      "Eğitim kurumlarının ihtiyaçlarını doğru analiz ederek, teknoloji ve insan odaklı çözümlerle kurumların verimliliğini artırmak, tedarik süreçlerini optimize etmek, öğrenci ve öğretmen memnuniyetini en üst seviyeye çıkarmak ve eğitimde kalite standartlarını yükseltmek.",
    valuesTitle: "Değerlerimiz",
    value1Title: "Güvenilirlik",
    value1Desc:
      "Tüm iş süreçlerimizde şeffaflık ve dürüstlük en önemli prensibimizdir. Verdiğimiz sözlerin arkasında durur, müşterilerimizin güvenini kazanırız.",
    value2Title: "Yenilikçilik",
    value2Desc:
      "Sürekli gelişim ve yenilikçi çözümler için araştırma ve geliştirmeye önem veriyor, eğitim sektöründeki en son trendleri takip ediyoruz.",
    value3Title: "İnsan Odaklılık",
    value3Desc:
      "Her çözümümüzün merkezinde eğitim kurumlarında yer alan insanlar vardır. Müşteri memnuniyeti ve insan odaklı yaklaşımla çözümler sunuyoruz.",
    statisticsTitle: "Rakamlarla Ölçü Eğitim",
    stat1: "Yıllık Deneyim",
    stat2: "Eğitim Kurumu",
    stat3: "Uzman Personel",
    stat4: "Hizmet Alanı",
    testimonialsTitle: "Müşterilerimiz Ne Diyor?",
    previous: "Önceki",
    next: "Sonraki",
    ctaTitle:
      "Eğitim Danışmanlığı ve Tedarik Hizmetlerimizden Yararlanmak İster Misiniz?",
    ctaDesc:
      "Kurumunuzun ihtiyaçlarına özel çözümler için bizimle iletişime geçin. Size uygun danışmanlık ve tedarik planları sunalım.",
    ctaButton: "Bizimle İletişime Geçin",
    testimonials: {
      testimonial1:
        "Ölçü Eğitim'in sunduğu danışmanlık hizmetiyle okulumuzun akademik başarısı son iki yılda %30 arttı. Profesyonel ekipleri ve çözüm odaklı yaklaşımları için teşekkür ederiz.",
      testimonial2:
        "Otomasyona geçiş sürecimizde Ölçü Eğitim'in desteği olmasaydı bu kadar hızlı adapte olamazdık. Özel yazılım çözümleri ve tedarik hizmetleri sayesinde idari süreçlerimiz artık çok daha verimli.",
      testimonial3:
        "Hem danışmanlık hem de tedarik hizmetleri için Ölçü Eğitim ile çalışmaya başladığımızdan beri işlerimiz kolaylaştı, maliyetlerimiz düştü ve öğrencilerimizin başarısı arttı.",
      testimonial4:
        "Tedarik süreçlerindeki uzmanlıkları sayesinde kurumumuz büyük bir maliyet avantajı yakaladı. Aynı zamanda sundukları eğitim danışmanlığı hizmetleri ile öğrencilerimizin başarı grafiği yükseldi.",
      testimonial5:
        "Ölçü Eğitim'in okul yayınları ve eğitim materyalleri tedariğinde gösterdiği titizlik ve hız sayesinde dönem başı hazırlıklarımız sorunsuz tamamlandı. Öğretmenlerimiz de memnun.",
      testimonial6:
        "Kodlama eğitimleri ve robotik setlerin tedariği konusunda uzman bir ekiple çalıştık. Öğrencilerimizin teknoloji alanında aldığı başarılar kendilerini kanıtlıyor.",
      testimonial7:
        "Yeni açılan okulumuzun tüm ihtiyaçlarını tek elden çözüme kavuşturdular. Danışmanlık ve tedarik konusunda profesyonel bir yaklaşım sergilediler.",
      testimonial8:
        "Öğretmen eğitimleri ve profesyonel gelişim programları konusunda sundukları hizmet beklentilerimizin üzerindeydi. Kurumumuza özel çözümler geliştirdiler.",
      testimonial9:
        "Okulumuzun kırtasiye ve mobilya ihtiyaçlarının temininde sağladıkları kalite ve ekonomik çözümler sayesinde uzun vadeli bir işbirliği yakaladık.",
      testimonial10:
        "3 yıldır Ölçü Eğitim ile çalışıyoruz ve her geçen yıl işbirliğimizi daha da genişletiyoruz. Eğitim danışmanlığı ve tedarik süreçlerinde gösterdikleri profesyonellik takdire şayan.",
      role1: "Okul Müdürü",
      role2: "Bilgi Teknolojileri Öğretmeni",
      role3: "Okul Kurucusu",
      role4: "İlkokul Müdürü",
      role5: "Satın Alma Müdürü",
      role6: "Bilişim Teknolojileri Zümre Başkanı",
      role7: "Okul Yöneticisi",
      role8: "Eğitim Koordinatörü",
      role9: "İdari İşler Sorumlusu",
      role10: "Genel Müdür",
    },
  },
  footer: {
    companyDescription:
      "ÖLÇÜ EĞİTİM VE TEDARİK HİZMETLERİ, eğitim kurumlarına özel çözümler ve danışmanlık hizmetleri sunmaktadır. Kaliteli ve profesyonel hizmet anlayışıyla sektörde öncü bir konumdayız.",
    technicalSupport: "Teknik Destek",
    supportDescription:
      "ÖLÇÜ'de 09:00 / 18:00 arası teknik destek alabileceğinizi unutmayın. Sorunlarınızı çözmenize yardımcı oluyoruz.",
  },

  // Form çevirilerini ekle
  form: formTranslations,
};

export default translations;
