// src/locales/tr/form.js
export const formTranslations = {
  institution: "Kurum Adı",
  city: "İl",
  district: "İlçe",
  select: "Seçiniz",
  schoolType: "Okul Türü",
  studentCount: "Öğrenci Sayısı",
  subjectAreas: "İlgilenilen Ders Alanları",
  publicationSchedule: "Yayın Takvimi",
  systemType: "Otomasyon Türü",
  currentSystem: "Mevcut Kullandığınız Sistem",
  userCount: "Kullanıcı Sayısı (Öğretmen/İdari)",
  parentAccess: "Veli Erişimi Gerekli mi?",
  mobileAccess: "Mobil Uygulama İhtiyacı",
  websiteType: "Web Sitesi Türü",
  hasExistingWebsite: "Mevcut Web Siteniz Var mı?",
  existingWebsite: "Mevcut Web Site Adresi",
  contentManagement: "İçerik Yönetimi Kimin Tarafından Yapılacak?",
  designPreferences: "Tasarım Tercihleri/Referanslar",
  institutionType: "Kurum Türü",
  licenseStatus: "Lisans/Ruhsat Durumu",
  staffTrainingNeeds: "Personel Eğitim İhtiyaçları",
  urgency: "Aciliyet Durumu",
  ageGroups: "Hedef Yaş Grupları",
  programmingTypes: "İstenen Kodlama Türleri",
  teacherTraining: "Öğretmen Eğitimi İhtiyacı",
  currentInfrastructure: "Mevcut Altyapı/Donanım",
  roboticsSets: "İlgilenilen Robotik Setler",
  labSetup: "Laboratuvar Kurulumu İhtiyacı",
  budget: "Yaklaşık Bütçe Aralığı",
  mealTypes: "İhtiyaç Duyulan Öğünler",
  staffCount: "Personel Sayısı",
  dietaryRestrictions: "Özel Diyet İhtiyaçları",
  kitchenAvailability: "Mutfak İmkanı",
  serviceType: "Servis Şekli",
  routeCount: "Tahmini Servis Güzergah Sayısı",
  vehicleTypes: "İhtiyaç Duyulan Araç Tipleri",
  trackingSystem: "Takip Sistemi İhtiyacı",
  hostessRequired: "Hostes İhtiyacı",
  serviceSchedule: "Servis Saatleri",
  productCategories: "İhtiyaç Duyulan Ürün Kategorileri",
  purchaseFrequency: "Satın Alma Sıklığı",
  customPrinting: "Özel Baskı İhtiyacı",
  estimatedBudget: "Tahmini Bütçe",
  ecoCertRequired: "Eko-Sertifikalı Ürün İhtiyacı",
  storageSpace: "Depolama Alanı",
  trainingAreas: "Eğitim Alanları",
  teacherCount: "Eğitim Alacak Öğretmen Sayısı",
  preferredDates: "Tercih Edilen Tarih Aralığı",
  trainingLocation: "Eğitim Lokasyonu",
  trainingDuration: "Eğitim Süresi Tercihi",
  currentStudentCount: "Mevcut Öğrenci Sayısı",
  targetStudentCount: "Hedef Öğrenci Sayısı",
  consultancyAreas: "Danışmanlık Alanları",
  marketingBudget: "Pazarlama Bütçesi",
  previousCampaigns: "Önceki Kayıt Kampanyaları",

  // Form seçenekleri için çeviriler
  options: {
    schoolType: {
      Anaokulu: "Anaokulu",
      İlkokul: "İlkokul",
      Ortaokul: "Ortaokul",
      Lise: "Lise",
      Diğer: "Diğer",
    },
    publicationSchedule: {
      "Acil (1 ay içinde)": "Acil (1 ay içinde)",
      "1-3 ay içinde": "1-3 ay içinde",
      "3-6 ay içinde": "3-6 ay içinde",
      "Gelecek eğitim yılı için": "Gelecek eğitim yılı için",
    },
    systemType: {
      "Öğrenci Takip": "Öğrenci Takip",
      "Yoklama Sistemi": "Yoklama Sistemi",
      "Not Sistemi": "Not Sistemi",
      "Veli İletişim": "Veli İletişim",
      "Envanter Yönetimi": "Envanter Yönetimi",
      Diğer: "Diğer",
    },
    parentAccess: {
      Evet: "Evet",
      Hayır: "Hayır",
    },
    mobileAccess: {
      Evet: "Evet",
      Hayır: "Hayır",
    },
    websiteType: {
      "Kurumsal Web Sitesi": "Kurumsal Web Sitesi",
      "E-Öğrenme Platformu": "E-Öğrenme Platformu",
      "Okul-Veli Portal": "Okul-Veli Portal",
      Diğer: "Diğer",
    },
    hasExistingWebsite: {
      Evet: "Evet",
      Hayır: "Hayır",
    },
    contentManagement: {
      "Kurum Personeli": "Kurum Personeli",
      "Dış Kaynak (Sizin tarafınızdan)": "Dış Kaynak (Sizin tarafınızdan)",
      "Karma Model": "Karma Model",
    },
    institutionType: {
      "Özel Okul": "Özel Okul",
      "Özel Kurs": "Özel Kurs",
      "Etüt Merkezi": "Etüt Merkezi",
      "Rehabilitasyon Merkezi": "Rehabilitasyon Merkezi",
      Diğer: "Diğer",
      Anaokulu: "Anaokulu",
      İlkokul: "İlkokul",
      Ortaokul: "Ortaokul",
      Lise: "Lise",
      "Karma Okul": "Karma Okul",
    },
    licenseStatus: {
      "Yeni Başvuru": "Yeni Başvuru",
      Yenileme: "Yenileme",
      "Tadilat/Değişiklik": "Tadilat/Değişiklik",
      "Denetim Hazırlığı": "Denetim Hazırlığı",
    },
    staffTrainingNeeds: {
      "Evrak Yönetimi": "Evrak Yönetimi",
      "Resmi Yazışmalar": "Resmi Yazışmalar",
      "Mevzuat Bilgilendirme": "Mevzuat Bilgilendirme",
      "Denetim Hazırlık": "Denetim Hazırlık",
      Diğer: "Diğer",
    },
    urgency: {
      "Acil (1 hafta içinde)": "Acil (1 hafta içinde)",
      "1-2 hafta içinde": "1-2 hafta içinde",
      "1 ay içinde": "1 ay içinde",
      "Belirli bir tarihte": "Belirli bir tarihte",
    },
    ageGroups: {
      "5-8 yaş": "5-8 yaş",
      "9-12 yaş": "9-12 yaş",
      "13-15 yaş": "13-15 yaş",
      "16-18 yaş": "16-18 yaş",
    },
    programmingTypes: {
      "Blok Kodlama": "Blok Kodlama",
      "Web Geliştirme": "Web Geliştirme",
      "Mobil Uygulama": "Mobil Uygulama",
      "Oyun Geliştirme": "Oyun Geliştirme",
      "Robotik Kodlama": "Robotik Kodlama",
      Diğer: "Diğer",
    },
    teacherTraining: {
      Evet: "Evet",
      Hayır: "Hayır",
    },
    roboticsSets: {
      "Lego Education": "Lego Education",
      Arduino: "Arduino",
      "Makey Makey": "Makey Makey",
      mBot: "mBot",
      "Raspberry Pi": "Raspberry Pi",
      "VEX Robotics": "VEX Robotics",
      Diğer: "Diğer",
    },
    labSetup: {
      Evet: "Evet",
      Hayır: "Hayır",
      "Mevcut Laboratuvar Geliştirme": "Mevcut Laboratuvar Geliştirme",
    },
    budget: {
      "10.000 TL altı": "10.000 TL altı",
      "10.000 - 25.000 TL": "10.000 - 25.000 TL",
      "25.000 - 50.000 TL": "25.000 - 50.000 TL",
      "50.000 TL üzeri": "50.000 TL üzeri",
      "5.000 TL altı": "5.000 TL altı",
      "5.000 - 15.000 TL": "5.000 - 15.000 TL",
      "15.000 - 50.000 TL": "15.000 - 50.000 TL",
      "50.000 - 100.000 TL": "50.000 - 100.000 TL",
      "100.000 TL üzeri": "100.000 TL üzeri",
    },
    mealTypes: {
      "Sabah Kahvaltısı": "Sabah Kahvaltısı",
      "Öğle Yemeği": "Öğle Yemeği",
      "İkindi Kahvaltısı": "İkindi Kahvaltısı",
      "Akşam Yemeği": "Akşam Yemeği",
    },
    kitchenAvailability: {
      "Var (Tam donanımlı)": "Var (Tam donanımlı)",
      "Var (Kısmi donanımlı)": "Var (Kısmi donanımlı)",
      Yok: "Yok",
    },
    serviceType: {
      "Yerinde Pişirme": "Yerinde Pişirme",
      "Taşıma Yemek": "Taşıma Yemek",
      "Karma Model": "Karma Model",
    },
    vehicleTypes: {
      "Minibüs (14+1)": "Minibüs (14+1)",
      "Midibüs (25+1)": "Midibüs (25+1)",
      "Otobüs (40+1 üzeri)": "Otobüs (40+1 üzeri)",
    },
    trackingSystem: {
      Evet: "Evet",
      Hayır: "Hayır",
    },
    hostessRequired: {
      Evet: "Evet",
      Hayır: "Hayır",
    },
    productCategories: {
      "Okul Defterleri": "Okul Defterleri",
      Kitaplar: "Kitaplar",
      "Resim Malzemeleri": "Resim Malzemeleri",
      "El İşi Malzemeleri": "El İşi Malzemeleri",
      "Ofis Kırtasiye": "Ofis Kırtasiye",
      Diğer: "Diğer",
      "Temizlik Malzemeleri": "Temizlik Malzemeleri",
      "Hijyen Ürünleri": "Hijyen Ürünleri",
      "Gıda Ürünleri": "Gıda Ürünleri",
      "Kantin Ürünleri": "Kantin Ürünleri",
    },
    purchaseFrequency: {
      "Yıllık Tek Seferde": "Yıllık Tek Seferde",
      Dönemlik: "Dönemlik",
      Aylık: "Aylık",
      "İhtiyaç Oldukça": "İhtiyaç Oldukça",
      Haftalık: "Haftalık",
    },
    customPrinting: {
      Evet: "Evet",
      Hayır: "Hayır",
    },
    ecoCertRequired: {
      Evet: "Evet",
      Hayır: "Hayır",
    },
    storageSpace: {
      Küçük: "Küçük",
      Orta: "Orta",
      Büyük: "Büyük",
      "Çok Büyük": "Çok Büyük",
    },
    trainingAreas: {
      "Yeni Eğitim Metodolojileri": "Yeni Eğitim Metodolojileri",
      "Teknoloji Entegrasyonu": "Teknoloji Entegrasyonu",
      "Sınıf Yönetimi": "Sınıf Yönetimi",
      "Ölçme ve Değerlendirme": "Ölçme ve Değerlendirme",
      Diğer: "Diğer",
    },
    trainingLocation: {
      Okulumuzda: "Okulumuzda",
      "Sizin Belirlediğiniz Yerde": "Sizin Belirlediğiniz Yerde",
      Online: "Online",
    },
    trainingDuration: {
      "Yarım Gün": "Yarım Gün",
      "Tam Gün": "Tam Gün",
      "Hafta Sonu": "Hafta Sonu",
      "Hafta İçi Akşam": "Hafta İçi Akşam",
      "Uzun Dönemli Program": "Uzun Dönemli Program",
    },
    consultancyAreas: {
      "Veli İletişim Stratejileri": "Veli İletişim Stratejileri",
      "Sosyal Medya Yönetimi": "Sosyal Medya Yönetimi",
      "Etkinlik Organizasyonu": "Etkinlik Organizasyonu",
      "Kayıt Dönemi Planlaması": "Kayıt Dönemi Planlaması",
      "Marka Değeri Artırma": "Marka Değeri Artırma",
      Diğer: "Diğer",
    },
    subjectAreas: {
      Türkçe: "Türkçe",
      Matematik: "Matematik",
      "Fen Bilimleri": "Fen Bilimleri",
      "Sosyal Bilgiler": "Sosyal Bilgiler",
      İngilizce: "İngilizce",
      Diğer: "Diğer",
    },
  },
};
