// Renk paleti

export const lightPalette = {
  primary: {
    main: "#060606f1",
    light: "#a2b6c7",
    dark: "#15b2c0",
    contrastText: "#fff",
  },
  secondary: {
    main: "#15b2c0",
    light: "#525151",
    dark: "#10919d",
    contrastText: "#fff",
  },
  error: {
    main: "#d32f2f",
    light: "#ef5350",
    dark: "#c62828",
    contrastText: "#fff",
  },
  warning: {
    main: "#ffa726",
    light: "#ffb74d",
    dark: "#f57c00",
    contrastText: "#fff",
  },
  info: {
    main: "#29b6f6",
    light: "#4fc3f7",
    dark: "#0288d1",
    contrastText: "#fff",
  },
  success: {
    main: "#66bb6a",
    light: "#81c784",
    dark: "#388e3c",
    contrastText: "#fff",
  },
  background: {
    default: "#f5f5f5",
    paper: "#ffffff",
  },
  text: {
    primary: "#212121",
    secondary: "#757575",
    disabled: "#bdbdbd",
  },
};

export const darkPalette = {
  primary: {
    main: "#90caf9",
    light: "#e3f2fd",
    dark: "#42a5f5",
    contrastText: "#000",
  },
  secondary: {
    main: "#ff9e80",
    light: "#ffccbc",
    dark: "#ff6e40",
    contrastText: "#000",
  },
  background: {
    default: "#121212",
    paper: "#1e1e1e",
  },
  text: {
    primary: "#ffffff",
    secondary: "#b0b0b0",
    disabled: "#6b6b6b",
  },
};
