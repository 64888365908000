import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  useTheme,
  Breadcrumbs,
  Link as MuiLink,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  useMediaQuery,
  IconButton,
  Drawer,
  alpha,
} from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

// Import icons
import BookIcon from "@mui/icons-material/Book";
import ComputerIcon from "@mui/icons-material/Computer";
import CodeIcon from "@mui/icons-material/Code";
import SchoolIcon from "@mui/icons-material/School";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import HomeIcon from "@mui/icons-material/Home";
import WebIcon from "@mui/icons-material/Web";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import CategoryIcon from "@mui/icons-material/Category";
import MenuIcon from "@mui/icons-material/Menu";
import FilterListIcon from "@mui/icons-material/FilterList";
import AppsIcon from "@mui/icons-material/Apps";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";

// Banner image - use slider image
import okulYayinlariBanner from "../assets/images/icons/okulYayinlariBanner.jpg";

const Services = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeCategory, setActiveCategory] = useState("all");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    // Simulate loading
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  // Toggle mobile sidebar
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Full services data with descriptions and paths - same length descriptions
  const servicesData = [
    {
      id: 1,
      title: "services.schoolPublications",
      icon: "BookIcon",
      path: "/hizmetler/okul-yayinlari",
      category: "eğitim",
      defaultDescription:
        "Okulunuza özel yayınlar ile eğitim kalitesini artırın. Yayınlarımız tüm yaş gruplarına uygun olarak hazırlanmakta ve MEB müfredatına göre düzenlenmektedir. Alanında uzman yazar kadromuz ile kurumunuza özgü içerikler oluşturmaktayız.",
    },
    {
      id: 2,
      title: "services.schoolAutomation",
      icon: "ComputerIcon",
      path: "/hizmetler/otomasyon-sistemleri",
      category: "teknoloji",
      defaultDescription:
        "Modern otomasyon sistemleri ile okulunuzun verimliliğini artırın. Öğrenci takip, yoklama, not sistemi ve daha fazlasını tek bir platformda yönetin. Veliler ve öğretmenler için özel raporlamalar ve anlık bildirimler ile iletişimi güçlendirin.",
    },
    {
      id: 3,
      title: "services.schoolWebSoftware",
      icon: "WebIcon",
      path: "/hizmetler/web-yazilim",
      category: "teknoloji",
      defaultDescription:
        "Kurumunuzun dijital yüzü olan web sitesini profesyonel ekibimizle tasarlayın. SEO uyumlu, mobil uyumlu ve yönetimi kolay web çözümleri sunuyoruz. Öğrenci ve veli portalleri, online başvuru sistemleri ve içerik yönetimi ile tam entegre çözümler.",
    },
    {
      id: 4,
      title: "services.officialControl",
      icon: "AdminPanelSettingsIcon",
      path: "/hizmetler/resmiyet-kontrol",
      category: "danışmanlık",
      defaultDescription:
        "Eğitim kurumunuzun resmi süreçlerinin doğru yönetimi için danışmanlık ve personel eğitimi hizmetleri sunuyoruz. MEB ve ilgili bakanlıklar nezdinde tüm evrakların kontrolü, denetim hazırlıkları ve personel eğitimleri ile kurumunuzu destekliyoruz.",
    },
    {
      id: 5,
      title: "services.codingEducation",
      icon: "CodeIcon",
      path: "/hizmetler/kodlama-egitimi",
      category: "eğitim",
      defaultDescription:
        "Geleceğin teknolojilerini öğrencilerinize bugünden kazandırın. Yaş gruplarına özel müfredatlar ve eğitim malzemeleri ile kodlama eğitimi çözümleri. Blok kodlamadan ileri düzey programlamaya kadar tüm seviyelerde eğitim programları ve atölye çalışmaları.",
    },
    {
      id: 6,
      title: "services.robotics",
      icon: "PrecisionManufacturingIcon",
      path: "/hizmetler/robotik",
      category: "teknoloji",
      defaultDescription:
        "En son teknoloji robotik malzemeler ile STEM eğitiminizi güçlendirin. Laboratuvar kurulumundan, eğitmen desteğine kadar tüm çözümler. Lego Education, Arduino, Makey Makey gibi popüler eğitim setleri ve yenilikçi robotik materyaller ile STEM eğitimlerini kurumuza kazandırın.",
    },
    {
      id: 7,
      title: "services.foodServices",
      icon: "FastfoodIcon",
      path: "/hizmetler/yemek-hizmetleri",
      category: "destek",
      defaultDescription:
        "Öğrencilerinize sağlıklı, dengeli ve lezzetli yemek hizmetleri. Diyetisyen kontrolünde hazırlanan menüler ve profesyonel hizmet anlayışı. Okul çağı çocuklarının gelişim ihtiyaçlarına uygun, hijyenik koşullarda hazırlanan besleyici öğünler ile sağlıklı nesiller yetiştirin.",
    },
    {
      id: 8,
      title: "services.transportServices",
      icon: "AirportShuttleIcon",
      path: "/hizmetler/servis-hizmetleri",
      category: "destek",
      defaultDescription:
        "Güvenli ve konforlu okul servis çözümleri. Deneyimli sürücüler, modern araçlar ve anlık takip sistemleri ile öğrencileriniz güvende. Velilerinize özel mobil uygulama üzerinden servis konum bilgisi, gecikmeler ve olağandışı durumlar hakkında anlık bildirimler.",
    },
    {
      id: 9,
      title: "services.stationery",
      icon: "BorderColorIcon",
      path: "/hizmetler/kirtasiye",
      category: "tedarik",
      defaultDescription:
        "Okulunuzun kırtasiye ihtiyaçlarını en uygun fiyatlarla karşılıyoruz. Toplu alımlarda özel indirimler ve stok garantisi. Okul defterleri, kitaplar, resim ve el işi malzemeleri, ofis kırtasiye ürünleri dahil tüm kırtasiye ihtiyaçlarınızı tek elden ve profesyonel hizmet anlayışı ile sağlıyoruz.",
    },
    {
      id: 10,
      title: "services.cleaningFood",
      icon: "CleaningServicesIcon",
      path: "/hizmetler/temizlik-gida",
      category: "tedarik",
      defaultDescription:
        "Eğitim kurumları için kaliteli temizlik ve gıda ürünleri tedariği. Hijyen standartlarına uygun, sağlıklı çözümler. Okul kantinleri, yemekhaneler ve ortak kullanım alanları için profesyonel, sertifikalı temizlik ve gıda ürünleri ile öğrencilerinizin sağlığını koruyun.",
    },
    {
      id: 11,
      title: "services.teacherTrainings",
      icon: "SchoolIcon",
      path: "/hizmetler/ogretmen-egitimleri",
      category: "eğitim",
      defaultDescription:
        "Profesyonel eğitimlerle öğretmenlerinizin gelişimini destekleyin. Alanında uzman eğitmenler eşliğinde, kurumunuza özel seminerler. Yeni eğitim metodolojileri, teknoloji entegrasyonu, sınıf yönetimi ve bireyselleştirilmiş eğitim gibi konularda öğretmenlerinizi güçlendirin.",
    },
    {
      id: 12,
      title: "services.registrationConsultancy",
      icon: "AssignmentIndIcon",
      path: "/hizmetler/kayit-danismanligi",
      category: "danışmanlık",
      defaultDescription:
        "Öğrenci kayıt süreçlerinizi profesyonel danışmanlık ile optimize edin. Doluluk oranınızı artıracak stratejik çözümler. Veli iletişim stratejileri, sosyal medya yönetimi, etkinlik organizasyonu ve kayıt dönemi planlaması ile okulunuzun doluluk oranını ve marka değerini artırın.",
    },
  ];

  // Categories for sidebar
  const categories = [
    {
      label: t("services.allCategories"),
      value: "all",
      icon: <AppsIcon />,
    },
    {
      label: t("services.education"),
      value: "eğitim",
      icon: <SchoolOutlinedIcon />,
    },
    {
      label: t("services.technology"),
      value: "teknoloji",
      icon: <DevicesOutlinedIcon />,
    },
    {
      label: t("services.consultancy"),
      value: "danışmanlık",
      icon: <BusinessCenterOutlinedIcon />,
    },
    {
      label: t("services.supply"),
      value: "tedarik",
      icon: <InventoryOutlinedIcon />,
    },
    {
      label: t("services.support"),
      value: "destek",
      icon: <SupportAgentOutlinedIcon />,
    },
  ];

  // Filter services by active category
  const filteredServices = servicesData.filter(
    (service) => activeCategory === "all" || service.category === activeCategory
  );

  // Render icon based on icon name
  const renderIcon = (iconName) => {
    switch (iconName) {
      case "BookIcon":
        return <BookIcon fontSize="large" />;
      case "ComputerIcon":
        return <ComputerIcon fontSize="large" />;
      case "CodeIcon":
        return <CodeIcon fontSize="large" />;
      case "SchoolIcon":
        return <SchoolIcon fontSize="large" />;
      case "PrecisionManufacturingIcon":
        return <PrecisionManufacturingIcon fontSize="large" />;
      case "AssignmentIndIcon":
        return <AssignmentIndIcon fontSize="large" />;
      case "WebIcon":
        return <WebIcon fontSize="large" />;
      case "AdminPanelSettingsIcon":
        return <AdminPanelSettingsIcon fontSize="large" />;
      case "FastfoodIcon":
        return <FastfoodIcon fontSize="large" />;
      case "AirportShuttleIcon":
        return <AirportShuttleIcon fontSize="large" />;
      case "BorderColorIcon":
        return <BorderColorIcon fontSize="large" />;
      case "CleaningServicesIcon":
        return <CleaningServicesIcon fontSize="large" />;
      default:
        return null;
    }
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
      },
    },
  };

  // Get category count
  const getCategoryCount = (categoryValue) => {
    if (categoryValue === "all") return servicesData.length;
    return servicesData.filter((service) => service.category === categoryValue)
      .length;
  };

  // Sidebar content component with colorful icons
  const SidebarContent = () => {
    // Function to get icon color based on category
    const getCategoryColor = (categoryValue) => {
      switch (categoryValue) {
        case "all":
          return theme.palette.primary.main;
        case "eğitim":
          return theme.palette.success.main;
        case "teknoloji":
          return theme.palette.info.main;
        case "danışmanlık":
          return theme.palette.warning.main;
        case "tedarik":
          return theme.palette.secondary.main;
        case "destek":
          return theme.palette.error.main;
        default:
          return theme.palette.primary.main;
      }
    };

    return (
      <Box sx={{ px: 2, py: 3 }}>
        <Typography
          variant="h5"
          sx={{
            px: 2,
            mb: 3,
            fontWeight: 600,
            borderLeft: `4px solid ${theme.palette.secondary.main}`,
            paddingLeft: 2,
          }}
        >
          {t("common.categories")}
        </Typography>
        <List component="nav" sx={{ width: "100%" }}>
          {categories.map((category) => (
            <ListItem
              key={category.value}
              disablePadding
              onClick={() => {
                setActiveCategory(category.value);
                if (isMobile) setSidebarOpen(false);
              }}
              sx={{
                mb: 1,
                borderRadius: 2,
                overflow: "hidden",
                backgroundColor:
                  activeCategory === category.value
                    ? alpha(getCategoryColor(category.value), 0.1)
                    : "transparent",
                "&:hover": {
                  backgroundColor: alpha(
                    getCategoryColor(category.value),
                    0.05
                  ),
                },
              }}
            >
              <Button
                fullWidth
                startIcon={
                  <Box
                    component="span"
                    sx={{
                      color: getCategoryColor(category.value),
                      transform:
                        activeCategory === category.value
                          ? "scale(1.2)"
                          : "scale(1)",
                      transition: "transform 0.3s ease",
                    }}
                  >
                    {React.cloneElement(category.icon, {
                      sx: { fontSize: "1.4rem" },
                    })}
                  </Box>
                }
                endIcon={
                  <Box
                    component="span"
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 26,
                      height: 26,
                      borderRadius: "50%",
                      bgcolor:
                        activeCategory === category.value
                          ? getCategoryColor(category.value)
                          : alpha(getCategoryColor(category.value), 0.1),
                      color:
                        activeCategory === category.value ? "white" : "inherit",
                      fontSize: "0.75rem",
                      fontWeight: "bold",
                    }}
                  >
                    {getCategoryCount(category.value)}
                  </Box>
                }
                sx={{
                  justifyContent: "space-between",
                  py: 1.5,
                  px: 2,
                  color:
                    activeCategory === category.value
                      ? getCategoryColor(category.value)
                      : "text.primary",
                  fontWeight: activeCategory === category.value ? 600 : 400,
                  textTransform: "none",
                  fontSize: "1rem",
                  textAlign: "left",
                }}
              >
                {category.label}
              </Button>
            </ListItem>
          ))}
        </List>

        {/* Enhanced Contact box in sidebar */}
        <Paper
          elevation={3}
          sx={{
            mt: 4,
            p: 3,
            borderRadius: 3,
            background: `linear-gradient(135deg, ${alpha(
              theme.palette.primary.main,
              0.8
            )}, ${alpha(theme.palette.secondary.main, 0.8)})`,
            position: "relative",
            overflow: "hidden",
            border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
            "&::before": {
              content: '""',
              position: "absolute",
              top: "-50%",
              left: "-50%",
              width: "200%",
              height: "200%",
              background: `radial-gradient(circle, ${alpha(
                "#fff",
                0.1
              )} 0%, transparent 60%)`,
              transform: "rotate(30deg)",
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{ mb: 1, fontWeight: 600, color: "white" }}
          >
            {t("services.contact")}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mb: 2, color: "white", opacity: 0.9 }}
          >
            {t("services.contactDescription")}
          </Typography>
          <Button
            component={Link}
            to="/iletisim"
            variant="contained"
            color="secondary"
            fullWidth
            sx={{
              py: 1.2,
              borderRadius: 2,
              textTransform: "none",
              fontWeight: 600,
              boxShadow: "0 4px 10px rgba(0,0,0,0.15)",
              transition: "all 0.3s ease",
              "&:hover": {
                transform: "translateY(-3px)",
                boxShadow: "0 8px 15px rgba(0,0,0,0.2)",
              },
            }}
          >
            {t("common.contactUs")}
          </Button>
        </Paper>
      </Box>
    );
  };

  // Modern Service Card Component - Updated with teklif-form link
  const ServiceCard = ({ title, icon, path, index, defaultDescription }) => {
    // Generate a color for the service card based on its index
    const getServiceColor = () => {
      const colors = [
        theme.palette.primary,
        theme.palette.secondary,
        theme.palette.info,
        theme.palette.success,
        theme.palette.warning,
        theme.palette.error,
      ];
      return colors[index % colors.length];
    };

    const serviceColor = getServiceColor();

    // Hizmet ID'sini path'den çıkaralım
    // Örneğin: "/hizmetler/okul-yayinlari" -> "okul-yayinlari"
    const serviceId = path.split("/").pop();

    // Başlık ve açıklama için çeviri kullanma
    const displayTitle = t(title);

    // Açıklama için farklı çeviri kaynaklarında arıyoruz
    const serviceType = title.split(".").pop();

    // Örnek: "services.schoolPublications" -> "services.schoolPublicationsDesc" veya "home.schoolPublicationsDesc"
    let description = "";

    // Önce services.[serviceType]Desc anahtarını kontrol et
    const serviceDescKey = `${title}Desc`;
    // Sonra home.[serviceType]Desc anahtarını kontrol et
    const homeDescKey = `home.${serviceType}Desc`;

    const serviceTranslation = t(serviceDescKey);
    if (serviceTranslation !== serviceDescKey) {
      description = serviceTranslation;
    } else {
      const homeTranslation = t(homeDescKey);
      if (homeTranslation !== homeDescKey) {
        description = homeTranslation;
      } else {
        // Hiçbir çeviri bulunamazsa varsayılan açıklama
        description = defaultDescription || "";
      }
    }

    return (
      <Card
        sx={{
          height: { xs: 415, sm: 475, md: 475, lg: 475 }, // Responsive heights
          display: "flex",
          flexDirection: "column",
          borderRadius: 3,
          border: "1px solid rgba(0,0,0,0.05)",
          overflow: "hidden",
          position: "relative",
          transition: "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
          boxShadow: "0 10px 20px rgba(0,0,0,0.05)",
          "&:hover": {
            transform: "translateY(-15px)",
            boxShadow: `0 20px 40px ${serviceColor.main}40`,
            "& .service-icon": {
              transform: "scale(1.1) rotate(10deg)",
              color: "white",
              backgroundColor: serviceColor.main,
            },
            "& .service-title": {
              color: serviceColor.main,
            },
            "& .service-btn": {
              backgroundColor: serviceColor.main,
              color: "white",
              borderColor: serviceColor.main,
            },
            "& .service-overlay": {
              opacity: 0.05,
            },
          },
        }}
      >
        {/* Background overlay */}
        <Box
          className="service-overlay"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0,
            background: `linear-gradient(135deg, ${serviceColor.light}, ${serviceColor.main})`,
            transition: "opacity 0.4s ease",
            zIndex: 0,
          }}
        />

        <CardContent
          sx={{
            p: { xs: 2, md: 3 },
            position: "relative",
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            height: "100%", // Important for flexbox spacing
          }}
        >
          {/* Content wrapper - top part */}
          <Box sx={{ mb: 2 }}>
            {/* Icon */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2, // Reduced margin
              }}
            >
              <Box
                className="service-icon"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 70, // Slightly smaller
                  height: 70, // Slightly smaller
                  borderRadius: "20px",
                  backgroundColor: "rgba(0,0,0,0.03)",
                  color: serviceColor.main,
                  transition:
                    "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                  boxShadow: `0 10px 20px ${serviceColor.main}20`,
                  "&:after": {
                    content: '""',
                    position: "absolute",
                    width: "50%",
                    height: "20%",
                    background: `linear-gradient(to left, ${serviceColor.main}10, ${serviceColor.light}80)`,
                    borderRadius: "50%",
                    bottom: -10,
                    filter: "blur(12px)",
                    opacity: 0.6,
                    transition: "opacity 0.4s ease",
                  },
                }}
              >
                {renderIcon(icon)}
              </Box>
            </Box>

            {/* Title */}
            <Typography
              variant="h6"
              component="h3"
              align="center"
              className="service-title"
              sx={{
                fontWeight: 700,
                mb: 1.5, // Reduced margin
                transition: "color 0.3s ease",
                fontSize: { xs: "1.1rem", sm: "1.25rem" }, // Responsive font size
              }}
            >
              {displayTitle}
            </Typography>
          </Box>

          {/* Description - with fixed height and scrollable */}
          <Box
            sx={{
              flexGrow: 1,
              overflow: "auto",
              mb: 2, // Fixed margin to button
             
              // Custom scrollbar styling
              "&::-webkit-scrollbar": {
                width: "6px",
                backgroundColor: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: alpha(serviceColor.main, 0.2),
                borderRadius: "3px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: alpha(serviceColor.main, 0.4),
              },
            }}
          >
            <Typography
              variant="body2"
              align="center"
              sx={{
                color: "text.secondary",
                lineHeight: 1.7,
                px: 1,
                fontSize: { xs: "0.815rem", sm: "0.875rem" }, // Responsive font size
              }}
            >
              {description}
            </Typography>
          </Box>

          {/* Button wrapper - fixed at bottom */}
          <Box sx={{ mt: "auto" }}>
            <Button
              component={Link}
              to={`/teklif-formu?hizmet=${serviceId}`}
              variant="outlined"
              className="service-btn"
              startIcon={<RequestQuoteIcon />}
              sx={{
                borderRadius: "10px",
                py: 1,
                fontWeight: 600,
                borderWidth: 2,
                borderColor: serviceColor.main,
                color: serviceColor.main,
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: `0 5px 15px ${serviceColor.main}40`,
                },
                width: "100%", // Full width button
                fontSize: { xs: "0.8rem", sm: "0.875rem" }, // Responsive font
              }}
            >
              {t("home.getQuote")}
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          position: "relative",
          height: { xs: "40vh", md: "60vh" },
          backgroundImage: `url(${okulYayinlariBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.6)",
          },
        }}
      >
      </Box>

      {/* Filter icon for mobile */}
      {isMobile && (
        <Box
          sx={{
            position: "sticky",
            top: 75,
            zIndex: 10,
            display: "flex",
            justifyContent: "flex-end",
            mt: -5,
            mr: 3,
          }}
        >
          <IconButton
            onClick={toggleSidebar}
            aria-label="Kategorileri filtrele"
            sx={{
              bgcolor: theme.palette.primary.main,
              color: "white",
              boxShadow: 3,
              "&:hover": {
                bgcolor: theme.palette.primary.dark,
              },
            }}
          >
            <FilterListIcon />
          </IconButton>
        </Box>
      )}

      {/* Main Content with Sidebar */}
      <Container maxWidth="xl" sx={{ py: 5 }}>
        <Box sx={{ display: "flex" }}>
          {/* Sidebar - Desktop */}
          {!isMobile && (
            <Box
              sx={{
                width: 280,
                flexShrink: 0,
                pr: 4,
                position: "sticky",
                top: 100,
                alignSelf: "flex-start",
                height: "fit-content",
              }}
            >
              <SidebarContent />
            </Box>
          )}

          {/* Sidebar - Mobile */}
          {isMobile && (
            <Drawer
              anchor="left"
              open={sidebarOpen}
              onClose={toggleSidebar}
              sx={{
                zIndex: 1200, // Header'dan yüksek z-index değeri
                "& .MuiBackdrop-root": {
                  zIndex: 1100, // Backdrop için de yüksek z-index
                },
                "& .MuiDrawer-paper": {
                  zIndex: 1200, // Drawer kağıdı için yüksek z-index
                },
              }}
              PaperProps={{
                sx: {
                  width: 280,
                  zIndex: 1200, // Drawer paneli için yüksek z-index
                },
              }}
              ModalProps={{
                sx: {
                  zIndex: 1200, // Modal arkası için yüksek z-index
                },
              }}
            >
              <SidebarContent />
            </Drawer>
          )}

          {/* Main content */}
          <Box sx={{ flexGrow: 1 }}>
            {/* Title and info of current view */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="h4" sx={{ fontWeight: 600, mb: 1 }}>
                {activeCategory === "all"
                  ? t("common.allServices")
                  : t(
                      `services.${
                        activeCategory === "eğitim"
                          ? "education"
                          : activeCategory === "teknoloji"
                          ? "technology"
                          : activeCategory === "danışmanlık"
                          ? "consultancy"
                          : activeCategory === "tedarik"
                          ? "supply"
                          : activeCategory === "destek"
                          ? "support"
                          : activeCategory
                      }`
                    )}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {activeCategory === "all"
                  ? t("services.servicesDescription")
                  : `${t(
                      `services.${
                        activeCategory === "eğitim"
                          ? "education"
                          : activeCategory === "teknoloji"
                          ? "technology"
                          : activeCategory === "danışmanlık"
                          ? "consultancy"
                          : activeCategory === "tedarik"
                          ? "supply"
                          : activeCategory === "destek"
                          ? "support"
                          : activeCategory
                      }`
                    )} ${t("services.servicesDescription")}`}
              </Typography>
            </Box>

            {/* Services Grid */}
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate={isLoaded ? "visible" : "hidden"}
            >
              <Grid container spacing={4}>
                {filteredServices.map((service, index) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={service.id}>
                    <motion.div variants={itemVariants}>
                      <ServiceCard
                        title={service.title}
                        icon={service.icon}
                        path={service.path}
                        index={index}
                        defaultDescription={service.defaultDescription}
                      />
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </motion.div>

            {/* Empty state when no services match the filter */}
            {filteredServices.length === 0 && (
              <Box
                sx={{
                  textAlign: "center",
                  py: 8,
                  px: 4,
                  bgcolor: alpha(theme.palette.primary.main, 0.05),
                  borderRadius: 3,
                }}
              >
                <CategoryIcon
                  sx={{
                    fontSize: 60,
                    color: alpha(theme.palette.primary.main, 0.3),
                    mb: 2,
                  }}
                />
                <Typography variant="h5" sx={{ mb: 1, fontWeight: 500 }}>
                  {t("services.noServicesInCategory")}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ mb: 3 }}
                >
                  {t("services.selectAnotherCategory")}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setActiveCategory("all")}
                >
                  {t("services.showAllServices")}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Services;