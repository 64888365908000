import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
  LinearProgress,
  alpha,
} from "@mui/material";
import { motion, AnimatePresence, useAnimation } from "framer-motion";

// Arka plansız logo PNG
import logoImage from "../../assets/images/icons/arkaplansızLogo.png";

const LoadingScreen = ({ onLoadingComplete }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const [progress, setProgress] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);
  const logoAnimControls = useAnimation();

  // Logo animasyonları - daha profesyonel hale getirildi
  useEffect(() => {
    const sequence = async () => {
      await logoAnimControls.start({
        scale: [0.95, 1.03, 1],
        rotate: [-0.5, 0.5, 0],
        transition: { duration: 1.5, ease: "easeInOut" },
      });

      // Sürekli hafif nefes alma animasyonu
      logoAnimControls.start({
        scale: [1, 1.02, 1, 1.01, 1],
        rotate: [0, 0.3, 0, -0.3, 0],
        transition: {
          repeat: Infinity,
          duration: 4,
          ease: "easeInOut",
        },
      });
    };

    sequence();
  }, [logoAnimControls]);

  // Yükleme çubuğu animasyonu - tam olarak 4 saniye sürecek şekilde ayarlandı
  useEffect(() => {
    const totalDuration = 4000; // 4 saniye
    const interval = 20; // Her 20ms'de bir güncelleme

    const startTime = Date.now();

    const timer = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const calculatedProgress = Math.min(
        (elapsedTime / totalDuration) * 100,
        100
      );

      setProgress(calculatedProgress);

      if (calculatedProgress >= 100) {
        clearInterval(timer);
        setTimeout(() => {
          setIsComplete(true);
          if (onLoadingComplete) onLoadingComplete();
        }, 200); // Tamamlandıktan sonra daha kısa bir bekleme
      }
    }, interval);

    return () => clearInterval(timer);
  }, [onLoadingComplete]);

  // Fare takibi - daha optimize edilmiş
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        setMousePosition({
          x: e.clientX - rect.left,
          y: e.clientY - rect.top,
        });
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  // Dinamik boyutlar - daha profesyonel ölçülere ayarlandı
  const logoSize = isMobile ? 180 : isTablet ? 220 : isLargeScreen ? 280 : 240;
  const sloganFontSize = isMobile ? 22 : isTablet ? 28 : 34;

  // Canlı ve uyumlu renk şeması - daha kurumsal ve profesyonel renkler
  const primaryColor = "#00BFA6"; // ÖLÇÜ turkuaz rengi - korundu
  const secondaryColor = "#2C3E50"; // Koyu lacivert - daha kurumsal
  const accentColor1 = "#3498DB"; // Mavi - daha kurumsal
  const accentColor2 = "#1ABC9C"; // Yeşil-turkuaz - kurumsal
  const accentColor3 = "#34495E"; // Koyu gri-mavi - kurumsal
  const accentColor4 = "#16A085"; // Koyu turkuaz - kurumsal
  const gradientLight = "#F5F9FC"; // Daha açık mavi background
  const gradientDark = "#E5EEF6"; // Daha açık mavi background bitiş

  // Renkli yükleme çubuğu için renkler
  const progressBarColors = [
    primaryColor,
    accentColor2,
    accentColor1,
    accentColor4,
  ];

  // Parçacık ayarları - sayısı ve boyutu azaltıldı, daha subtil
  const particleCount = isMobile ? 25 : isTablet ? 35 : 45;
  const particleColors = [
    primaryColor,
    secondaryColor,
    accentColor1,
    accentColor2,
  ];

  // Parçacık oluşturma - daha zarif, daha az dikkat dağıtıcı
  const particles = Array.from({ length: particleCount }).map((_, index) => {
    const size = Math.random() * (isMobile ? 3 : 4) + 1;
    const initialX = Math.random() * 100; // % cinsinden
    const initialY = Math.random() * 100; // % cinsinden
    const speed = Math.random() * 12 + 20; // Daha yavaş
    const color =
      particleColors[Math.floor(Math.random() * particleColors.length)];
    const delay = Math.random() * 2;
    const isCircle = Math.random() > 0.2; // Daha çok daire
    const opacity = Math.random() * 0.2 + 0.05; // Daha az görünür

    return {
      id: index,
      size,
      initialX,
      initialY,
      speed,
      color,
      delay,
      isCircle,
      opacity,
    };
  });

  // Uçuşan simgeler - daha az ve daha profesyonel
  const svgIcons = [
    {
      path: "M4 18h16v2H4zm0-5h16v2H4zm0-5h16v2H4zM2 4v2h20V4z", // Kitap
      viewBox: "0 0 24 24",
      color: accentColor1,
    },
    {
      path: "M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6zm4 18H6V4h7v5h5v11z", // Dosya
      viewBox: "0 0 24 24",
      color: accentColor2,
    },
    {
      path: "M12 3L1 9l4 2.18v6L12 21l7-3.82v-6l2-1.09V17h2V9L12 3zm6.82 6L12 12.72 5.18 9 12 5.28 18.82 9zM17 15.99l-5 2.73-5-2.73v-3.72L12 15l5-2.73v3.72z", // Okul
      viewBox: "0 0 24 24",
      color: primaryColor,
    },
    {
      path: "M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z", // Mezuniyet
      viewBox: "0 0 24 24",
      color: secondaryColor,
    },
  ];

  // Köşe ışık pozisyonları - daha subtil
  const cornerLights = [
    { top: 0, left: 0, color: accentColor1 }, // Sol üst
    { top: 0, right: 0, color: accentColor2 }, // Sağ üst
    { bottom: 0, left: 0, color: primaryColor }, // Sol alt
    { bottom: 0, right: 0, color: accentColor4 }, // Sağ alt
  ];

  // Geometrik şekiller - daha az ve daha subtil
  const geometricShapes = [
    // Sol taraf
    {
      type: "circle",
      left: "5%",
      top: "15%",
      size: isMobile ? 50 : 100,
      color: alpha(accentColor1, 0.06),
    },
    {
      type: "square",
      left: "8%",
      top: "40%",
      size: isMobile ? 35 : 70,
      color: alpha(accentColor2, 0.05),
      rotation: 45,
    },

    // Sağ taraf
    {
      type: "circle",
      right: "8%",
      top: "25%",
      size: isMobile ? 40 : 90,
      color: alpha(primaryColor, 0.05),
    },
    {
      type: "square",
      right: "15%",
      bottom: "20%",
      size: isMobile ? 40 : 80,
      color: alpha(accentColor1, 0.04),
      rotation: 30,
    },
  ];

  // Geometrik şekil render fonksiyonu
  const renderShape = (shape) => {
    const commonStyle = {
      position: "absolute",
      zIndex: 0,
      opacity: 0.6,
      ...shape,
    };

    switch (shape.type) {
      case "circle":
        return (
          <motion.div
            style={{
              ...commonStyle,
              width: shape.size,
              height: shape.size,
              borderRadius: "50%",
              background: shape.color,
            }}
            animate={{
              scale: [1, 1.05, 1],
              opacity: [0.4, 0.5, 0.4],
            }}
            transition={{
              duration: 5 + Math.random() * 3,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />
        );
      case "square":
        return (
          <motion.div
            style={{
              ...commonStyle,
              width: shape.size,
              height: shape.size,
              background: shape.color,
              transform: `rotate(${shape.rotation}deg)`,
            }}
            animate={{
              rotate: [shape.rotation, shape.rotation + 5, shape.rotation],
              opacity: [0.4, 0.5, 0.4],
            }}
            transition={{
              duration: 6 + Math.random() * 4,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />
        );
      default:
        return null;
    }
  };

  // Parçacık render fonksiyonu - daha yavaş ve daha zarif hareketler
  const renderParticle = (particle) => {
    // Fare konumuna bağlı hareket etme miktarı - daha az hassas
    const mouseFactor = 0.01; // Daha az hassas
    const containerWidth =
      containerRef.current?.clientWidth || window.innerWidth;
    const containerHeight =
      containerRef.current?.clientHeight || window.innerHeight;

    // Fare pozisyonuna göre ek hareket hesaplama
    const mouseXPercent = mousePosition.x / containerWidth;
    const mouseYPercent = mousePosition.y / containerHeight;

    // Parçacık fare etkisi hesaplama - daha subtil
    const moveX = (mouseXPercent - 0.5) * mouseFactor * -100; // % olarak
    const moveY = (mouseYPercent - 0.5) * mouseFactor * -100; // % olarak

    return (
      <motion.div
        key={particle.id}
        style={{
          position: "absolute",
          left: `${particle.initialX}%`,
          top: `${particle.initialY}%`,
          width: particle.size,
          height: particle.size,
          borderRadius: particle.isCircle ? "50%" : "2px",
          background: particle.color,
          opacity: particle.opacity,
          zIndex: 1,
          boxShadow:
            particle.size > 4
              ? `0 0 ${particle.size}px ${particle.color}`
              : "none",
        }}
        animate={{
          x: [moveX - 30, moveX + 30, moveX - 20, moveX + 20, moveX],
          y: [moveY - 30, moveY + 20, moveY - 30, moveY + 30, moveY],
          opacity: [
            particle.opacity,
            particle.opacity * 1.2,
            particle.opacity * 0.9,
            particle.opacity * 1.1,
            particle.opacity,
          ],
          scale: [1, 1.05, 0.95, 1.02, 1],
        }}
        transition={{
          duration: particle.speed,
          repeat: Infinity,
          delay: particle.delay,
          ease: "easeInOut",
        }}
      />
    );
  };

  return (
    <AnimatePresence>
      {!isComplete && (
        <motion.div
          ref={containerRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { duration: 0.4 } }}
          className="loading-screen"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: `linear-gradient(135deg, ${gradientLight} 0%, ${gradientDark} 100%)`,
            zIndex: 9999,
            overflow: "hidden",
          }}
        >
          {/* Daha subtil dalgalı arkaplan */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0.3,
              zIndex: 0,
              overflow: "hidden",
            }}
          >
            <svg
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <motion.path
                d="M0,50 C20,60 40,45 60,55 C80,65 90,50 100,55 L100,100 L0,100 Z"
                fill={alpha(accentColor1, 0.03)}
                animate={{
                  d: [
                    "M0,50 C20,60 40,45 60,55 C80,65 90,50 100,55 L100,100 L0,100 Z",
                    "M0,55 C20,45 40,55 60,45 C80,55 90,45 100,50 L100,100 L0,100 Z",
                    "M0,50 C20,60 40,45 60,55 C80,65 90,50 100,55 L100,100 L0,100 Z",
                  ],
                }}
                transition={{
                  repeat: Infinity,
                  duration: 10,
                  ease: "easeInOut",
                }}
              />
              <motion.path
                d="M0,65 C15,55 35,65 50,60 C65,55 80,65 100,60 L100,100 L0,100 Z"
                fill={alpha(primaryColor, 0.02)}
                animate={{
                  d: [
                    "M0,65 C15,55 35,65 50,60 C65,55 80,65 100,60 L100,100 L0,100 Z",
                    "M0,60 C15,70 35,60 50,65 C65,70 80,60 100,65 L100,100 L0,100 Z",
                    "M0,65 C15,55 35,65 50,60 C65,55 80,65 100,60 L100,100 L0,100 Z",
                  ],
                }}
                transition={{
                  repeat: Infinity,
                  duration: 12,
                  ease: "easeInOut",
                  delay: 0.5,
                }}
              />
            </svg>
          </Box>

          {/* Geometrik Şekiller */}
          {geometricShapes.map((shape, index) => (
            <React.Fragment key={`shape-${index}`}>
              {renderShape(shape)}
            </React.Fragment>
          ))}

          {/* Köşe Işık Efektleri */}
          {cornerLights.map((light, index) => (
            <motion.div
              key={`light-${index}`}
              style={{
                position: "absolute",
                width: isMobile ? 120 : 180,
                height: isMobile ? 120 : 180,
                borderRadius: "50%",
                background: `radial-gradient(circle, ${alpha(
                  light.color,
                  0.08
                )} 0%, ${alpha(light.color, 0)} 70%)`,
                ...light, // top, left, right, bottom pozisyonları
              }}
              animate={{
                scale: [1, 1.1, 1],
                opacity: [0.15, 0.25, 0.15],
              }}
              transition={{
                duration: 5 + index,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            />
          ))}

          {/* Uçuşan simgeler - daha az ve daha profesyonel */}
          {svgIcons.map((icon, index) => (
            <motion.div
              key={`icon-${index}`}
              style={{
                position: "absolute",
                zIndex: 1,
                opacity: 0,
                color: icon.color,
              }}
              initial={{
                x: Math.random() > 0.5 ? -100 : window.innerWidth + 100,
                y: Math.random() * window.innerHeight,
                rotate: Math.random() * 360,
                opacity: 0,
                scale: Math.random() * 0.3 + 0.3,
              }}
              animate={{
                x: Math.random() > 0.5 ? window.innerWidth + 100 : -100,
                y: Math.random() * window.innerHeight,
                rotate: Math.random() * 360,
                opacity: [0, 0.4, 0],
                scale: Math.random() * 0.3 + 0.3,
              }}
              transition={{
                duration: Math.random() * 15 + 15,
                delay: Math.random() * 4,
                repeat: Infinity,
                repeatType: "loop",
              }}
            >
              <svg
                width={isMobile ? 32 : 40}
                height={isMobile ? 32 : 40}
                viewBox={icon.viewBox}
                style={{
                  filter: `drop-shadow(0 2px 4px ${alpha(icon.color, 0.2)})`,
                }}
              >
                <path d={icon.path} fill={icon.color} />
              </svg>
            </motion.div>
          ))}

          {/* Parçacıklar - Daha subtil versiyon */}
          {particles.map((particle) => renderParticle(particle))}

          {/* Ana içerik kartı */}
          <Paper
            elevation={4}
            sx={{
              position: "relative",
              width: isMobile ? "85%" : isTablet ? "75%" : "65%",
              maxWidth: "800px",
              background: "rgba(255, 255, 255, 0.9)",
              backdropFilter: "blur(8px)",
              borderRadius: 3,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: isMobile ? 3 : 5,
              zIndex: 2,
              border: `1px solid ${alpha("#fff", 0.2)}`,
              boxShadow: `0 15px 50px ${alpha(
                primaryColor,
                0.15
              )}, 0 5px 20px ${alpha(secondaryColor, 0.1)}`,
            }}
          >
            {/* Kart üzerinde hafif parlaklık efekti */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                height: "30%",
                background: `linear-gradient(to bottom, ${alpha(
                  "#fff",
                  0.1
                )}, ${alpha("#fff", 0)})`,
                zIndex: 0,
              }}
            />

            {/* Logo PNG Bölümü */}
            <Box
              sx={{
                position: "relative",
                mb: isMobile ? 3 : 4,
                zIndex: 1,
                width: logoSize,
                height: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Işık efekti dairesi */}
              <motion.div
                animate={{
                  scale: [1, 1.08, 1],
                  opacity: [0.2, 0.3, 0.2],
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
                style={{
                  position: "absolute",
                  width: "130%",
                  height: "130%",
                  borderRadius: "50%",
                  background: `radial-gradient(circle, ${alpha(
                    primaryColor,
                    0.1
                  )} 0%, ${alpha(primaryColor, 0)} 70%)`,
                }}
              />

              {/* Logo resmi */}
              <motion.img
                src={logoImage}
                alt="ÖLÇÜ Logo"
                animate={logoAnimControls}
                style={{
                  width: "100%",
                  height: "auto",
                  filter: "drop-shadow(0 5px 10px rgba(0,0,0,0.1))",
                  position: "relative",
                  zIndex: 1,
                }}
                drag
                dragConstraints={{
                  top: -3,
                  left: -3,
                  right: 3,
                  bottom: 3,
                }}
                dragElastic={0.05}
                whileHover={{
                  scale: 1.03,
                  filter: "drop-shadow(0 8px 15px rgba(0,0,0,0.15))",
                }}
              />
            </Box>

            {/* Slogan */}
            <motion.div
              initial={{ opacity: 0, y: 15 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.7 }}
              style={{
                marginBottom: isMobile ? "1.5rem" : "2rem",
                textAlign: "center",
                position: "relative",
                zIndex: 1,
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: sloganFontSize,
                  fontWeight: 500,
                  color: secondaryColor,
                  textAlign: "center",
                  letterSpacing: "0.01em",
                  mb: 1,
                }}
              >
                <span style={{ color: primaryColor, fontWeight: 700 }}>
                  ÖLÇÜ
                </span>
                'lü olmak hep kazandırmıştır.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: alpha(secondaryColor, 0.7),
                  fontStyle: "italic",
                  fontSize: isMobile ? 13 : 15,
                  mt: 1,
                }}
              >
                "Eğitimin ölçüsü, geleceğin mimarı"
              </Typography>
            </motion.div>

            {/* İlerleme çubuğu - daha modern */}
            <motion.div
              initial={{ opacity: 0, y: 15 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.7 }}
              style={{
                width: "100%",
                position: "relative",
                zIndex: 1,
              }}
            >
              <Box sx={{ width: "100%", position: "relative" }}>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{
                    height: isMobile ? 6 : 8,
                    borderRadius: 4,
                    backgroundColor: alpha(primaryColor, 0.1),
                    ".MuiLinearProgress-bar": {
                      borderRadius: 4,
                      background: `linear-gradient(90deg, ${primaryColor}, ${accentColor1}, ${accentColor2}, ${accentColor4})`,
                      backgroundSize: "400% 100%",
                      animation: "progressBarAnimation 4s linear infinite",
                    },
                    "@keyframes progressBarAnimation": {
                      "0%": {
                        backgroundPosition: "0% 50%",
                      },
                      "100%": {
                        backgroundPosition: "100% 50%",
                      },
                    },
                  }}
                />

                {/* İlerleme çubuğu üzerinde kayan ışık efekti */}
                <motion.div
                  animate={{ x: ["-100%", "200%"] }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut",
                    repeatDelay: 0.8,
                  }}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "20%",
                    height: "100%",
                    background: `linear-gradient(90deg, ${alpha(
                      "#fff",
                      0
                    )} 0%, ${alpha("#fff", 0.4)} 50%, ${alpha(
                      "#fff",
                      0
                    )} 100%)`,
                    borderRadius: 8,
                    pointerEvents: "none",
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 1.5,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: alpha(secondaryColor, 0.7),
                    fontWeight: 500,
                    fontSize: isMobile ? 13 : 14,
                  }}
                >
                  Yükleniyor...
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    color: primaryColor,
                    fontWeight: 600,
                    fontSize: isMobile ? 13 : 14,
                  }}
                >
                  {Math.floor(progress)}%
                </Typography>
              </Box>
            </motion.div>

            {/* Telif hakkı */}
            <Typography
              variant="caption"
              sx={{
                color: alpha(secondaryColor, 0.5),
                fontSize: 11,
                mt: isMobile ? 3 : 4,
                textAlign: "center",
              }}
            >
              © {new Date().getFullYear()} ÖLÇÜ EĞİTİM VE TEDARİK HİZMETLERİ
            </Typography>
          </Paper>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LoadingScreen;