import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { translations as trTranslations } from "../locales/tr/translation";
import { translations as enTranslations } from "../locales/en/translation";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      tr: {
        translation: trTranslations,
      },
      en: {
        translation: enTranslations,
      },
    },
    fallbackLng: "tr",
    debug: process.env.NODE_ENV !== "production",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
