import { formTranslations } from "./form";

export const translations = {
  common: {
    companyName: "OLCU EDUCATION AND SUPPLY SERVICES",
    loading: "Loading...",
    home: "Home",
    about: "About",
    services: "Services",
    contact: "Contact",
    references: "References",
    language: "Language",
    search: "Search",
    more: "More",
    readMore: "Read More",
    ourServices: "Our Services",
    phoneNumber: "Phone Number",
    email: "Email",
    address: "Address",
    contactUs: "Contact Us",
    copyright: "All rights reserved",
    allServices: "All Services",
    technicalSupport: "Technical Support",
    quickLinks: "Quick Links",
    send: "Send",
    contactForm: "Contact Form",
    name: "Full Name",
    phone: "Phone",
    message: "Your Message",
    workingHours: "Working Hours",
    quotationRequest: "Request Quote",
    explore: "Explore",
    quotationForm: "Quotation Form",
    thanks: "Thank you!",
    quotationReceived: "Your quotation request has been successfully received.",
    willContact: "Our team will contact you as soon as possible.",
    returnToServices: "Return to Services",
    privacyPolicy: "Privacy Policy",
    categories: "Categories",
  },
  header: {
    callUs: "Call Us",
    sendEmail: "Send Email",
    ourLocation: "Our Location",
  },
  services: {
    schoolPublications: "School Publications",
    schoolAutomation: "School Automation Systems",
    schoolWebSoftware: "School Web Software",
    officialControl: "School Official Control and Staff Training",
    codingEducation: "Coding and Software Education",
    robotics: "Robotics Materials",
    foodServices: "Food Services",
    transportServices: "Transport Services",
    stationery: "Stationery Products",
    cleaningFood: "Cleaning and Food Products",
    teacherTrainings: "Teacher Training and Seminars",
    registrationConsultancy: "Registration Consultancy",
    servicesDescription:
      "Professional solutions that meet all the needs of educational institutions",
    education: "Education",
    technology: "Technology",
    consultancy: "Consultancy",
    supply: "Supply",
    support: "Support",
    allCategories: "All Categories",
    noServicesInCategory: "There are no services in this category yet",
    selectAnotherCategory:
      "You can select another category or view all our services.",
    showAllServices: "Show All Services",
    contact: "Get in Touch",
    contactDescription:
      "Contact us for detailed information about our services.",
  },
  home: {
    welcomeTitle: "Education Solutions and Consultancy Services",
    welcomeTitle2: "Digital Transformation in Education",
    welcomeSubtitle:
      "We are with you with corporate and professional solutions",
    ourReference: "Our References",
    testimonials: "Testimonials",
    whyChooseUs: "Why Choose Us?",
    whyChooseUsSubtitle:
      "Your professional solution partner to take your educational institution one step further",
    educationExpertise: "Education Expertise",
    educationExpertiseDesc:
      "We offer expert solutions for all the needs of educational institutions with 10+ years of industry experience.",
    digitalTransformation: "Digital Transformation",
    digitalTransformationDesc:
      "We enable the digital transformation of educational institutions using modern technologies, increasing their efficiency.",
    supportService: "Technical Support",
    supportServiceDesc:
      "Our technical support team is always by your side. We provide solution-oriented support service between 09:00-18:00.",
    customizedSolutions: "Customized Solutions",
    customizedSolutionsDesc:
      "We analyze the unique needs of each institution and provide you with tailored solutions and consulting services.",
    servingInstitutions:
      "We serve 500+ educational institutions across Turkey.",
    quickContact: "Quick Contact",
    workingDays: "7 days a week",
    ourPartners: "Our Brands",
    digitalSolutions: "Our Digital Solutions",
    codingRoboticTitle: "Coding and Robotics Education",
    modernSkills: "Provide your students with 21st century skills",
    codingEducation: "Coding Education",
    roboticMaterials: "Robotics Materials",
    testimonialDescription: "What our customers say about us",
    professionalSolutions:
      "We offer professional solutions that meet all the needs of your educational institution",
    exploreAllServices: "Explore All Our Services",
    previous: "Previous",
    next: "Next",
    getQuote: "Get Quote",
    viewServices: "Our Digital Solutions",
    schoolPublications: "School Publications",
    schoolPublicationsDesc:
      "Enhance the quality of education with publications tailored for your school. Our publications are prepared for all age groups and arranged according to the national curriculum.",
    schoolAutomation: "School Automation Systems",
    schoolAutomationDesc:
      "Increase the efficiency of your school with modern automation systems. Manage student tracking, attendance, grading system and more on a single platform.",
    codingEducationDesc:
      "Bring the technologies of the future to your students today. Coding education solutions with curricula and educational materials specific to age groups.",
    teacherTrainings: "Teacher Training and Seminars",
    teacherTrainingsDesc:
      "Support the development of your teachers with professional trainings. Custom seminars with expert trainers in their fields.",
    roboticsDesc:
      "Strengthen your STEM education with the latest robotics materials. All solutions from laboratory setup to trainer support.",
    registrationConsultancy: "Registration Consultancy",
    registrationConsultancyDesc:
      "Optimize your student registration processes with professional consultancy. Strategic solutions to increase your occupancy rate.",
  },
  contact: {
    getInTouch: "Get In Touch",
    fullName: "Full Name",
    email: "Email",
    phone: "Phone",
    message: "Your Message",
    send: "Send",
    successMessage:
      "Your message has been sent successfully. We will get back to you as soon as possible.",
    redirectMessage:
      "You are being redirected to your email application. Please complete the process to send your message.",

    errorMessage:
      "An error occurred while sending your message. Please try again later.",
    workingHours: "Working Hours",
    address: "Our Address",
    contactUs: "Contact Us",
    formDescription:
      "You can send us a message by filling out the form for all your questions and suggestions. We will contact you as soon as possible.",
  },
  // translation.js dosyasına eklenecek "references" bölümü:

  references: {
    title: "Our References",
    subtitle:
      "Our professional team working in line with our trust and privacy principles",
    privacyTitle: "Client Privacy and GDPR Compliance",
    privacyDescription1:
      "Our company values sharing collaborations and success stories with our clients. However, in line with our sensitivity on the protection of personal data and client information confidentiality, we do not publicly share our client references in accordance with the Personal Data Protection Law (GDPR) and the principle of protecting commercially confidential information.",
    privacyDescription2:
      "Protecting our partners' information is our priority within the framework of the trust relationship we have established with our clients and the responsibilities we have assumed as a data controller.",
    dataSecurityTitle: "Data Security",
    dataSecurityDescription:
      "In line with our information security policies, all our client data is protected to international standards. All data, including corporate identity information, is not shared without client permission.",
    trustedPartnerTitle: "Trusted Business Partner",
    trustedPartnerDescription:
      "We provide services with the experience and trust of years in the industry. Our long-term business partnerships with our clients and our high client satisfaction rate are the most important indicators of our success.",
    contactTitle: "For Our References and Special Meetings",
    contactDescription:
      "We organize special meetings for our potential clients, sharing our success stories and references in similar sectors within the framework of confidentiality. By contacting us through our contact form, you can both get information about our references and request a special meeting.",
    contactButtonText: "Contact Form",
    dataSafety: "Your data is safe",
  },
  quotation: {
    title: "Quotation Form",
    description: "Please fill out the form below to request a quote.",
    sending: "Sending...",
    sendRequest: "Send Quotation Request",
    privacyNote:
      "* By filling out this form, you consent to the processing of your personal data and to be contacted within the scope of KVKK. You can review our Privacy Policy for detailed information.",
    invalidService:
      "No valid service selected. Please select a service from the services page.",
    success: "Thank you!",
    successMessage: "Your quotation request has been successfully received.",
    contactSoon: "Our team will contact you as soon as possible.",
  },
  about: {
    heroTitle: "About Us",
    heroSubtitle:
      "Your Trusted Partner in Education Consulting and Supply Services Since 2015",
    whoWeAre: "Who We Are",
    introFirstParagraph:
      "Olcu Education is a people-oriented institution that has been providing education consultancy and supply services since 2015, deriving its strength from customer satisfaction. While offering individual and corporate consultancy services on one hand, we provide reliable solutions in the supply of products needed by educational institutions on the other.",
    introSecondParagraph:
      "With our experience in the education sector and our expert staff, we develop special solutions for educational institutions of all sizes, strengthen your supply chain, and contribute to the growth and development processes of institutions. Without compromising our principles, we continue to add value to the education sector with innovative approaches.",
    servicesTitle: "Our Consulting and Supply Services",
    educationConsultingTitle: "Education Consulting",
    educationConsultingDesc:
      "We provide comprehensive consultancy services with our expert team to increase the academic success of your institution and improve the quality of education.",
    educationFeature1: "Corporate Education Consulting",
    educationFeature2: "Educational Program Development",
    educationFeature3: "Teacher and Administrator Training",
    educationFeature4: "Academic Success Coaching",
    supplyServicesTitle: "Supply Services",
    supplyServicesDesc:
      "We provide reliable and cost-effective supply of all materials and equipment needed by your educational institutions.",
    supplyFeature1: "Educational Materials Supply",
    supplyFeature2: "Technological Equipment Solutions",
    supplyFeature3: "Stationery and Office Supplies",
    supplyFeature4: "School Furniture",
    visionTitle: "Our Vision",
    visionDesc:
      "To become a leader in education consultancy and supply, raising the quality of educational institutions in Turkey with innovative and sustainable solutions and contributing to their achievement of international standards.",
    missionTitle: "Our Mission",
    missionDesc:
      "To analyze the needs of educational institutions correctly, to increase the efficiency of institutions with technology and human-oriented solutions, to optimize supply processes, to maximize student and teacher satisfaction, and to raise quality standards in education.",
    valuesTitle: "Our Values",
    value1Title: "Reliability",
    value1Desc:
      "Transparency and honesty are our most important principles in all our business processes. We stand behind our promises and earn the trust of our customers.",
    value2Title: "Innovation",
    value2Desc:
      "We place importance on research and development for continuous improvement and innovative solutions, and follow the latest trends in the education sector.",
    value3Title: "People-Oriented",
    value3Desc:
      "People in educational institutions are at the center of every solution we provide. We provide solutions with a customer satisfaction and people-oriented approach.",
    statisticsTitle: "Olcu Education in Numbers",
    stat1: "Years of Experience",
    stat2: "Educational Institutions",
    stat3: "Expert Staff",
    stat4: "Service Areas",
    testimonialsTitle: "What Our Customers Say",
    previous: "Previous",
    next: "Next",
    ctaTitle:
      "Would You Like to Benefit from Our Education Consulting and Supply Services?",
    ctaDesc:
      "Contact us for solutions tailored to your institution's needs. Let us offer you suitable consulting and supply plans.",
    ctaButton: "Contact Us",
    testimonials: {
      testimonial1:
        "With the consultancy service provided by Olcu Education, our school's academic success has increased by 30% in the last two years. Thank you for their professional teams and solution-oriented approaches.",
      testimonial2:
        "Without the support of Olcu Education in our transition to automation, we would not have been able to adapt so quickly. Thanks to special software solutions and supply services, our administrative processes are now much more efficient.",
      testimonial3:
        "Since we started working with Olcu Education for both consultancy and supply services, our work has become easier, our costs have decreased and our students' success has increased.",
      testimonial4:
        "Thanks to their expertise in supply processes, our institution has gained a significant cost advantage. At the same time, with the education consultancy services they offer, our students' success graph has risen.",
      testimonial5:
        "Thanks to the meticulousness and speed shown by Olcu Education in the supply of school publications and educational materials, our beginning of semester preparations were completed without any problems. Our teachers are also satisfied.",
      testimonial6:
        "We worked with an expert team on coding education and supply of robotics sets. The success our students have achieved in the field of technology proves themselves.",
      testimonial7:
        "They solved all the needs of our newly opened school from a single source. They displayed a professional approach to consultancy and supply.",
      testimonial8:
        "The service they provided in teacher training and professional development programs was above our expectations. They developed solutions specific to our institution.",
      testimonial9:
        "Thanks to the quality and economical solutions they provide in meeting the stationery and furniture needs of our school, we have achieved a long-term collaboration.",
      testimonial10:
        "We have been working with Olcu Education for 3 years and we expand our cooperation more and more every year. Their professionalism in education consultancy and supply processes is admirable.",
      role1: "School Principal",
      role2: "Information Technology Teacher",
      role3: "School Founder",
      role4: "Primary School Principal",
      role5: "Purchasing Manager",
      role6: "Head of IT Department",
      role7: "School Administrator",
      role8: "Education Coordinator",
      role9: "Administrative Affairs Officer",
      role10: "General Manager",
    },
  },
  footer: {
    companyDescription:
      "OLCU EDUCATION AND SUPPLY SERVICES provides specialized solutions and consulting services to educational institutions. We are a leader in the sector with our quality and professional service approach.",
    technicalSupport: "Technical Support",
    supportDescription:
      "Remember that you can get technical support at OLCU between 09:00 and 18:00. We help you solve your problems.",
  },

  // Form translations
  form: formTranslations,
};

export default translations;
