import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Button,
  MenuItem,
  Drawer,
  ListItemText,
  List,
  ListItem,
  ListItemButton,
  Divider,
  Tooltip,
  useMediaQuery,
  useTheme,
  Paper,
  Popper,
  Fade,
  Stack,
  Chip,
  alpha,
  ButtonGroup,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import {
  Language as LanguageIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  LocationOn as LocationOnIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  Home as HomeIcon,
  Info as InfoIcon,
  Business as BusinessIcon,
  ContactPhone as ContactPhoneIcon,
  Assignment as AssignmentIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

// Logo placeholder - Replace with actual logo
import logoPath from "../../assets/images/icons/logo.png";

const Header = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // State variables
  const [anchorElLang, setAnchorElLang] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [hoveredPage, setHoveredPage] = useState(null);

  // Navigation items with icons
  const pages = [
    { name: t("common.home") || "Ana Sayfa", path: "/", icon: <HomeIcon /> },
    {
      name: t("common.about") || "Hakkımızda",
      path: "/hakkimizda",
      icon: <InfoIcon />,
    },
    {
      name: t("common.services") || "Hizmetler",
      path: "/hizmetler",
      icon: <BusinessIcon />,
    },
    {
      name: t("common.references") || "Referanslar",
      path: "/referanslar",
      icon: <AssignmentIcon />,
    },
    {
      name: t("common.contact") || "İletişim",
      path: "/iletisim",
      icon: <ContactPhoneIcon />,
    },
  ];

  // Language options
  const languages = [
    { code: "tr", name: "Türkçe" },
    { code: "en", name: "English" },
  ];

  // Sosyal medya linkleri
  const socialLinks = [
    {
      icon: <FacebookIcon fontSize="small" />,
      url: "/",
      label: "Facebook",
      color: "#3b5998",
    },
    {
      icon: <TwitterIcon fontSize="small" />,
      url: "https://twitter.com/olcuegitim",
      label: "Twitter",
      color: "#1da1f2",
    },
    {
      icon: <InstagramIcon fontSize="small" />,
      url: "https://instagram.com/olcuegitim",
      label: "Instagram",
      color: "#c32aa3",
    },
    {
      icon: <LinkedInIcon fontSize="small" />,
      url: "https://linkedin.com/company/olcuegitim",
      label: "LinkedIn",
      color: "#0a66c2",
    },
  ];

  // Scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Handle language change
  const handleLanguageChange = (langCode) => {
    i18n.changeLanguage(langCode);
    setAnchorElLang(null);
  };

  // Toggle the drawer
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  // Handle hover for any navigation item
  const handleNavHover = (pageName) => {
    setHoveredPage(pageName);
  };

  // Font size for menu items
  const menuFontSize = "1rem";

  return (
    <>
      {/* Upper Info Bar */}
      <Box
        sx={{
          background: `linear-gradient(90deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
          color: "white",
          py: { xs: 1, md: 0.8 },
          position: "relative",
          overflow: "hidden",
          width: "100%", // Genişliği tam olarak 100% yap
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)",
            zIndex: 1,
          },
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "center", md: "center" },
              position: "relative",
              zIndex: 2,
            }}
          >
            {/* Sol taraf - İletişim bilgileri */}
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 3 }}
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ backgroundColor: "rgba(255,255,255,0.3)" }}
                />
              }
              sx={{
                py: 0.5,
                alignItems: "center",
                display: { xs: "none", sm: "flex" }, // Mobilde gizle
              }}
            >
              {/* Telefon */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  transition: "all 0.3s ease",
                  "&:hover": { transform: "translateY(-2px)" },
                }}
              >
                <Tooltip title={t("header.callUs")}>
                  <IconButton
                    color="inherit"
                    size="small"
                    href="tel:0850 480 65 28"
                    sx={{ mr: 0.5 }}
                  >
                    <PhoneIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontSize: "0.875rem" }}
                >
                  0850 480 65 28
                </Typography>
              </Box>

              {/* Email */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  transition: "all 0.3s ease",
                  "&:hover": { transform: "translateY(-2px)" },
                }}
              >
                <Tooltip title={t("header.sendEmail")}>
                  <IconButton
                    color="inherit"
                    size="small"
                    href="mailto:info@olcuegitim.com"
                    sx={{ mr: 0.5 }}
                  >
                    <EmailIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontSize: "0.875rem" }}
                >
                  info@olcuegitim.com
                </Typography>
              </Box>

              {/* Konum - Opsiyonel */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  transition: "all 0.3s ease",
                  "&:hover": { transform: "translateY(-2px)" },
                }}
              >
                <Tooltip title={t("header.ourLocation")}>
                  <IconButton
                    color="inherit"
                    size="small"
                    href="https://maps.google.com/?q=Ölçü+Eğitim+İstanbul"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ mr: 0.5 }}
                  >
                    <LocationOnIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ fontSize: "0.875rem" }}
                >
                  İstanbul, Türkiye
                </Typography>
              </Box>
            </Stack>

            {/* Sağ taraf - Sosyal medya */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {socialLinks.map((social, index) => (
                <Tooltip key={index} title={social.label}>
                  <IconButton
                    color="inherit"
                    size="small"
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      mx: 0.5,
                      transition: "all 0.3s ease",
                      "&:hover": {
                        transform: "scale(1.2)",
                        color: social.color,
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      },
                    }}
                  >
                    {social.icon}
                  </IconButton>
                </Tooltip>
              ))}
            </Box>
          </Box>
        </Container>
      </Box>

      <AppBar
        position={isScrolled ? "fixed" : "static"}
        sx={{
          bgcolor: "white",
          color: "primary.main",
          boxShadow: isScrolled ? 4 : 3,
          top: isScrolled ? 0 : "auto",
          transition: "box-shadow 0.3s ease",
          zIndex: 1000, // Sabit bir değer kullanarak Drawer'dan daha düşük olmasını sağlayın
          transform: isScrolled ? "translateY(0)" : "translateY(0)",
          animation: isScrolled ? "slideDown 0.8s ease-out" : "none",
          "@keyframes slideDown": {
            "0%": {
              transform: "translateY(-100%)",
            },
            "100%": {
              transform: "translateY(0)",
            },
          },
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{
              py: 2,
              height: 90,
            }}
          >
            {/* Logo - Orijinal yeri korundu */}
            <Box
              sx={{
                mr: 2,
                display: "flex",
                flexGrow: { xs: 1, md: 0 },
                position: { xs: "relative", md: "static" },
                zIndex: { xs: drawerOpen ? 1301 : "auto", md: "auto" }, // Higher z-index when drawer is open
              }}
            >
              <Link to="/">
                <img
                  src={logoPath}
                  alt="ÖLÇÜ EĞİTİM"
                  height={isMobile ? "50" : "60"}
                />
              </Link>
            </Box>

            {/* Desktop navigation - Centered */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
                alignItems: "center",
                height: 90,
              }}
            >
              <Box>
                {pages.map((page) => (
                  <Button
                    key={page.name}
                    component={Link}
                    to={page.path}
                    onMouseEnter={() => handleNavHover(page.path)}
                    sx={{
                      color: "primary.main",
                      textTransform: "none",
                      mx: 1.5,
                      py: 1.5,
                      fontSize: menuFontSize,
                      position: "relative",
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: theme.palette.secondary.main,
                      },
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        width: "0%",
                        height: "2px",
                        bottom: "5px",
                        left: "0",
                        backgroundColor: theme.palette.secondary.main,
                        transition: "width 0.3s ease-in-out",
                      },
                      "&:hover::after": {
                        width: "100%",
                      },
                    }}
                  >
                    {page.name}
                  </Button>
                ))}
              </Box>
            </Box>

            {/* Mobile menu - Orijinal yeri korundu (sağda) */}
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(!drawerOpen)}
                color="inherit"
                sx={{
                  zIndex: 1301,
                  borderRadius: "12px",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.primary.main, 0.08),
                  },
                }}
              >
                <MenuIcon />
              </IconButton>
            </Box>

            {/* Language selector - Right aligned */}
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Box
                onClick={(e) => setAnchorElLang(e.currentTarget)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: 2,
                  border: "1px solid rgba(255, 255, 255, 0.3)",
                  borderRadius: "30px",
                  padding: "4px 12px",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    transform: "translateY(-2px)",
                  },
                }}
              >
                <LanguageIcon sx={{ fontSize: "18px", mr: 1 }} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "medium",
                    textTransform: "uppercase",
                    letterSpacing: "0.5px",
                  }}
                >
                  {i18n.language === "tr" ? "TR" : "EN"}
                </Typography>
              </Box>
              <Popper
                open={Boolean(anchorElLang)}
                anchorEl={anchorElLang}
                placement="bottom-end"
                transition
                disablePortal
                style={{ zIndex: 1300 }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={200}>
                    <Paper elevation={3} sx={{ mt: 1, width: 120 }}>
                      {languages.map((lang) => (
                        <MenuItem
                          key={lang.code}
                          onClick={() => handleLanguageChange(lang.code)}
                          sx={{
                            py: 1,
                            fontSize: menuFontSize,
                            "&:hover": {
                              backgroundColor: `${theme.palette.secondary.light}20`,
                            },
                          }}
                        >
                          {lang.name}
                        </MenuItem>
                      ))}
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Modern Sağdan Açılan Hamburger Menü - Scrollbar'sız */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          zIndex: 1400, // Ensure the drawer appears on top
          "& .MuiDrawer-paper": {
            width: "85%",
            maxWidth: "320px",
            borderRadius: "16px 0 0 16px",
            boxShadow: "0 8px 40px rgba(0,0,0,0.15)",
            backgroundImage: `linear-gradient(to bottom, ${alpha(
              theme.palette.primary.light,
              0.05
            )}, ${alpha(theme.palette.background.default, 1)} 30%)`,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              display: "none", // Webkit tarayıcılar için scrollbar'ı gizle
            },
            scrollbarWidth: "none", // Firefox için scrollbar'ı gizle
            msOverflowStyle: "none", // IE ve Edge için scrollbar'ı gizle
          },
        }}
      >
        {/* Menü header - İki Satırlı Çözüm */}
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid rgba(0,0,0,0.08)",
            background: `linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
            color: "white",
            minHeight: 80, // Min-height kullanarak içerik büyüdüğünde genişlemeye izin ver
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              maxWidth: "calc(100% - 40px)", // Logo ve yazı için alan sınırlaması
            }}
          >
            {/* Logo */}
            <Box sx={{ flexShrink: 0, mr: 2 }}>
              <img
                src={logoPath}
                alt="ÖLÇÜ EĞİTİM"
                style={{
                  height: "44px",
                  objectFit: "contain",
                }}
              />
            </Box>

            {/* Şirket İsmi - İki satırlı düzen */}
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontSize: { xs: "0.9rem", sm: "1rem" },
                fontWeight: 500,
                textShadow: "0px 1px 2px rgba(0,0,0,0.1)",
                lineHeight: 1.2, // Satır aralığını azalt
                whiteSpace: "normal", // Metin sarma özelliği etkin
                wordBreak: "break-word", // Uzun kelimeler bölünsün
                overflow: "visible", // Taşma görünsün
              }}
            >
              {t("common.companyName") || "ÖLÇÜ EĞİTİM"}
            </Typography>
          </Box>

          <IconButton
            onClick={toggleDrawer(false)}
            size="small"
            sx={{
              color: "white",
              border: "1px solid rgba(255,255,255,0.3)",
              borderRadius: "50%",
              width: 32,
              height: 32,
              flexShrink: 0, // Buton boyutu korunacak
              ml: 1, // Sol kenar boşluğu ekle
              "&:hover": {
                backgroundColor: "rgba(255,255,255,0.1)",
              },
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        {/* Dil seçimi - Header'ın hemen altında, kompakt butonlar halinde */}
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid rgba(0,0,0,0.06)",
          }}
        >
          <Typography variant="subtitle2" color="text.secondary">
            {t("common.language") || "Dil"}:
          </Typography>
          <ButtonGroup variant="outlined" size="small">
            {languages.map((lang) => (
              <Button
                key={lang.code}
                onClick={() => handleLanguageChange(lang.code)}
                sx={{
                  px: 2,
                  backgroundColor:
                    i18n.language === lang.code
                      ? alpha(theme.palette.primary.main, 0.1)
                      : "transparent",
                  color:
                    i18n.language === lang.code
                      ? theme.palette.primary.main
                      : theme.palette.text.secondary,
                  fontWeight: i18n.language === lang.code ? 600 : 400,
                  borderColor:
                    i18n.language === lang.code
                      ? theme.palette.primary.main
                      : alpha(theme.palette.text.secondary, 0.2),
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.primary.main, 0.05),
                    borderColor: theme.palette.primary.main,
                  },
                }}
              >
                {lang.code.toUpperCase()}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
        {/* Menü içeriği */}
        <Box sx={{ py: 1 }}>
          <List>
            {pages.map((page) => (
              <ListItem key={page.name} disablePadding>
                <ListItemButton
                  component={Link}
                  to={page.path}
                  sx={{
                    py: 1.5,
                    px: 3,
                    color: theme.palette.text.primary,
                    transition: "all 0.3s ease",
                    borderRight: "4px solid transparent",
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08),
                      borderRight: `4px solid ${theme.palette.primary.main}`,
                      paddingRight: 4,
                    },
                  }}
                  onClick={toggleDrawer(false)}
                >
                  <Box
                    sx={{
                      mr: 3,
                      color: theme.palette.primary.main,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {page.icon}
                  </Box>
                  <ListItemText
                    primary={page.name}
                    primaryTypographyProps={{
                      fontSize: "1rem",
                      fontWeight: 500,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        {/* İletişim bilgileri - Kompakt format */}
        <Box
          sx={{
            p: 3,
            mt: "auto", // Alt kısma yapıştır
            borderTop: "1px solid rgba(0,0,0,0.06)",
            backgroundColor: alpha(theme.palette.background.paper, 0.5),
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <PhoneIcon
              sx={{ fontSize: 18, mr: 2, color: theme.palette.primary.main }}
            />
            <Typography variant="body2">0850 480 65 28</Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <EmailIcon
              sx={{ fontSize: 18, mr: 2, color: theme.palette.primary.main }}
            />
            <Typography variant="body2">info@olcuegitim.com</Typography>
          </Box>
        </Box>
        {/* Sosyal medya linkleri - Kompakt butonlar */}
        <Box
          sx={{
            py: 2,
            px: 3,
            display: "flex",
            justifyContent: "center",
            borderTop: "1px solid rgba(0,0,0,0.06)",
            backgroundColor: alpha(theme.palette.background.paper, 0.3),
          }}
        >
          {socialLinks.map((social, index) => (
            <IconButton
              key={index}
              href={social.url}
              target="_blank"
              rel="noopener noreferrer"
              size="small"
              sx={{
                mx: 1,
                backgroundColor: alpha(social.color, 0.1),
                color: social.color,
                width: 32,
                height: 32,
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-3px)",
                  backgroundColor: social.color,
                  color: "#fff",
                },
              }}
            >
              {social.icon}
            </IconButton>
          ))}
        </Box>
      </Drawer>

      {/* Placeholder to prevent content jumping when AppBar becomes fixed */}
      {isScrolled && <Box sx={{ height: 90 }} />}
    </>
  );
};

export default Header;
