import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  Divider,
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
  IconButton,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Phone,
  Email,
  LocationOn,
  Facebook,
  Twitter,
  Instagram,
  LinkedIn,
  YouTube,
  KeyboardArrowRight,
  Support,
} from "@mui/icons-material";

// Logo placeholder - Replace with actual logo
import logoPath from "../../assets/images/icons/logo.png";

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <Box sx={{ bgcolor: "secondary.light", color: "white", pt: 6, pb: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Company Information */}
          <Grid item xs={12} md={4}>
            <Box sx={{ mb: 3 }}>
              <Link component={RouterLink} to="/">
                <img src={logoPath} alt={t("common.companyName")} height="60" />
              </Link>
            </Box>
            <Typography variant="body2" paragraph>
              {t("footer.companyDescription")}
            </Typography>
            <Stack direction="row" spacing={1}>
              <IconButton size="small" sx={{ color: "#3b5998" }}>
                <Facebook />
              </IconButton>
              <IconButton
                size="small"
                sx={{ color: "#1da1f2" }}
                component="a"
                href="https://twitter.com/olcuegitim"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <Twitter />
              </IconButton>
              <IconButton
                size="small"
                sx={{ color: "#c32aa3" }}
                component="a"
                href="https://instagram.com/olcuegitim"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <Instagram />
              </IconButton>
              <IconButton
                size="small"
                sx={{ color: "#0a66c2" }}
                component="a"
                href="https://linkedin.com/company/olcuegitim"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <LinkedIn />
              </IconButton>
              <IconButton
                size="small"
                sx={{ color: "#ff0000" }}
                component="a"
                href="https://youtube.com/olcuegitim"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="YouTube"
              >
                <YouTube />
              </IconButton>
            </Stack>
          </Grid>

          {/* Technical Support Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ mb: 3 }}>
              <Typography
                variant="h6"
                gutterBottom
                fontWeight="bold"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  color: "white",
                }}
              >
                <Support /> {t("common.technicalSupport")}
              </Typography>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  color: "white",
                  bgcolor: "rgba(255,255,255,0.1)",
                  borderRadius: 2,
                  border: "1px solid rgba(255,255,255,0.2)",
                }}
              >
                <Typography variant="body2">
                  {t("footer.supportDescription")}
                </Typography>
              </Paper>
            </Box>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" gutterBottom fontWeight="bold">
              {t("common.quickLinks")}
            </Typography>
            <List dense>
              <ListItem disablePadding>
                <ListItemIcon sx={{ minWidth: 30, color: "white" }}>
                  <KeyboardArrowRight fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Link
                    component={RouterLink}
                    to="/"
                    color="inherit"
                    underline="hover"
                  >
                    {t("common.home")}
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon sx={{ minWidth: 30, color: "white" }}>
                  <KeyboardArrowRight fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Link
                    component={RouterLink}
                    to="/hakkimizda"
                    color="inherit"
                    underline="hover"
                  >
                    {t("common.about")}
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon sx={{ minWidth: 30, color: "white" }}>
                  <KeyboardArrowRight fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Link
                    component={RouterLink}
                    to="/hizmetler"
                    color="inherit"
                    underline="hover"
                  >
                    {t("common.services")}
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon sx={{ minWidth: 30, color: "white" }}>
                  <KeyboardArrowRight fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Link
                    component={RouterLink}
                    to="/referanslar"
                    color="inherit"
                    underline="hover"
                  >
                    {t("common.references")}
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon sx={{ minWidth: 30, color: "white" }}>
                  <KeyboardArrowRight fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Link
                    component={RouterLink}
                    to="/iletisim"
                    color="inherit"
                    underline="hover"
                  >
                    {t("common.contact")}
                  </Link>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom fontWeight="bold">
              {t("common.contactUs")}
            </Typography>
            <List dense>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <ListItemIcon sx={{ minWidth: 30, color: "primary.dark" }}>
                  <LocationOn fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Link
                    href="https://maps.google.com/?q=Ölçü+Eğitim+İstanbul"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="inherit"
                    underline="hover"
                    sx={{
                      transition: "color 0.3s",
                      "&:hover": { color: "secondary.main" },
                    }}
                  >
                    İstanbul, Türkiye
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <ListItemIcon sx={{ minWidth: 30, color: "primary.dark" }}>
                  <Phone fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Link
                    href="tel:0850 480 65 28"
                    color="inherit"
                    underline="hover"
                    sx={{
                      transition: "color 0.3s",
                      "&:hover": { color: "secondary.main" },
                    }}
                  >
                    0850 480 65 28
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <ListItemIcon sx={{ minWidth: 30, color: "primary.dark" }}>
                  <Email fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Link
                    href="mailto:info@olcuegitim.com"
                    color="inherit"
                    underline="hover"
                    sx={{
                      transition: "color 0.3s",
                      "&:hover": { color: "secondary.main" },
                    }}
                  >
                    info@olcuegitim.com
                  </Link>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid>

        <Divider sx={{ mt: 3, mb: 2, borderColor: "rgba(255,255,255,0.2)" }} />

        {/* Copyright */}
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="body2" color="white">
            {t("common.companyName")} © {currentYear} olcuegitim.com
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
