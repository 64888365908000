import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  useTheme,
  Paper,
  Avatar,
  Divider,
  alpha,
} from "@mui/material";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { styled } from "@mui/material/styles";

// Import components
import Partners from "../components/common/Partners";

// Import icons
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarHalfIcon from "@mui/icons-material/StarHalf";

import BookIcon from "@mui/icons-material/Book";
import ComputerIcon from "@mui/icons-material/Computer";
import CodeIcon from "@mui/icons-material/Code";
import SchoolIcon from "@mui/icons-material/School";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import WebIcon from "@mui/icons-material/Web";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SupportIcon from "@mui/icons-material/Support";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import sliderImage1 from "../assets/images/slider/slider1.avif";
import sliderImage2 from "../assets/images/slider/slider2.avif";
//import sliderImage3 from "../assets/images/slider/slider3.avif";

// Styled component for SectionTitle
const SectionTitle = styled(Typography)(({ theme }) => ({
  position: "relative",
  marginBottom: theme.spacing(5),
  fontWeight: 700,
  "&:after": {
    content: '""',
    position: "absolute",
    bottom: -12,
    left: "50%",
    transform: "translateX(-50%)",
    width: 70,
    height: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 2,
  },
}));

// Hizmet verileri
const servicesData = [
  {
    id: 1,
    title: "services.schoolPublications",
    icon: "BookIcon",
    path: "/hizmetler/okul-yayinlari",
  },
  {
    id: 2,
    title: "services.schoolAutomation",
    icon: "ComputerIcon",
    path: "/hizmetler/otomasyon-sistemleri",
  },
  {
    id: 5,
    title: "services.codingEducation",
    icon: "CodeIcon",
    path: "/hizmetler/kodlama-egitimi",
  },
  {
    id: 11,
    title: "services.teacherTrainings",
    icon: "SchoolIcon",
    path: "/hizmetler/ogretmen-egitimleri",
  },
  {
    id: 6,
    title: "services.robotics",
    icon: "PrecisionManufacturingIcon",
    path: "/hizmetler/robotik",
  },
  {
    id: 12,
    title: "services.registrationConsultancy",
    icon: "AssignmentIndIcon",
    path: "/hizmetler/kayit-danismanligi",
  },
];

const HomePage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isLoaded, setIsLoaded] = useState(false);
  const testimonialRef = useRef(null);

  useEffect(() => {
    // Simulate loading
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  const scrollTestimonials = (direction) => {
    if (testimonialRef.current) {
      // Slider'a erişip manuel kaydırma işlemini yapma
      const slider = testimonialRef.current;
      if (direction === "left") {
        slider.slickPrev();
      } else {
        slider.slickNext();
      }
    }
  };

  // Testimonial data from AboutPage
  const testimonials = [
    {
      text: t("about.testimonials.testimonial1"),
      name: "Ahmet Y.",
      title: t("about.testimonials.role1"),
      rating: 5,
      color: theme.palette.primary.main,
      lightColor: theme.palette.primary.light,
    },
    {
      text: t("about.testimonials.testimonial2"),
      name: "Zeynep K.",
      title: t("about.testimonials.role2"),
      rating: 4,
      color: theme.palette.success.main,
      lightColor: theme.palette.success.light,
    },
    {
      text: t("about.testimonials.testimonial3"),
      name: "Murat D.",
      title: t("about.testimonials.role3"),
      rating: 5,
      color: theme.palette.info.main,
      lightColor: theme.palette.info.light,
    },
    {
      text: t("about.testimonials.testimonial4"),
      name: "Selim B.",
      title: t("about.testimonials.role4"),
      rating: 4.5,
      color: theme.palette.warning.main,
      lightColor: theme.palette.warning.light,
    },
    {
      text: t("about.testimonials.testimonial5"),
      name: "Deniz A.",
      title: t("about.testimonials.role5"),
      rating: 4,
      color: theme.palette.secondary.main,
      lightColor: theme.palette.secondary.light,
    },
    {
      text: t("about.testimonials.testimonial6"),
      name: "Emre T.",
      title: t("about.testimonials.role6"),
      rating: 5,
      color: theme.palette.error.main,
      lightColor: theme.palette.error.light,
    },
    {
      text: t("about.testimonials.testimonial7"),
      name: "Hakan S.",
      title: t("about.testimonials.role7"),
      rating: 4,
      color: theme.palette.primary.dark,
      lightColor: theme.palette.primary.main,
    },
    {
      text: t("about.testimonials.testimonial8"),
      name: "Nur L.",
      title: t("about.testimonials.role8"),
      rating: 4.5,
      color: theme.palette.success.dark,
      lightColor: theme.palette.success.main,
    },
    {
      text: t("about.testimonials.testimonial9"),
      name: "Canan Y.",
      title: t("about.testimonials.role9"),
      rating: 4,
      color: theme.palette.info.dark,
      lightColor: theme.palette.info.main,
    },
    {
      text: t("about.testimonials.testimonial10"),
      name: "Burak M.",
      title: t("about.testimonials.role10"),
      rating: 5,
      color: theme.palette.warning.dark,
      lightColor: theme.palette.warning.main,
    },
  ];

  const renderRating = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    for (let i = 0; i < 5; i++) {
      if (i < fullStars) {
        stars.push(
          <StarIcon key={i} sx={{ color: theme.palette.warning.main }} />
        );
      } else if (i === fullStars && hasHalfStar) {
        stars.push(
          <StarHalfIcon key={i} sx={{ color: theme.palette.warning.main }} />
        );
      } else {
        stars.push(
          <StarBorderIcon key={i} sx={{ color: theme.palette.warning.main }} />
        );
      }
    }

    return stars;
  };

  // Slider settings-hero bannerda kullanılıyor
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: true,
  };

  // Testimonial slider settings
  const testimonialSliderSettings = {
    dots: false,
    infinite: true,
    speed: 12000, // Çok yavaş geçiş
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // Hemen başla
    cssEase: "linear", // Sabit hızda hareket
    pauseOnHover: true,
    arrows: false, // Özel butonlar kullanıyoruz
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    rtl: false,
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  // Render icon based on icon name
  const renderIcon = (iconName) => {
    switch (iconName) {
      case "BookIcon":
        return <BookIcon fontSize="large" />;
      case "ComputerIcon":
        return <ComputerIcon fontSize="large" />;
      case "CodeIcon":
        return <CodeIcon fontSize="large" />;
      case "SchoolIcon":
        return <SchoolIcon fontSize="large" />;
      case "PrecisionManufacturingIcon":
        return <PrecisionManufacturingIcon fontSize="large" />;
      case "AssignmentIndIcon":
        return <AssignmentIndIcon fontSize="large" />;
      case "WebIcon":
        return <WebIcon fontSize="large" />;
      case "AdminPanelSettingsIcon":
        return <AdminPanelSettingsIcon fontSize="large" />;
      case "FastfoodIcon":
        return <FastfoodIcon fontSize="large" />;
      case "AirportShuttleIcon":
        return <AirportShuttleIcon fontSize="large" />;
      case "BorderColorIcon":
        return <BorderColorIcon fontSize="large" />;
      case "CleaningServicesIcon":
        return <CleaningServicesIcon fontSize="large" />;
      default:
        return null;
    }
  };

  const ServiceCard = ({ title, icon, path, index }) => {
    // Generate a color for the service card based on its index
    const getServiceColor = () => {
      const colors = [
        theme.palette.primary,
        theme.palette.secondary,
        theme.palette.info,
        theme.palette.success,
        theme.palette.warning,
        theme.palette.error,
      ];
      return colors[index % colors.length];
    };

    const serviceColor = getServiceColor();

    // Extract serviceId from path
    // Example: "/hizmetler/okul-yayinlari" -> "okul-yayinlari"
    const serviceId = path.split("/").pop();

    // Get the display title from the translation object using the title key
    const displayTitle = t(title);

    // Get the description from home translations based on the service type
    // We'll use the pattern "home.[serviceType]Desc" where serviceType is the last part of the title
    // Example: "services.schoolPublications" -> "home.schoolPublicationsDesc"
    const serviceType = title.split(".").pop();
    const descriptionKey = `home.${serviceType}Desc`;
    const description = t(descriptionKey);

    return (
      <Card
        sx={{
          height: { xs: 400, sm: 450, md: 430 },
          display: "flex",
          flexDirection: "column",
          borderRadius: 3,
          border: "1px solid rgba(0,0,0,0.05)",
          overflow: "hidden",
          position: "relative",
          transition: "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
          boxShadow: "0 10px 20px rgba(0,0,0,0.05)",
          "&:hover": {
            transform: "translateY(-15px)",
            boxShadow: `0 20px 40px ${serviceColor.main}40`,
            "& .service-icon": {
              transform: "scale(1.1) rotate(10deg)",
              color: "white",
              backgroundColor: serviceColor.main,
            },
            "& .service-title": {
              color: serviceColor.main,
            },
            "& .service-btn": {
              backgroundColor: serviceColor.main,
              color: "white",
              borderColor: serviceColor.main,
            },
            "& .service-overlay": {
              opacity: 0.05,
            },
          },
        }}
      >
        {/* Background overlay */}
        <Box
          className="service-overlay"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0,
            background: `linear-gradient(135deg, ${serviceColor.light}, ${serviceColor.main})`,
            transition: "opacity 0.4s ease",
            zIndex: 0,
          }}
        />

        <CardContent
          sx={{
            p: { xs: 2, md: 3 },
            position: "relative",
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            height: "100%", // Important for flexbox spacing
          }}
        >
          {/* Content wrapper - top part */}
          <Box sx={{ mb: 2 }}>
            {/* Icon */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2,
              }}
            >
              <Box
                className="service-icon"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 70,
                  height: 70,
                  borderRadius: "20px",
                  backgroundColor: "rgba(0,0,0,0.03)",
                  color: serviceColor.main,
                  transition:
                    "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                  boxShadow: `0 10px 20px ${serviceColor.main}20`,
                  "&:after": {
                    content: '""',
                    position: "absolute",
                    width: "50%",
                    height: "20%",
                    background: `linear-gradient(to left, ${serviceColor.main}10, ${serviceColor.light}80)`,
                    borderRadius: "50%",
                    bottom: -10,
                    filter: "blur(12px)",
                    opacity: 0.6,
                    transition: "opacity 0.4s ease",
                  },
                }}
              >
                {renderIcon(icon)}
              </Box>
            </Box>

            {/* Title */}
            <Typography
              variant="h6"
              component="h3"
              align="center"
              className="service-title"
              sx={{
                fontWeight: 700,
                mb: 1.5,
                transition: "color 0.3s ease",
                fontSize: { xs: "1.1rem", sm: "1.25rem" },
              }}
            >
              {displayTitle}
            </Typography>
          </Box>

          {/* Description - with fixed height and scrollable */}
          <Box
            sx={{
              flexGrow: 1,
              overflow: "auto",
              mb: 2,
              "&::-webkit-scrollbar": {
                width: "6px",
                backgroundColor: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: alpha(serviceColor.main, 0.2),
                borderRadius: "3px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: alpha(serviceColor.main, 0.4),
              },
            }}
          >
            <Typography
              variant="body2"
              align="center"
              sx={{
                color: "text.secondary",
                lineHeight: 1.7,
                px: 1,
                fontSize: { xs: "0.815rem", sm: "0.875rem" },
              }}
            >
              {description}
            </Typography>
          </Box>

          {/* Button wrapper - fixed at bottom */}
          <Box sx={{ mt: "auto" }}>
            <Button
              component={Link}
              to={`/teklif-formu?hizmet=${serviceId}`}
              variant="outlined"
              className="service-btn"
              sx={{
                borderRadius: "10px",
                py: 1,
                fontWeight: 600,
                borderWidth: 2,
                borderColor: serviceColor.main,
                color: serviceColor.main,
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: `0 5px 15px ${serviceColor.main}40`,
                },
                width: "100%", // Full width button
                fontSize: { xs: "0.8rem", sm: "0.875rem" },
              }}
            >
              {t("home.getQuote")}
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  };
  return (
    
    <Box>
      {/* Hero Slider */}
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          width: "100vw",
          marginLeft: "calc(-50vw + 50%)",
        }}
      >
        <Slider {...sliderSettings}>
          <Box>
            <Box
              sx={{
                position: "relative",
                height: { xs: "60vh", md: "80vh" },
                backgroundImage: `url(${sliderImage1})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0,0,0,0.3)",
                },
              }}
            >
              <Container maxWidth="lg">
                <Box
                  sx={{
                    position: "relative",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    color: "white",
                    pt: { xs: 15, md: 0 },
                  }}
                >
                  <Typography
                    variant="h2"
                    component="h1"
                    sx={{
                      fontWeight: "bold",
                      mb: 2,
                      fontSize: { xs: "2rem", md: "3.5rem" },
                    }}
                  >
                    {t("home.welcomeTitle")}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      mb: 4,
                      maxWidth: "700px",
                      fontSize: { xs: "1.2rem", md: "1.5rem" },
                    }}
                  >
                    {t("home.welcomeSubtitle")}
                  </Typography>
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      component={Link}
                      to="/hizmetler"
                      sx={{ mr: 2, px: 4, py: 1.5 }}
                    >
                      {t("common.ourServices")}
                    </Button>
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="large"
                      component={Link}
                      to="/iletisim"
                      sx={{
                        px: 4,
                        py: 1.5,
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.secondary.main,
                          color: (theme) =>
                            theme.palette.secondary.contrastText,
                          borderColor: (theme) => theme.palette.secondary.main,
                        },
                      }}
                    >
                      {t("common.contactUs")}
                    </Button>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                position: "relative",
                height: { xs: "60vh", md: "80vh" },
                backgroundImage: `url(${sliderImage2})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0,0,0,0.3)",
                },
              }}
            >
              <Container maxWidth="lg">
                <Box
                  sx={{
                    position: "relative",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    color: "white",
                    pt: { xs: 15, md: 0 },
                  }}
                >
                  <Typography
                    variant="h2"
                    component="h1"
                    sx={{
                      fontWeight: "bold",
                      mb: 2,
                      fontSize: { xs: "2rem", md: "3.5rem" },
                    }}
                  >
                    {t("home.welcomeTitle2")}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      mb: 4,
                      maxWidth: "700px",
                      fontSize: { xs: "1.2rem", md: "1.5rem" },
                    }}
                  >
                    {t("home.welcomeSubtitle")}
                  </Typography>
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      component={Link}
                      to="/hizmetler"
                      sx={{ mr: 2, px: 4, py: 1.5 }}
                    >
                      {t("home.digitalSolutions")}
                    </Button>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
        </Slider>
      </Box>

      {/* Slider Altı Sabit Başlık Bölümü - YENİ EKLENDİ */}
      <Box
        sx={{
          py: { xs: 3.5, md: 4 },
          background: `linear-gradient(to right, ${alpha(
            theme.palette.primary.main,
            0.05
          )}, ${alpha(theme.palette.secondary.main, 0.05)})`,
          textAlign: "center",
          width: "100vw",
          marginLeft: "calc(-50vw + 50%)",
          borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
          position: "relative",
          boxShadow: "0 1px 8px rgba(0,0,0,0.05)",
          "&::before": {
            content: '""',
            position: "absolute",
            top: -15,
            left: "50%",
            transform: "translateX(-50%)",
            width: 60,
            height: 30,
            background: theme.palette.background.paper,
            clipPath: "polygon(0 0, 100% 0, 50% 100%)",
            zIndex: 1,
            boxShadow: "0 -1px 4px rgba(0,0,0,0.1)",
          },
        }}
      >
        <Container>
          <Typography
            variant="h4"
            component="h2"
            color="primary"
            sx={{
              fontWeight: "bold",
              mb: 1.5,
              fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
              textShadow: "0px 1px 2px rgba(0,0,0,0.05)",
            }}
          >
            {t("home.whyChooseUs") || "Neden Bizi Tercih Etmelisiniz?"}
          </Typography>

          <Typography
            variant="h6"
            sx={{
              color: "text.secondary",
              fontWeight: "normal",
              maxWidth: 900,
              mx: "auto",
              fontSize: { xs: "1rem", sm: "1.1rem", md: "1.25rem" },
              lineHeight: 1.5,
            }}
          >
            {t("home.whyChooseUsSubtitle") ||
              "Eğitim kurumunuzu bir adım öne taşıyacak profesyonel çözüm ortağınız"}
          </Typography>
        </Container>
      </Box>

      {/* Neden Ölçü Eğitim Bölümü */}
      <Box
        sx={{
          py: { xs: 6, md: 10 },
          backgroundColor: theme.palette.background.default,
          background: `linear-gradient(135deg, ${alpha(
            theme.palette.primary.light,
            0.1
          )}, ${alpha(theme.palette.primary.dark, 0.05)})`,
          position: "relative",
          overflow: "hidden",
          width: "100vw",
          marginLeft: "calc(-50vw + 50%)",
        }}
      >
        {/* Dekoratif arka plan elementleri */}
        <Box
          sx={{
            position: "absolute",
            width: "400px",
            height: "400px",
            borderRadius: "50%",
            background: `radial-gradient(circle, ${alpha(
              theme.palette.primary.light,
              0.2
            )}, transparent 70%)`,
            top: "-200px",
            right: "-100px",
            zIndex: 0,
          }}
        />

        <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
          <Grid container spacing={4}>
            {/* Özellik 1 */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: 4,
                  transition: "all 0.3s ease",
                  border: "1px solid rgba(0,0,0,0.05)",
                  "&:hover": {
                    transform: "translateY(-10px)",
                    boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                    borderColor: theme.palette.primary.main,
                  },
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                    width: 80,
                    height: 80,
                    mb: 2,
                    color: theme.palette.primary.main,
                  }}
                >
                  <SchoolIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  {t("home.educationExpertise")}
                </Typography>
                <Typography color="text.secondary">
                  {t("home.educationExpertiseDesc")}
                </Typography>
              </Paper>
            </Grid>

            {/* Özellik 2 */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: 4,
                  transition: "all 0.3s ease",
                  border: "1px solid rgba(0,0,0,0.05)",
                  "&:hover": {
                    transform: "translateY(-10px)",
                    boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                    borderColor: theme.palette.success.main,
                  },
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: alpha(theme.palette.success.main, 0.1),
                    width: 80,
                    height: 80,
                    mb: 2,
                    color: theme.palette.success.main,
                  }}
                >
                  <ComputerIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  {t("home.digitalTransformation")}
                </Typography>
                <Typography color="text.secondary">
                  {t("home.digitalTransformationDesc")}
                </Typography>
              </Paper>
            </Grid>

            {/* Özellik 3 */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: 4,
                  transition: "all 0.3s ease",
                  border: "1px solid rgba(0,0,0,0.05)",
                  "&:hover": {
                    transform: "translateY(-10px)",
                    boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                    borderColor: theme.palette.warning.main,
                  },
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: alpha(theme.palette.warning.main, 0.1),
                    width: 80,
                    height: 80,
                    mb: 2,
                    color: theme.palette.warning.main,
                  }}
                >
                  <SupportIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  {t("home.supportService")}
                </Typography>
                <Typography color="text.secondary">
                  {t("home.supportServiceDesc")}
                </Typography>
              </Paper>
            </Grid>

            {/* Özellik 4 */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: 4,
                  transition: "all 0.3s ease",
                  border: "1px solid rgba(0,0,0,0.05)",
                  "&:hover": {
                    transform: "translateY(-10px)",
                    boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                    borderColor: theme.palette.info.main,
                  },
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: alpha(theme.palette.info.main, 0.1),
                    width: 80,
                    height: 80,
                    mb: 2,
                    color: theme.palette.info.main,
                  }}
                >
                  <AssignmentIndIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  {t("home.customizedSolutions")}
                </Typography>
                <Typography color="text.secondary">
                  {t("home.customizedSolutionsDesc")}
                </Typography>
              </Paper>
            </Grid>
          </Grid>

          {/* İstatistik veya Vurgu */}
          <Box
            sx={{
              mt: 6,
              p: 4,
              borderRadius: 4,
              bgcolor: "white",
              boxShadow: "0 10px 40px rgba(0,0,0,0.05)",
              border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
            }}
          >
            <Typography
              variant="h5"
              align="center"
              fontWeight="bold"
              sx={{
                background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {t("home.servingInstitutions")}
            </Typography>
          </Box>
        </Container>
      </Box>

      {/* Services Section */}
      <Box
        sx={{ py: { xs: 6, md: 10 }, backgroundColor: "background.default" }}
      >
        <Container maxWidth="lg">
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate={isLoaded ? "visible" : "hidden"}
          >
            <Box sx={{ textAlign: "center", mb: 6 }}>
              <motion.div variants={itemVariants}>
                <Typography
                  variant="h3"
                  component="h2"
                  color="primary"
                  sx={{ fontWeight: "bold", mb: 2 }}
                >
                  {t("common.ourServices")}
                </Typography>
              </motion.div>
              <motion.div variants={itemVariants}>
                <Typography
                  variant="h6"
                  color="text.secondary"
                  sx={{ maxWidth: 800, mx: "auto" }}
                >
                  {t("home.professionalSolutions")}
                </Typography>
              </motion.div>
            </Box>

            <Grid container spacing={4}>
              {servicesData.map((service, index) => (
                <Grid item xs={12} sm={6} md={4} key={service.id}>
                  <motion.div variants={itemVariants}>
                    <ServiceCard
                      title={service.title}
                      displayTitle={service.displayTitle}
                      description={service.description}
                      icon={service.icon}
                      path={service.path}
                      index={index}
                    />
                  </motion.div>
                </Grid>
              ))}
            </Grid>

            {/* Tüm hizmetler butonu */}
            <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
              <Button
                component={Link}
                to="/hizmetler"
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  py: 1.5,
                  px: 4,
                  borderRadius: 2,
                  fontWeight: 600,
                  boxShadow: 4,
                  transition: "all 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: 8,
                  },
                }}
              >
                {t("home.exploreAllServices")}
              </Button>
            </Box>
          </motion.div>
        </Container>
      </Box>

      {/* Statistics Section */}
      <Box
        sx={{
          mb: { xs: 6, md: 12 },
          width: "100vw",
          marginLeft: "calc(-50vw + 50%)",
        }}
      >
        <Container maxWidth="lg">
          <Paper
            elevation={0}
            sx={{
              bgcolor: "background.default",
              borderRadius: 8,
              py: 8,
              px: 4,
              background: `linear-gradient(135deg, ${theme.palette.primary.dark}05, ${theme.palette.primary.main}15)`,
              boxShadow: "0 20px 60px rgba(0, 0, 0, 0.07)",
              position: "relative",
              overflow: "hidden",
              "&:before": {
                content: '""',
                position: "absolute",
                width: "300px",
                height: "300px",
                borderRadius: "50%",
                background: `radial-gradient(circle, ${theme.palette.primary.light}30, transparent 70%)`,
                top: "-150px",
                left: "-100px",
              },
              "&:after": {
                content: '""',
                position: "absolute",
                width: "300px",
                height: "300px",
                borderRadius: "50%",
                background: `radial-gradient(circle, ${theme.palette.primary.light}30, transparent 70%)`,
                bottom: "-150px",
                right: "-100px",
              },
            }}
          >
            <SectionTitle
              variant="h4"
              component="h2"
              align="center"
              sx={{ mb: 6 }}
            >
              {t("about.statisticsTitle")}
            </SectionTitle>

            <Box sx={{ position: "relative", zIndex: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 4 }}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={6} md={3}>
                  <Paper
                    elevation={4}
                    sx={{
                      py: 4,
                      px: 2,
                      textAlign: "center",
                      borderRadius: 4,
                      overflow: "hidden",
                      position: "relative",
                      height: "100%",
                      transition: "all 0.3s ease",
                      background: `linear-gradient(135deg, white, ${theme.palette.primary.light}10)`,
                      "&:hover": {
                        transform: "translateY(-10px) scale(1.05)",
                        boxShadow: "0 20px 40px rgba(0, 0, 0, 0.15)",
                      },
                      "&:before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "5px",
                        background: `linear-gradient(90deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
                      },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        fontWeight: 800,
                        color: theme.palette.primary.main,
                        mb: 1,
                        textShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      10+
                    </Typography>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      sx={{ fontWeight: 600 }}
                    >
                      {t("about.stat1")}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={6} md={3}>
                  <Paper
                    elevation={4}
                    sx={{
                      py: 4,
                      px: 2,
                      textAlign: "center",
                      borderRadius: 4,
                      overflow: "hidden",
                      position: "relative",
                      height: "100%",
                      transition: "all 0.3s ease",
                      background: `linear-gradient(135deg, white, ${theme.palette.success.light}10)`,
                      "&:hover": {
                        transform: "translateY(-10px) scale(1.05)",
                        boxShadow: "0 20px 40px rgba(0, 0, 0, 0.15)",
                      },
                      "&:before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "5px",
                        background: `linear-gradient(90deg, ${theme.palette.success.light}, ${theme.palette.success.main})`,
                      },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        fontWeight: 800,
                        color: theme.palette.success.main,
                        mb: 1,
                        textShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        background: `linear-gradient(135deg, ${theme.palette.success.main}, ${theme.palette.success.dark})`,
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      500+
                    </Typography>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      sx={{ fontWeight: 600 }}
                    >
                      {t("about.stat2")}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={6} md={3}>
                  <Paper
                    elevation={4}
                    sx={{
                      py: 4,
                      px: 2,
                      textAlign: "center",
                      borderRadius: 4,
                      overflow: "hidden",
                      position: "relative",
                      height: "100%",
                      transition: "all 0.3s ease",
                      background: `linear-gradient(135deg, white, ${theme.palette.info.light}10)`,
                      "&:hover": {
                        transform: "translateY(-10px) scale(1.05)",
                        boxShadow: "0 20px 40px rgba(0, 0, 0, 0.15)",
                      },
                      "&:before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "5px",
                        background: `linear-gradient(90deg, ${theme.palette.info.light}, ${theme.palette.info.main})`,
                      },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        fontWeight: 800,
                        color: theme.palette.info.main,
                        mb: 1,
                        textShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        background: `linear-gradient(135deg, ${theme.palette.info.main}, ${theme.palette.info.dark})`,
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      100+
                    </Typography>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      sx={{ fontWeight: 600 }}
                    >
                      {t("about.stat3")}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={6} md={3}>
                  <Paper
                    elevation={4}
                    sx={{
                      py: 4,
                      px: 2,
                      textAlign: "center",
                      borderRadius: 4,
                      overflow: "hidden",
                      position: "relative",
                      height: "100%",
                      transition: "all 0.3s ease",
                      background: `linear-gradient(135deg, white, ${theme.palette.warning.light}10)`,
                      "&:hover": {
                        transform: "translateY(-10px) scale(1.05)",
                        boxShadow: "0 20px 40px rgba(0, 0, 0, 0.15)",
                      },
                      "&:before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "5px",
                        background: `linear-gradient(90deg, ${theme.palette.warning.light}, ${theme.palette.warning.main})`,
                      },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        fontWeight: 800,
                        color: theme.palette.warning.main,
                        mb: 1,
                        textShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        background: `linear-gradient(135deg, ${theme.palette.warning.main}, ${theme.palette.warning.dark})`,
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      10+
                    </Typography>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      sx={{ fontWeight: 600 }}
                    >
                      {t("about.stat4")}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
      </Box>

      {/* Testimonials Section - Fixed Slider Format */}
      <Box
        sx={{ py: { xs: 6, md: 10 }, backgroundColor: "background.default" }}
      >
        <Container maxWidth="lg">
          <Box sx={{ textAlign: "center", mb: 6 }}>
            <Typography
              variant="h3"
              component="h2"
              color="primary"
              sx={{ fontWeight: "bold", mb: 2 }}
            >
              {t("home.testimonials")}
            </Typography>
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{ maxWidth: 800, mx: "auto" }}
            >
              {t("home.testimonialDescription")}
            </Typography>
          </Box>

          {/* Fixed Testimonial Slider - Oklar kaldırıldı */}
          <Box sx={{ position: "relative", mt: 4 }}>
            <Box
              sx={{
                position: "relative",
                overflow: "hidden",
                mx: { xs: 0, md: 4 },
              }}
            >
              <Slider ref={testimonialRef} {...testimonialSliderSettings}>
                {testimonials.map((testimonial, index) => (
                  <Box key={index} sx={{ px: 2 }}>
                    <Card
                      sx={{
                        borderRadius: 4,
                        overflow: "visible",
                        position: "relative",
                        boxShadow: "0 10px 30px rgba(0, 0, 0, 0.08)",
                        transition: "all 0.4s ease",
                        height: "auto",
                        minHeight: 350,
                        width: { xs: "100%", md: 380 },
                        maxWidth: "95%",
                        mx: "auto",
                        display: "flex",
                        flexDirection: "column",
                        "&:hover": {
                          transform: "translateY(-10px)",
                          boxShadow: "0 20px 40px rgba(0, 0, 0, 0.12)",
                        },
                        "&:before": {
                          content: '""',
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          height: 5,
                          backgroundColor: testimonial.color,
                          borderTopLeftRadius: 4,
                          borderTopRightRadius: 4,
                        },
                      }}
                    >
                      <CardContent
                        sx={{
                          p: 3,
                          pb: 3,
                          display: "flex",
                          flexDirection: "column",
                          flexGrow: 1,
                        }}
                      >
                        <FormatQuoteIcon
                          sx={{
                            position: "absolute",
                            top: -28,
                            left: 30,
                            fontSize: 70,
                            color: testimonial.lightColor,
                            transform: "rotate(180deg)",
                            zIndex: 1,
                          }}
                        />
                        <Typography
                          variant="body1"
                          paragraph
                          sx={{
                            mb: 3,
                            position: "relative",
                            zIndex: 1,
                            lineHeight: 1.7,
                            flexGrow: 1,
                            height: "auto",
                            maxHeight: "none",
                            overflow: "visible",
                            display: "block",
                          }}
                        >
                          {testimonial.text}
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "auto",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Avatar
                              alt={testimonial.name}
                              sx={{
                                width: 54,
                                height: 54,
                                mr: 2,
                                border: `2px solid ${theme.palette.background.paper}`,
                                boxShadow: "0 3px 10px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              {testimonial.name.charAt(0)}
                            </Avatar>
                            <Box>
                              <Typography variant="subtitle1" fontWeight="bold">
                                {testimonial.name}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {testimonial.title}
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {renderRating(testimonial.rating)}
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                ))}
              </Slider>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Dönüşüm Odaklı CTA Bölümü */}
      <Box
        sx={{
          py: { xs: 6, md: 10 },
          position: "relative",
          background: `linear-gradient(135deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`,
          color: "white",
          overflow: "hidden",
          width: "100vw",
          marginLeft: "calc(-50vw + 50%)",
        }}
      >
        {/* Dekoratif Elementler */}
        <Box
          sx={{
            position: "absolute",
            width: "300px",
            height: "300px",
            borderRadius: "50%",
            background: "rgba(255,255,255,0.05)",
            bottom: "-150px",
            left: "-50px",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            width: "200px",
            height: "200px",
            borderRadius: "50%",
            background: "rgba(255,255,255,0.05)",
            top: "-100px",
            right: "10%",
          }}
        />

        <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={7}>
              <Typography
                variant="h3"
                component="h2"
                sx={{ fontWeight: "bold", mb: 2 }}
              >
                {t("about.ctaTitle")}
              </Typography>
              <Typography variant="h6" sx={{ mb: 4, opacity: 0.9 }}>
                {t("about.ctaDesc")}
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    component={Link}
                    to="/iletisim"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    size="large"
                    sx={{
                      py: 1.5,
                      borderRadius: 2,
                      fontSize: "1.1rem",
                      fontWeight: 600,
                      backgroundColor: "gray", // Added black background color
                      boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
                      "&:hover": {
                        transform: "translateY(-5px)",
                        boxShadow: "0 15px 30px rgba(0,0,0,0.2)",
                        backgroundColor: "black", // Maintain black color on hover
                      },
                      transition: "all 0.3s ease",
                    }}
                  >
                    {t("common.contactUs")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={5}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Box
                sx={{
                  p: 3,
                  bgcolor: "rgba(255,255,255,0.1)",
                  borderRadius: 4,
                  backdropFilter: "blur(10px)",
                  border: "1px solid rgba(255,255,255,0.2)",
                }}
              >
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  {t("home.quickContact")}
                </Typography>

                <Box sx={{ mt: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", mb: 2 }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            (window.location.href = "tel:0850 480 65 28")
                          }
                        >
                          <PhoneIcon
                            sx={{ mr: 2, color: theme.palette.secondary.light }}
                          />
                          <Typography>0850 480 65 28</Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{ display: "flex", alignItems: "center", mb: 2 }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            (window.location.href =
                              "mailto:info@olcuegitim.com")
                          }
                        >
                          <EmailIcon
                            sx={{ mr: 2, color: theme.palette.secondary.light }}
                          />
                          <Typography>info@olcuegitim.com</Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <AccessTimeIcon
                          sx={{ mr: 2, color: theme.palette.secondary.light }}
                        />
                        <Typography>
                          09:00 - 18:00 ({t("home.workingDays")})
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Partners Section */}
      <Box sx={{ py: { xs: 6, md: 10 }, backgroundColor: "white" }}>
        <Container maxWidth="lg">
          <Box sx={{ textAlign: "center", mb: 6 }}>
            <Typography
              variant="h3"
              component="h2"
              color="primary"
              sx={{ fontWeight: "bold", mb: 2 }}
            >
              {t("home.ourPartners")}
            </Typography>
          </Box>

          <Partners />
        </Container>
      </Box>
    </Box>
  );
};

export default HomePage;