import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Paper,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import {
  Phone as PhoneIcon,
  Email as EmailIcon,
  LocationOn as LocationIcon,
  AccessTime as TimeIcon,
  Send as SendIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const ContactPage = () => {
  const { t } = useTranslation();

  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  // Yönlendirme mesajı için state
  const [redirectMsg, setRedirectMsg] = useState({
    open: false,
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // E-posta içeriği oluştur
    const emailSubject = `İletişim Formu - ${formState.name}`;

    let emailBody = `Ad Soyad: ${formState.name}\n`;
    emailBody += `E-posta: ${formState.email}\n`;
    emailBody += `Telefon: ${formState.phone}\n\n`;
    emailBody += `Mesaj:\n${formState.message}\n`;

    // Yeni satır karakterlerini HTML için uygun formata dönüştür
    const formattedBody = emailBody.replace(/\n/g, "%0D%0A");

    // Yönlendirme mesajını göster
    setRedirectMsg({
      open: true,
      message: t("contact.redirectMessage"), // "E-posta uygulamanıza yönlendiriliyorsunuz..." mesajı
    });

    // E-posta istemcisini yeni pencerede aç
    window.open(
      `mailto:info@olcuegitim.com?subject=${encodeURIComponent(
        emailSubject
      )}&body=${formattedBody}`,
      "_blank"
    );

    // Form alanlarını temizle
    setFormState({
      name: "",
      email: "",
      phone: "",
      message: "",
    });

    // Yükleme durumunu kapat
    setLoading(false);
  };

  const handleCloseRedirectMsg = () => {
    setRedirectMsg({ open: false, message: "" });
  };

  return (
    <Box sx={{ bgcolor: "#f5f5f7", minHeight: "100vh", py: 6 }}>
      <Container maxWidth="lg">
        {/* Header */}
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          align="center"
          sx={{
            mb: 6,
            fontWeight: 700,
            position: "relative",
            "&:after": {
              content: '""',
              position: "absolute",
              bottom: -10,
              left: "50%",
              width: 100,
              height: 4,
              bgcolor: "primary.main",
              transform: "translateX(-50%)",
            },
          }}
        >
          {t("common.contact")}
        </Typography>

        <Grid container spacing={4}>
          {/* Contact Info Cards */}
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: "100%",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "translateY(-8px)",
                  boxShadow: 8,
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  p: 3,
                }}
              >
                <Box
                  sx={{
                    bgcolor: "secondary.dark",
                    color: "white",
                    borderRadius: "50%",
                    width: 64,
                    height: 64,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 2,
                  }}
                >
                  <TimeIcon fontSize="large" />
                </Box>
                <Typography variant="h6" gutterBottom>
                  {t("common.workingHours")}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  09:00 - 18:00
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: "100%",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "translateY(-8px)",
                  boxShadow: 8,
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  p: 3,
                }}
              >
                <Box
                  sx={{
                    bgcolor: "secondary.dark",
                    color: "white",
                    borderRadius: "50%",
                    width: 64,
                    height: 64,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 2,
                  }}
                >
                  <PhoneIcon fontSize="large" />
                </Box>
                <Typography variant="h6" gutterBottom>
                  {t("common.phone")}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  component="a"
                  href="tel:08504806528"
                  sx={{
                    textDecoration: "none",
                    color: "text.secondary",
                    transition: "color 0.3s",
                    "&:hover": { color: "primary.main" },
                  }}
                >
                  0850 480 65 28
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: "100%",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "translateY(-8px)",
                  boxShadow: 8,
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  p: 3,
                }}
              >
                <Box
                  sx={{
                    bgcolor: "secondary.dark",
                    color: "white",
                    borderRadius: "50%",
                    width: 64,
                    height: 64,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 2,
                  }}
                >
                  <EmailIcon fontSize="large" />
                </Box>
                <Typography variant="h6" gutterBottom>
                  {t("common.email")}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  component="a"
                  href="mailto:info@olcuegitim.com"
                  sx={{
                    textDecoration: "none",
                    color: "text.secondary",
                    transition: "color 0.3s",
                    "&:hover": { color: "primary.main" },
                  }}
                >
                  info@olcuegitim.com
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Contact Form & Map Section */}
          <Grid item xs={12}>
            <Paper
              elevation={3}
              sx={{
                mt: 4,
                overflow: "hidden",
                borderRadius: 2,
              }}
            >
              <Grid container>
                {/* Contact Form */}
                <Grid item xs={12} md={6} sx={{ p: 4 }}>
                  <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom
                    sx={{ mb: 3, fontWeight: 600 }}
                  >
                    {t("contact.contactUs")}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mb: 4 }}
                  >
                    {t("contact.formDescription")}
                  </Typography>

                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label={t("common.name")}
                          name="name"
                          value={formState.name}
                          onChange={handleInputChange}
                          required
                          variant="outlined"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&:hover fieldset": {
                                borderColor: "primary.main",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label={t("common.email")}
                          name="email"
                          type="email"
                          value={formState.email}
                          onChange={handleInputChange}
                          required
                          variant="outlined"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&:hover fieldset": {
                                borderColor: "primary.main",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label={t("common.phone")}
                          name="phone"
                          value={formState.phone}
                          onChange={handleInputChange}
                          variant="outlined"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&:hover fieldset": {
                                borderColor: "primary.main",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label={t("common.message")}
                          name="message"
                          value={formState.message}
                          onChange={handleInputChange}
                          required
                          multiline
                          rows={4}
                          variant="outlined"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&:hover fieldset": {
                                borderColor: "primary.main",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          disabled={loading}
                          startIcon={
                            loading ? (
                              <CircularProgress size={24} />
                            ) : (
                              <SendIcon />
                            )
                          }
                          sx={{
                            mt: 2,
                            py: 1.5,
                            px: 4,
                            borderRadius: 2,
                            boxShadow: 3,
                            transition: "transform 0.2s, box-shadow 0.2s",
                            "&:hover": {
                              transform: "scale(1.02)",
                              boxShadow: 5,
                            },
                          }}
                        >
                          {loading ? t("quotation.sending") : t("common.send")}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>

                {/* Address & Map */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    bgcolor: "#f0f3f8",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ p: 4, flexGrow: 0 }}>
                    <Typography
                      variant="h4"
                      component="h2"
                      gutterBottom
                      sx={{ mb: 3, fontWeight: 600 }}
                    >
                      {t("contact.address")}
                    </Typography>
                    <Box
                      sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}
                    >
                      <LocationIcon color="primary" sx={{ mr: 2, mt: 0.5 }} />
                      <Typography variant="body1" color="text.secondary">
                        İnkılap Mah. Seyit Onbaşı Cad. No:7/A Ümraniye /
                        İstanbul
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{ flexGrow: 1, position: "relative", minHeight: 300 }}
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.226419205063!2d29.09657491541527!3d41.01686997929907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac8a05b4f9ed7%3A0x7c76fd6389c771c3!2zxLBua8SxbGFwLCBTZXlpdCBPbmJhxZ_EsSBDZC4gTm86Ny9BLCDDnG1yYW5peWUvxLBzdGFuYnVs!5e0!3m2!1str!2str!4v1648140324901!5m2!1str!2str"
                      width="100%"
                      height="100%"
                      style={{ border: 0, position: "absolute" }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      title="Ölçü Eğitim Lokasyon"
                    ></iframe>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      {/* 3 saniye sonra otomatik kapanan yönlendirme mesajı */}
      <Snackbar
        open={redirectMsg.open}
        autoHideDuration={3000} // 3 saniye (3000 milisaniye) sonra otomatik kapanacak
        onClose={handleCloseRedirectMsg}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        TransitionProps={{ appear: true }}
      >
        <Alert
          onClose={handleCloseRedirectMsg}
          severity="info"
          variant="filled"
          sx={{
            transition: "opacity 0.5s ease-out",
          }}
        >
          {redirectMsg.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ContactPage;
